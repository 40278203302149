<template>

<head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Parking Quick Stats</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
        type="text/css" />

         <!-- // Add the new slick-theme.css if you want the default styling -->
     <link rel="stylesheet" href="assets/libs/slick/slick.css"/>
     <link rel="stylesheet" href="assets/libs/slick/slick-theme.css"/>

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body @contextmenu.prevent data-sidebar="dark">

    <!-- <body data-layout="horizontal" data-topbar="dark"> -->
    <!-- Loader -->

    <!-- Begin page -->
    <div id="layout-wrapper">



        <NavigationBar/>
        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row ">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Plot No {{plotNumber}} in {{zone}}</h4>

                                <div class="page-title-right text-capitalize">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Parking</a></li>
                                        <li class="breadcrumb-item"><a href="parking-fleet.html">Fleet manager</a></li>
                                        <li class="breadcrumb-item"><a href="parking-fleet-details.html">Usiku Sacco Details</a></li>
                                        <li class="breadcrumb-item"><a href="#">KAD123T</a></li>
                                        <li class="breadcrumb-item"><a href="#">Financial statements</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->

                  <ToolBarPlot/>

                    <!-- end of toolbar -->
                    <div class="row">

                        <div class="col-12 px-sm-30px">
                            <div class="card">
                                <div class="card-header bg-white border-bottom d-flex justify-content-between">
                                    <div>
                                        <h4 class="card-title text-uppercase">Financial Statements for Plot No. {{plotNumber}} in {{zone}}</h4>
                                    </div>

                                    <!-- by default filter for the whole year -->
                                    <div class="d-flex">
                                        <div class="d-flex align-items-center me-3 pe-3">
                                            <span class="pr-3 fw-semibold">From:</span>
                                            <input v-model="dateFrom" class="form-control" placeholder="Start date" type="date">
                                            <span class="px-3 fw-semibold">TO:</span>
                                            <input v-model="dateTo"  class="form-control" placeholder="Start date" type="date">
                                        </div>
                                        <button @click="downloadBillInfo" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                            <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                                        </button>

                                    </div>

                                </div>


                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table" id="">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th>Posting Date</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Posted By</th>
                                                    <th scope="col" class="">BillNo No.</th>
                                                    <th scope="col" class="text-right">Debit</th>
                                                    <th class="text-right">Credit</th>
                                                    <th scope="col" class="text-right">Balance</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <!-- always rememeber to show the opening balance -->
                                                <tr class="table-light">
                                                   <th colspan="4">Opening Balance</th>
                                                   <th class="text-right">KES 0.00</th>
                                                   <th class="text-right">KES 0.00</th>
                                                   <th class="text-right">KES 0.00</th>
                                               </tr>
                                               <tr :key="index" :value="item" v-for="(item, index) in billInfo">
                                                    <td>{{index+1}} {{formatDate(item.dateCreated)}}</td>
                                                    <td>{{item.description}}</td>
                                                    <td>{{item.names}}</td>
                                                    <td>{{item.billNo}}</td>
                                                    <td class="text-right">
                                                        KES {{numberFormat(item.billTotal)}}
                                                    </td>
                                                    <td class="text-right"> {{numberFormat(item.receiptAmount)}}</td>
                                                    <td class="text-right">KES {{numberFormat(item.billBalance)}}</td>

                                               </tr>

                                            </tbody>

                                            <tfoot>
                                                <tr class="table-light">
                                                   <th colspan="4">
                                                        Closing Balance
                                                   </th>
                                                   <th class="text-right">
                                                             KES {{numberFormat(totalBillTotal)}}
                                                   </th>
                                                   <th class="text-right">
                                                        KES {{numberFormat(totalReceiptAmount)}}
                                                   </th>
                                                   <th class="text-right">
                                                    KES {{numberFormat(totalBillBalance)}}
                                                   </th>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>

                                        <div v-if="billInfo.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                                        </div>

                                    </div>
                                </div>

                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example "
                                         class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px " v-model="pageSize">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                                <option value="5000">5000 Rows</option>
                                                <option value="10000">10000 Rows</option>
                                                <option value="100000000000">All Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                <a class="page-link" tabindex="-1" @click="prevPage" :disabled="currentPage === 1">Previous</a>
                                            </li>
                                            <!-- replace the v-for directive with the pagination function call -->
                                            <li v-for="page in pagination(currentPage, totalPages, 3)" :key="page" class="page-item" :class="{ active: currentPage === page }">
                                                <!-- check if the page is an ellipsis and display it as plain text -->
                                                <span v-if="page === '...'" class="page-link">{{ page }}</span>
                                                <!-- otherwise, display it as a clickable link -->
                                                <a v-else class="page-link" @click="goToPage(page)">{{ page }}</a>
                                            </li>
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                <a class="page-link"  @click="nextPage" :disabled="currentPage === totalPages">Next</a>
                                            </li>
                                        </ul>


                                    </nav>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
           <!-- payment modal -->
         <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header d-none">
                    <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                    <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                    </button>

                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                    <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                    </button>
                    </div>

                    <div class="payment-panel-parent">

                        <div class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">
                            <div class="success-image mb-4 pb-4 pt-4">
                                <img src="assets/images/bills.svg" height="200" alt="">
                            </div>
                            <h4 class="text-black fw-bold">Bill Successfully Created</h4>
                            <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
                            <div class="d-flex w-100 pb-2">
                                <button  type="button" class="btn btn-primary btn-block  me-3 flex-grow-1 print-bill-btn">
                                    <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                    Receive Payment
                                </button>
                                <a href="PrintDocs/Bill/bill.html" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                            </div>
                        </div>

                        <div class="send-method payment-panel d-none ">
                            <label for="" class="mb-0 pb-0">Payment Method</label>
                            <p><small class="text-muted">How would you like to send this money?</small></p>

                            <div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <div class="flex-shrink-0 me-3">
                                                    <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                                                    <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div>
                                                    <p>
                                                        A payment request of <strong class="text-black">KES 1,500</strong> will be sent to the MPESA number you enter below.
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">MPESA Number</label>
                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                                        <label for="floatingnameInput">Phone No.</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div class="d-none transaction-summary payment-panel">
                            <label for="">Transaction Breakdown</label>
                            <div class="border p-4 rounded ">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="text-muted ">
                                            Bill NO.
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end">
                                        <div class=" text-right text-black">
                                            BLL12364558T
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="text-muted mt-2">
                                            Receipt For
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end">
                                        <div class=" text-right text-black">
                                            Alex Wanjala
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="text-muted mt-2">
                                            Payment Method
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end">
                                        <div class=" text-right text-black">
                                            MPESA
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="text-muted mt-2">
                                            Transaction Amount
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end">
                                        <div class=" text-right text-black">
                                            KES 1,500
                                        </div>
                                    </div>


                                    <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                                    <div class="col-lg-6 text-uppercase">
                                        <div class="text-muted mt-2 font-21">
                                            Total
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end text-uppercase">
                                        <div class=" text-right text-black font-21 fw-bold">
                                            KES 1,500
                                        </div>
                                    </div>
                                </div>

                                <div class="mpesa-confirmation ">
                                    <p class="text-muted mt-2">A payment request of <strong class="text-black">KES 1,500</strong> will be sent to <strong class="text-black">phone number (0704549859)</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                                        <br>
                                        <br>
                                        Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>

                                        <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <div class="stk-timer-container d-none justify-content-center align-items-center">
                                                    <span class="mdi mdi-timer-outline font-16px"></span>
                                                    <span class="stk-timer px-2"></span>
                                                </div>
                                                <div class="justify-content-center align-items-center d-flex">
                                                    <span class="px-2">Send Request</span>
                                                    <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                                                </div>

                                            </div>
                                         </button>
                                </div>

                                <div class="my-wallet-confirmation d-none">
                                    <p class="text-muted mt-2 "><strong class="text-uppercase text-black">KES 300</strong> will be deducted from your guardian blink wallet and amount will be credited to <strong class="text-capitalize text-black">Alex's pocket money account</strong>.</p>
                                    <p class="text-muted">confirm transaction by clicking the <strong class="text-capitalize text-black">send money</strong> button.</p>

                                    <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center w-100">
                                        <div class="d-flex justify-content-center align-items-center"> <span class="mx-2">Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
                                     </button>

                                </div>


                            </div>
                        </div>

                        <div class="d-none transaction-summary loader-panel">
                            <div class="p-5 py-0 pt-3 d-flex justify-content-center align-items-center text-center flex-column">
                                <div class="p-5">
                                    <div class="spinner-border text-info m-1" role="status">
                                        <span class="sr-only text-uppercase">Loading...</span>
                                    </div>
                                </div>
                                <h4 class="text-black fw-bold">Payment Being Processed</h4>
                                <div class="mb-5">Ask Client to be checking their Phone for a payment request thats currently being Processed</div>
                            </div>
                        </div>
                        <div class="text-center d-flex flex-column justify-content-around align-items-center sent-success d-none payment-panel">
                            <div class="success-image mb-4">
                                <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                            </div>
                            <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                            <p class="text-muted mb-4"><strong class="text-black">KES 1,500.00</strong> has been received for <strong class="text-black">Bill No. YUB48997</strong></p>


                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex d-none bill-modal-footer">
                    <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i> Previouse </button>
                    <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
                    <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                       <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
                    </button>
                </div>
            </div>
            </div>
        </div>
        <!-- end of payment modal -->
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong>
                                                </td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method"
                                                        data-style="btn-secondary w-100" data-live-search="true"
                                                        title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                    </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex "
                                                            placeholder="Phone No." spellcheck="false"
                                                            data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex"
                                                        placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#"
                                                            class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()"
                                    class="btn btn-success waves-effect waves-light me-1"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);"
                                    class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <!-- add vehicles -->
            <div class="modal fade add-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Remove This Vehicle from Usiku Sacco</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p>
                                        Delete Car with registrtaion number <strong class="text-uppercase">KAD 123T</strong> from Usku Sacco.
                                    </p>
                                    <div>
                                        <label class="form-label">Reason For Removing The vehicle </label>
                                        <textarea name="" id="" class="form-control" placeholder="Enter Reason for deleting this vehicle"></textarea>
                                    </div>
                                </div>
                            </div>
                    </div>
                        <div class="modal-footer">

                            <div class="float-end">

                                <a href="javascript: void(0);"
                                    class="btn btn-danger w-md waves-effect waves-light w-100">Delete</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of adding vehicles -->

            <!-- move vehicle to another sacco -->
            <div class="modal fade move-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Move vehicle from Ushirika Sacco</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-capitalize">
                                        Move Vehicle with registrtaion number <strong class="text-uppercase">KAD 123T</strong> from Usku Sacco.
                                    </p>
                                    <div>
                                        <label class="form-label text-uppercase">Search for Sacco</label>
                                        <input class="form-control" type="text" placeholder="Search for Sacco" >
                                    </div>

                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">
                                            <thead class="table-light">
                                                <tr>

                                                    <th></th>
                                                    <th scope="col">Sacco</th>
                                                    <th scope="col">Subcounty</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td>
                                                        <div class="d-flex  align-items-center">
                                                            <div class="the-mail-checkbox pr-4">
                                                                <input class="form-check-input" type="radio" name="form-sacco" >
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td class="text-uppercase">
                                                       Usiku Sacco
                                                    </td>
                                                    <td>
                                                        Meru Town Sub County
                                                    </td>

                                                </tr>

                                                <tr>

                                                    <td>
                                                        <div class="d-flex  align-items-center">
                                                            <div class="the-mail-checkbox pr-4">
                                                                <input class="form-check-input" type="radio" name="form-sacco" >
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td class="text-uppercase">
                                                       Usiku Sacco 4K
                                                    </td>
                                                    <td>
                                                        Meru Town Sub County
                                                    </td>

                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                    </div>
                        <div class="modal-footer">

                            <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light w-100">Move Vehicle</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>

            <!-- move vehicle to another sacco -->

            <!-- adding arrears -->
            <div class="modal fade add-arrears-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Manually add arrears to the Vehicle</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-capitalize">
                                       Use this section to manually add Arrears to the vehicle with registration number <strong class="text-uppercase">KAD 123T</strong> for either of the following months.
                                    </p>
                                    <div>
                                        <label class="form-label text-uppercase">Select the Month(s) the vehicle owes the county in Arrears</label>

                                    </div>

                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">
                                            <thead class="table-light">
                                                <tr>

                                                    <th style="width: 20px;"></th>
                                                    <th scope="col">Month</th>
                                                    <th scope="col" class="text-right">Amount Payable</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td>
                                                        <div class="d-flex  align-items-center">
                                                            <div class="the-mail-checkbox pr-4">
                                                                <input class="form-check-input" type="checkbox" id="month-1">
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td class="text-uppercase">
                                                       <label for="month-1">September 2023</label>
                                                    </td>
                                                    <td class="text-right">
                                                        KES 300
                                                    </td>

                                                </tr>


                                                <tr>

                                                    <td>
                                                        <div class="d-flex  align-items-center">
                                                            <div class="the-mail-checkbox pr-4">
                                                                <input class="form-check-input" type="checkbox" id="month-2">
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td class="text-uppercase">
                                                       <label for="month-2">November 2023</label>
                                                    </td>
                                                    <td class="text-right">
                                                        KES 300
                                                    </td>

                                                </tr>

                                                <tr>

                                                    <td>
                                                        <div class="d-flex  align-items-center">
                                                            <div class="the-mail-checkbox pr-4">
                                                                <input class="form-check-input" type="checkbox" id="month-3">
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td class="text-uppercase">
                                                       <label for="month-3">December 2023</label>
                                                    </td>
                                                    <td class="text-right">
                                                        KES 300
                                                    </td>

                                                </tr>




                                            </tbody>
                                            <tfoot>
                                                <tr class="table-light">
                                                    <TH></TH>
                                                    <th>
                                                        2 Months
                                                    </th>
                                                    <th class="text-right">
                                                        KES 600
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div class="alert alert-warning fw-bold mt-3" role="alert">
                                           Kindly note after submiting this cannot be outdone not unless it is weivered by an authorized official from the county
                                            </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                        <div class="modal-footer">

                            <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light w-100">Submit</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of arrears adding -->

            <!-- sending sms reminders to car owners -->
            <div class="modal fade send-sms-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Sending payment Reminder</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-capitalize">
                                      Use this section to send a payment reminder to the vehicle owner of vehicle with registration Number  <strong class="text-uppercase">KAD 123T</strong> for either of the following months.
                                    </p>
                                    <div>
                                        <label class="form-label text-uppercase">Select the Month(s) the vehicle owes the county in Arrears</label>

                                    </div>

                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">
                                            <thead class="table-light">
                                                <tr>

                                                    <th style="width: 20px;">#</th>
                                                    <th scope="col">Month</th>
                                                    <th scope="col" class="text-right">Amount Payable</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td>
                                                        1.
                                                    </td>
                                                    <td class="text-uppercase">
                                                       <label for="month-1">September 2023</label>
                                                    </td>
                                                    <td class="text-right">
                                                        KES 300
                                                    </td>

                                                </tr>


                                                <tr>

                                                    <td>
                                                       2.
                                                    </td>
                                                    <td class="text-uppercase">
                                                       <label for="month-2">November 2023</label>
                                                    </td>
                                                    <td class="text-right">
                                                        KES 300
                                                    </td>

                                                </tr>

                                                <tr>

                                                    <td>
                                                        3.
                                                    </td>
                                                    <td class="text-uppercase">
                                                       <label for="month-3">December 2023</label>
                                                    </td>
                                                    <td class="text-right">
                                                        KES 300
                                                    </td>

                                                </tr>




                                            </tbody>
                                            <tfoot>
                                                <tr class="table-light">
                                                    <TH></TH>
                                                    <th>
                                                        3 Months
                                                    </th>
                                                    <th class="text-right">
                                                        KES 900
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>


                                    </div>
                                </div>
                            </div>
                    </div>
                        <div class="modal-footer">

                            <a href="javascript: void(0);" class="btn btn-success w-md waves-effect waves-light w-100">Submit</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- sending payment reminders to vehicle owners -->

            <!-- vehicle details modal -->
            <div class="modal fade details-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Vehicle details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-capitalize">
                                      Use this section to send a payment reminder to the vehicle owner of vehicle with registration Number  <strong class="text-uppercase">KAD 123T</strong> for either of the following months.
                                    </p>
                                    <div>
                                        <label class="form-label text-uppercase">Select the Month(s) the vehicle owes the county in Arrears</label>

                                    </div>

                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">

                                            <tbody>
                                                <tr>

                                                    <td>
                                                        Plate Number
                                                    </td>
                                                    <td class="text-uppercase">
                                                       KAD123Y
                                                    </td>
                                                </tr>

                                                <tr>

                                                    <td>
                                                        Vehicle Category
                                                    </td>
                                                    <td class="text-uppercase">
                                                       14 Seater Matatu
                                                    </td>
                                                </tr>

                                                <tr>

                                                    <td>
                                                       Amount Payable Monthly
                                                    </td>
                                                    <td class="text-uppercase">
                                                       KES 450
                                                    </td>
                                                </tr>

                                                <tr>

                                                    <td>
                                                       Owner
                                                    </td>
                                                    <td class="text-uppercase">
                                                        <input type="text" class="from-control form-control-sm" placeholder="Enter Owner's Full Names" >
                                                    </td>
                                                </tr>

                                                <tr>

                                                    <td>
                                                       Owner's Phone No.
                                                    </td>
                                                    <td class="text-uppercase">
                                                      <input type="text" class="from-control form-control-sm" placeholder="Enter Owner's Phone" value="0704549859">
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>


                                    </div>

                                    <div class="alert alert-secondary fw-semibold mt-3" role="alert">
                                        Remember to submit the changes incase you do any changes
                                        </div>
                                </div>
                            </div>
                    </div>
                        <div class="modal-footer">

                            <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light w-100 text-uppercase">Save Changes</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of vehicle details modal -->

            <!-- receipted bills modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Receipt Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Received from:</strong><br>
                                        Kelvin Njuguna<br>
                                        email@mail.com, 0704 549 859<br>
                                        ID No. 423-410.

                                    </address>
                                </div>
                                <div class="col-sm-6 text-sm-end">
                                    <address class="mt-2 mt-sm-0">
                                        <strong>Payment Method</strong><br>
                                        <span class="fw-medium">MPESA</span><br>
                                        Phone No. 0704 549 859<br> <br>
                                        On 1 Mar 2022, 10:20 AM
                                    </address>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">ND12368</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap vertical-align-middle">
                                        <thead class="bg-light">
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Details</th>
                                                <th class="text-end">Amount</th>

                                            </tr>
                                        </thead>
                                        <tfoot class="bg-light">
                                            <tr>
                                                <td colspan="2" class="border-0 text-end text-right">
                                                    <strong>Total</strong>
                                                </td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 11,250</h5>
                                                </td>

                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>1</td>
                                                <td>
                                                    <p class=" ">Seasonal Parking ticket for Feb 2023 plus Arrears. Vehicle Registrtaion No. KAG123Y  </p>
                                                </td>
                                                <td class="text-end text-right">KES 1000</td>

                                            </tr>

                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>2</td>
                                                <td>
                                                    <p class=" ">Seasonal Parking ticket for Feb 2023 plus Arrears. Vehicle Registrtaion No. KAG123Y  </p>
                                                </td>
                                                <td class="text-end text-right">KES 1000</td>

                                            </tr>

                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>3</td>
                                                <td>
                                                    <p class=" ">Seasonal Parking ticket for Feb 2023 plus Arrears. Vehicle Registrtaion No. KAG123Y  </p>
                                                </td>
                                                <td class="text-end text-right">KES 1000</td>

                                            </tr>

                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>4</td>
                                                <td>
                                                    <p class=" ">Seasonal Parking ticket for Feb 2023 plus Arrears. Vehicle Registrtaion No. KAG123Y  </p>
                                                </td>
                                                <td class="text-end text-right">KES 1000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Receipts/receipts.html" type="button" target="_blank" class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print receipt
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receited bills modal -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
             County Government
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>


    </body>

</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import ToolBar from "@/components/parking/ToolBar";
    import {biller, execute, executeDownload, parking, plotRent} from "@/api";
    import ToolBarPlot from "@/components/plotrent/ToolBar.vue";
    export default {
        name: "ParkingFleetDetailsStatements",
        components: {ToolBarPlot, ToolBar, NavigationBar},
        data(){
            return{
                download:'Download Statement',
                totalBillBalance:0,
                totalBillTotal:0,
                totalReceiptAmount:0,
              zone:'',
              plotNumber:'',
                dateFrom: '',
                dateTo: '',
                billInfo: [
                    {
                        id: "278357",
                        billNo: "BLL0107125040759",
                        feeID: "83844",
                        feeDescription: "Light Truck",
                        fiscalYear: "",
                        paymentCode: "BLL0107125040759",
                        customer: "KCN887D",
                        description: "TEST SACC December 2023",
                        periodID: "1",
                        wardID: "120520258",
                        subCountyID: "12052",
                        subCountyName: "IGEMBE CENTRAL",
                        wardName: "IGEMBE EAST",
                        createdBy: "1",
                        billTotal: "2200",
                        receiptAmount: "0",
                        billBalance: "2200",
                        status: null,
                        dateCreated: "2024-01-06 21:45:05.054831",
                        zone: "KIENGU SLAUGHTER HOUSE",
                        departmentID: "5",
                        department: "Roads, Public Works, Transport And Infrastructure",
                        idNo: "demo",
                        phoneNumber: "254704722837",
                        names: "demo",
                        customerPhoneNumber: "0704766654",
                        category: "SUPER ADMIN",
                        incomeTypePrefix: "PKN"
                    }],
                loading: false,
                currentPage: 1,
                totalPages: 0,
                pageSize: 10,
                summary: {
                    count: 0
                }
            }
        },
        watch: {
            //pagination code
            // define a watcher for the pageSize property
            pageSize(newPageSize) {
                this.getBillInfo("")
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            },
            search(){
                const keyword = this.search;
                this.getBillInfo(keyword)
            }

        },
        mounted() {

            const currentDate = new Date();
            const last3MonthsDate = new Date(currentDate);
            last3MonthsDate.setMonth(currentDate.getMonth() - 3);
            this.dateFrom = last3MonthsDate.toISOString().split('T')[0];
            this.dateTo = this.getCurrentDate()

            this.$watch('dateFrom', function (newValue) {
                this.getBillInfo()
            })

            this.$watch('dateTo', function (newValue) {
                this.getBillInfo()
            })

            this.plotNumber = sessionStorage.getItem("plotNumber")
            this.zone = sessionStorage.getItem("zone")
            this.getBillInfo()
            this.menuAccess()
        },
        methods:{

          menuAccess(){

            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "Plot Statement");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then((res) => {
                  // alert(res.data.message);
                })
                .catch((e) => {
                  // alert(e.message);
                });

          },

            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getBillInfo("")
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getBillInfo("")
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getBillInfo("")
                }
            },

            //number format
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            formatPrice(value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'KES',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            getCurrentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const day = String(today.getDate()).padStart(2, "0");

                return `${year}-${month}-${day}`;
            },
            getBillInfo(){
                this.loading = true
                this.billInfo.splice(0)
                const data = new FormData();
                data.append("function", "getBillInfo");
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("customer", this.plotNumber);
                data.append("zone", this.zone);
                execute(data,plotRent)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                            this.billInfo = res.data.data.billInfo

                            this.totalBillBalance = this.billInfo.reduce((acc, cur) => {
                                let amountTotal = Number(cur.billBalance);
                                if (!isNaN(amountTotal)) {
                                    return acc + amountTotal;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.totalBillTotal = this.billInfo.reduce((acc, cur) => {
                                let amountTotal = Number(cur.billTotal);
                                if (!isNaN(amountTotal)) {
                                    return acc + amountTotal;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.totalReceiptAmount = this.billInfo.reduce((acc, cur) => {
                                let amountTotal = Number(cur.receiptAmount);
                                if (!isNaN(amountTotal)) {
                                    return acc + amountTotal;
                                } else {
                                    return acc;
                                }
                            }, 0);

                            this.summary = res.data.data.summary
                            this.totalPages =  Math.ceil(this.summary.count / this.pageSize);


                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            formatDate(date) {
                let d = new Date(date);
                let day = d.getDate();
                let month = d.toLocaleString('en-US', {month: 'short'});
                let year = d.getFullYear();

                let suffix = '';
                if (day % 10 === 1 && day !== 11) {
                    suffix = 'st';
                } else if (day % 10 === 2 && day !== 12) {
                    suffix = 'nd';
                } else if (day % 10 === 3 && day !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return `${day}${suffix} ${month} ${year}`;
            },
            downloadBillInfo(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadBillInfo");
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("customer", this.numberPlate);
                data.append("zone", this.zone);
                executeDownload(data,parking).then(response => {
                    this.download ="Download Statement"
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = this.dateFrom+' to '+ this.dateTo+"Statement.csv";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
        }
    }
</script>

<style scoped>

</style>