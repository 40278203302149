<template>

  <head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Business Permits</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
          type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body @contextmenu.prevent data-sidebar="dark">

  <!-- <body data-layout="horizontal" data-topbar="dark"> -->
  <!-- Loader -->

  <!-- Begin page -->

  <!-- Begin page -->
  <div id="layout-wrapper">


    <header id="page-topbar">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box">
            <a href="index.html" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="assets/images/logo.svg" alt="" height="22">
                            </span>
              <span class="logo-lg">
                                <img src="assets/images/logo-dark.png" alt="" height="17">
                            </span>
            </a>

            <a href="index.html" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="assets/images/the-logo.png" alt="" height="50">
                            </span>
              <span class="logo-lg">
                                <img src="assets/images/logo-text-white.png" alt="" height="45">
                            </span>
            </a>
          </div>

          <button type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect"
                  id="vertical-menu-btn">
            <i class="fa fa-fw fa-bars"></i>
          </button>
          <!-- logo small -->
          <div class="phone-logo d-md-none d-sm-flex"> <img src="assets/images/color-log0.svg" alt=""
                                                            class="img"></div>



          <!-- App Search-->
          <form class="app-search d-none d-lg-block">
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="Search...">
              <span class="bx bx-search-alt"></span>
            </div>
          </form>


        </div>

        <div class="d-flex">
          <!-- small search -->
          <div class="dropdown d-inline-block d-lg-none ms-2">
            <button type="button" class="btn header-item noti-icon waves-effect"
                    id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
              <i class="mdi mdi-magnify"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                 aria-labelledby="page-header-search-dropdown">

              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search ..."
                           aria-label="Recipient's username">
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="submit"><i
                          class="mdi mdi-magnify"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>




          <div class="dropdown d-none d-lg-inline-block ms-1">
            <button type="button" class="btn header-item noti-icon waves-effect"
                    data-bs-toggle="fullscreen">
              <i class="bx bx-fullscreen"></i>
            </button>
          </div>

          <div class="dropdown d-inline-block d-none">

            <!-- notifications, i will come to this later -->
            <button type="button" class="btn header-item noti-icon waves-effect"
                    id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
              <i class="bx bx-bell bx-tada"></i>
              <span class="badge bg-danger rounded-pill">3</span>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                 aria-labelledby="page-header-notifications-dropdown">
              <div class="p-3">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="m-0" key="t-notifications"> Notifications </h6>
                  </div>
                  <div class="col-auto">
                    <a href="#!" class="small" key="t-view-all"> View All</a>
                  </div>
                </div>
              </div>
              <div data-simplebar style="max-height: 230px;">
                <a href="javascript: void(0);" class="text-reset notification-item">
                  <div class="d-flex">
                    <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-primary rounded-circle font-size-16">
                                                <i class="bx bx-cart"></i>
                                            </span>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="mb-1" key="t-your-order">Your order is placed</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1" key="t-grammer">If several languages coalesce the
                          grammar</p>
                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span
                            key="t-min-ago">3 min ago</span></p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="javascript: void(0);" class="text-reset notification-item">
                  <div class="d-flex">
                    <img src="assets/images/users/avatar-3.jpg"
                         class="me-3 rounded-circle avatar-xs" alt="user-pic">
                    <div class="flex-grow-1">
                      <h6 class="mb-1">James Lemire</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1" key="t-simplified">It will seem like simplified English.
                        </p>
                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span
                            key="t-hours-ago">1 hours ago</span></p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="javascript: void(0);" class="text-reset notification-item">
                  <div class="d-flex">
                    <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-success rounded-circle font-size-16">
                                                <i class="bx bx-badge-check"></i>
                                            </span>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="mb-1" key="t-shipped">Your item is shipped</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1" key="t-grammer">If several languages coalesce the
                          grammar</p>
                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span
                            key="t-min-ago">3 min ago</span></p>
                      </div>
                    </div>
                  </div>
                </a>

                <a href="javascript: void(0);" class="text-reset notification-item">
                  <div class="d-flex">
                    <img src="assets/images/users/avatar-4.jpg"
                         class="me-3 rounded-circle avatar-xs" alt="user-pic">
                    <div class="flex-grow-1">
                      <h6 class="mb-1">Salena Layfield</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1" key="t-occidental">As a skeptical Cambridge friend of
                          mine occidental.</p>
                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span
                            key="t-hours-ago">1 hours ago</span></p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="p-2 border-top d-grid">
                <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                  <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View
                                        More..</span>
                </a>
              </div>
            </div>
          </div>


          <div class="dropdown d-inline-block">
            <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                   alt="Header Avatar">
              <span class="d-none d-xl-inline-block ms-1" key="t-henry">Adroa Balinda</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <a class="dropdown-item" href="myprofile.html"><i
                  class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">My
                                    Profile</span></a>
              <a class="dropdown-item" href="my-logs.html"><i
                  class="bx bx-time font-size-16 align-middle me-1"></i> <span key="t-profile">My
                                    Logs</span></a>
              <a class="dropdown-item" href="auth-lock-screen.html"><i
                  class="bx bx-lock-open font-size-16 align-middle me-1"></i> <span
                  key="t-lock-screen">Lock screen</span></a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-danger" href="auth-login.html"><i
                  class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span
                  key="t-logout">Logout</span></a>
            </div>
          </div>
        </div>
      </div>
    </header>

    <NavigationBar/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Alcoholic Drinks ActiveLicences</h4>

<!--                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="#">Certificates & Licenses</a></li>
                    <li class="breadcrumb-item"><a href="#">Alcoholic Drinks Licences</a></li>
                    <li class="breadcrumb-item active">Certificate Applications</li>
                  </ol>
                </div>-->

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- tool bar -->
          <ToolBarLiquor/>
          <!-- end of toolbar -->
          <div class="row" >
            <div class="col-12">
              <div class="card">
                <div
                    class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                  <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center"
                       role="toolbar">
                    <h4 class="card-title mb-0 text-uppercase ">
                      Active Certificates
                    </h4>
                  </div>
                  <div class="d-flex align-items-center justify-content-center p-3"  role="toolbar">

                    <label class="text-nowrap mb-0 mx-2">Business Category:</label>
                    <select @change="getTradeSubCategory($event)" class="form-control bg-white"
                            data-live-search="true" title="Select Business Activity">
                      <option value=" ">All</option>
                      <option :key="index" :value="item.incomeTypeId" v-for="(item, index) in tradeCategories">{{ item.incomeTypeDescription }}</option>
                    </select>

                    <label class="text-nowrap mb-0 mx-2">Sub Category:</label>
                    <select @change="selectedTradeSubCategory($event)" class="form-control bg-white"
                            data-live-search="true" title="Select Sub Categories">
                      <option :key="index" :value="JSON.stringify(item)"
                              v-for="(item, index) in tradeSubCategories">
                        {{ item.feeDescription }}
                      </option>
                    </select>

                    <label class="text-nowrap mb-0 mx-2">Sub County:</label>
                    <select @change="selectedSubCounty($event)" class="form-control bg-white"
                            data-live-search="true" title="Select Sub County" style="margin-left: 10px">
                      <option :key="index" :value="JSON.stringify(item)"
                              v-for="(item, index) in subCounties">
                        {{ item.subCountyName }}
                      </option>
                    </select>

                    <label class="text-nowrap mb-0 mx-2">Ward:</label>
                    <select @change="selectedWard($event)" class="form-control bg-white"
                            data-live-search="true" title="Select Ward" style="margin-left: 10px">
                      <option :key="index" :value="JSON.stringify(item)"
                              v-for="(item, index) in wards">
                        {{ item.wardName }}
                      </option>
                    </select>

                  </div>
                </div>

                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                  <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                    <div class="flex-grow-1">
                      <div class="search-box mb-0 me-3">
                        <div class="position-relative">
                          <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="mx-3">
                        <div class="float-end d-flex">
                          <div class="input-group input-group fw-semibold text-uppercase">
                            <label class="input-group-text text-uppercase">From</label>
                            <input v-model="dateFrom" class="form-control form-control-sm" type="date"  id="example-date-input">
                          </div>

                          <div class="input-group input-group fw-semibold text-uppercase">
                            <label class="input-group-text text-uppercase">To:</label>
                            <input v-model="dateTo" class="form-control form-control-sm" type="date"  id="example-date-input">
                          </div>
                        </div>
                      </div>
                      <button @click="downloadApplications()" data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                      </button>

                    </div>


                  </div>
                  <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                    <button type="button" data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                  </div>
                </div>

                <div class="card-body">
                  <div class="table-responsive text-nowrap">

                    <table class="table align-middle table-hover  contacts-table table-striped "
                           id="datatable-buttons">
                      <thead class="table-light">
                      <tr class="table-dark">
                        <th scope="col">Invoice NO.</th>
                        <th scope="col">Business</th>
                        <th scope="col">Applicant</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Licence Sub Category</th>
                        <th scope="col">Application Duration</th>
                        <th scope="col">Sub County</th>
                        <th>Date Inspected</th>
                        <th>Status</th>
                        <th scope="col" class="text-right">Invoice Amount</th>
                        <th scope="col" class="text-right">Amount Paid</th>
                        <th class="text-right">Balance</th>
                        <th>Invoiced By</th>

                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr :key="index" :value="item" v-for="(item, index) in applications">
                        <td class="text-capitalize text-nowrap">{{index+1}}. {{item.licenceBillNo}}</td>
                        <td class="text-capitalize text-nowrap">{{item.businessName}}</td>
                        <td class="text-capitalize text-nowrap">{{item.applicant}}</td>
                        <td class="text-capitalize text-nowrap">{{item.phoneNumber}}</td>
                        <td class="text-capitalize text-nowrap"><a href="">{{item.licenceCategory}}</a></td>
                        <td class="text-capitalize text-nowrap"><a href="">{{item.licenceDuration}}</a></td>

                        <td>{{item.subCountyName}}</td>
                        <td>{{formatDateString(item.dateInspected)}}</td>
                        <td><span class="badge rounded-pill bg-success text-uppercase" key="t-new">{{item.status}}</span></td>

                        <td class="text-right">
                          {{item.licenceDetailAmount}}
                        </td>

                        <td class="text-right">
                          {{item.licenceReceiptAmount}}
                        </td>
                        <td class="text-right">
                          {{item.licenceBillBalance}}
                        </td>
                        <td>
                          {{item.issuingOfficer}}
                        </td>

                          <td>
                            <div class="d-flex justify-content-end align-items-center">

                              <button @click="applicationDetials(item)"  type="button"
                                      class="btn btn-primary btn-sm waves-effect waves-light text-nowrap"
                                      data-bs-toggle="modal"
                                      data-bs-target=".receipting-modal"><i
                                  class="bx bx-show font-size-16 align-middle me-2"></i>
                                Details</button>


                              <a  @click="printViewBill(item)"
                                 class="btn btn-success btn-sm waves-effect waves-light mx-3">
                                <i
                                    class="bx bx-receipt font-size-16 align-middle me-2"></i>
                                Invoice
                              </a>

                              <a  @click="printLiquorPermit(item.businessID)"
                                 class="btn btn-success btn-sm waves-effect waves-light mx-3">
                                <i
                                    class="bx bx-receipt font-size-16 align-middle me-2"></i>
                                Print Permit
                              </a>

<!--
                              <button @click="getBill(item.licenceBillNo)" data-toggle="modal" data-target="#payment-modal" type="button" class="btn btn-warning text-black btn-sm waves-effect waves-light text-nowrap"><i
                                  class="bx bx-send font-size-16 align-middle me-2"></i>
                                Receive Payment</button>
-->

                            </div>
                          </td>

                      </tr>

                      </tbody>
                      <tfoot class="table-dark">
                      <tr>

                        <th colspan="2">
                          23 Applications
                        </th>


                        <th colspan="3" class="text-success">
                          PAID APPLICATIONS: 13
                        </th>

                        <th colspan="4" class="text-warning">
                          PENDING PAYMENTS: 10
                        </th>

                        <th  class="text-right">
                          500.00
                        </th>

                        <th  class="text-right">
                          500.00
                        </th>

                        <th  class="text-right">
                          1500.00
                        </th>
                        <th colspan="2"></th>
                      </tr>

                      </tfoot>
                    </table>

                    <div class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center"
                         v-if="loading">
                      <div class="p-5 py-0 pt-3">
                        <div class="p-5">
                          <div class="spinner-border text-info m-1" role="status">
                            <span class="sr-only text-uppercase">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <h4 class="text-uppercase">Loading Data</h4>
                      <div class="col-sm-12 col-md-8 col-lg-5">
                        <p>We are fetching the data for you. It will be ready soon. Please wait
                          and have fun. Thank you for your interest.</p>

                      </div>
                    </div>
                    <div class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center"
                         v-if="applications.length<1 & loading === false">
                      <div class="p-5 py-0 pt-3">
                        <img :src="'./assets/images/no-results.png'" alt="No search results"
                             class="img mb-4"/>
                      </div>
                      <h4>No Results To Show</h4>
                      <p>Sorry, we could not find any results that match your search or filter
                        criteria. Please try using different keywords or filters.</p>
                    </div>

                  </div>
                </div>

                <div class="card-footer bg-white">
                  <nav aria-label="Page navigation example "
                       class="d-flex align-items-center justify-content-between">

                    <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                      <select class="form-select w-auto font-13px " v-model="pageSize">
                        <option value="10">10 Rows</option>
                        <option value="25">25 Rows</option>
                        <option value="50">50 Rows</option>
                        <option value="100">100 Rows</option>
                        <option value="150">150 Rows</option>
                        <option value="200">200 Rows</option>
                      </select>
                    </div>

                    <ul class="pagination">
                      <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                      <li :class="{ disabled: currentPage === 1 }" class="page-item">
                        <a :disabled="currentPage === 1" @click="prevPage" class="page-link"
                           tabindex="-1">Previous</a>
                      </li>
                      <!-- replace the v-for directive with the pagination function call -->
                      <li :class="{ active: currentPage === page }" :key="page" class="page-item"
                          v-for="page in pagination(currentPage, totalPages, 3)">
                        <!-- check if the page is an ellipsis and display it as plain text -->
                        <span class="page-link" v-if="page === '...'">{{ page }}</span>
                        <!-- otherwise, display it as a clickable link -->
                        <a @click="goToPage(page)" class="page-link" v-else>{{ page }}</a>
                      </li>
                      <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                      <li :class="{ disabled: currentPage === totalPages }" class="page-item">
                        <a :disabled="currentPage === totalPages" @click="nextPage"
                           class="page-link">Next</a>
                      </li>
                    </ul>

                  </nav>
                </div>

              </div>
            </div>
            <!-- end col -->
          </div>
          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <!-- Application details modal -->
      <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-full">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Application Details.</h5>
              <span
                  class="badge rounded-pill bg-success text-uppercase font-12px text-uppercase mx-3">PAID</span>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-12" style="overflow-x: auto;">
                  <div class="hori-timeline" style="white-space: nowrap;">
                    <div class="owl-carousel owl-theme navs-carousel events timeline-carousel" id="timeline-carousel" style="display: flex; flex-wrap: nowrap;">


                      <div :class="item.completed === 'active' ? 'active' : ''" class="item event-list col-2" v-for="(item, index) in applicationStatuses" :key="item.statusID" style="width: 150px;">

                        <div>
                          {{index+1}}
                          <div class="event-date">
                            <div class="text-primary mb-1">{{getHumanDate(item.updatedDate)}}</div>
                            <h5 class="mb-4">{{item.status}}</h5>
                          </div>
                          <div class="event-down-icon">
                            <i v-if="item.completed==='true'" class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                            <i v-if="item.completed==='false' || item.completed==='active'" class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a href="javascript: void(0);">{{item.issuingOfficer}}</a></p>

                            <p v-if="item.statusID !== '2'" class="text-muted text-capitalize mb-1"><i class="bx bx-map"></i>{{item.location}}</p>
                            <p v-if="item.completed === 'true' && item.statusID==='2'" class="text-muted text-capitalize mb-1"><a href="javascript: void(0);"><i class="bx bx-receipt"></i> View Receipt</a></p>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div class="col-sm-12 col-md-12 d-none">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-map font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Map View</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="col-sm-12 col-md-12">
                  <div class="row">
<!--                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="dripicons-user font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Owner </p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">

                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Owner's Name</p>
                            </td>
                            <td class="">Kelvin Njuguna</td>


                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                ID No.</p>
                            </td>
                            <td class="">1234557768</td>



                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                Phone</p>

                            </td>
                            <td class="">12345</td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Email
                              </p>

                            </td>
                            <td class="">12345</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>-->

                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Details </p>

                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">

                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business/ Company name</p>
                            </td>
                            <td class="">{{application.businessName}}</td>


                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business Category</p>
                            </td>
                            <td class="">{{application.businessCategory}}</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business Sub Category</p>

                            </td>
                            <td class="">{{application.businessSubCategory}}</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Sub County</p>

                            </td>
                            <td class="">{{application.subCountyName}}</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Ward</p>

                            </td>
                            <td class="">{{application.wardName}}</td>
                          </tr>



                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Plot Number</p>

                            </td>
                            <td class="">{{application.plotNumber}}</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Invoicing Year</p>

                            </td>
                            <td class="">{{application.fiscalYear}}</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business ID</p>

                            </td>
                            <td class="">{{application.businessID}}</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                SBP Invoicing Number</p>

                            </td>
                            <td class="">{{application.billNo}}</td>
                          </tr>


                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-receipt font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0 text-uppercase">ALCOHOLIC DRINKS LICENCE Application details</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">

                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Date Invoiced</p>
                            </td>
                            <td class="">{{formatDateString(application.dateOfIssue)}}</td>


                          </tr>



                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Invoice Number</p>
                            </td>
                            <td class="">
                              <a href="PrintDocs/Bill/bill.html" target="new">{{application.billNo}}</a>
                            </td>


                          </tr>



                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Licence Application Fee</p>
                            </td>
                            <td class="">{{application.billTotal}}</td>


                          </tr>


                          </tbody>
                        </table>
                      </div>
                    </div>


                    <div class="col-12 mt-4">
                      <div class="bg-warning bg-soft border-2  p-2 mb-4 d-flex align-items-center text-black">
                        <i class="mdi mdi-fountain-pen-tip font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0 text-uppercase">Licence Application Details</p>

                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">

                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Date Inspected</p>
                            </td>
                            <td class="">{{application.dateInspected}}</td>


                          </tr>



                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Licence Category</p>
                            </td>
                            <td class="">{{application.licenceCategory}} </td>


                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Licence Sub Category</p>
                            </td>
                            <td class="">({{application.licenceDuration}}) {{application.licenceSubCategory}}</td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Licence Duration</p>
                            </td>
                            <td class="">{{application.licenceDuration}}</td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12 mt-4">
                      <div class="bg-warning bg-soft border-2  p-2 mb-4 d-flex align-items-center text-black">
                        <i class="bx bx-search-alt font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0 text-uppercase">Inspection Notes</p>

                      </div>
                    </div>

                    <div class="col-12">
                      <div class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Inspection Notes</h4>
                        <p>{{application.comments}}</p>
                        <hr>
                        <p class="mb-0 fw-semibold text-uppercase">Inspected By {{application.issuingOfficer}}</p>
                      </div>
                    </div>

                    <div class="col-12 mt-4">
                      <div class="bg-warning bg-light border-2  p-2 mb-4 d-flex align-items-center text-black">
                        <i class="mdi mdi-file-eye font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0 text-uppercase">Courte Review Outcome</p>

                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">

                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Approval Minutes Number</p>
                            </td>
                            <td class="">{{application.minutesNumber}}</td>


                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Minutes Document</p>
                            </td>
                            <td>
                              <a :href="documentULR" target="new">{{application.minutesFile}}</a>
                            </td>
                          </tr>


                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="alert alert-success" role="alert">
                        <h4 class="alert-heading">Approval Details</h4>
                        <p>This application is duly approved since the applicant met all the set standards.</p>
                        <hr>
                        <p class="mb-0 fw-semibold text-uppercase">Approved By Sammy Zayne</p>
                      </div>
                    </div>


                  </div>
                </div>

              </div>

            </div>
            <div class="modal-footer d-none">

              <a href="PrintDocs/Receipts/receipts-HOMabay county.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Receipt
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of application details modal -->

      <!-- payment modal -->
      <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>
                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                </button>
              </div>

              <div class="payment-panel-parent">

                <div v-if="paymentPanel" class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4 pb-4 pt-4">
                    <img src="assets/images/bills.svg" height="200" alt="">
                  </div>
                  <h4 class="text-black fw-bold">{{message}}</h4>
                  <p class="text-muted mb-4">Print the bill or receive payment for this bill.</p>
                  <div class="d-flex w-100 pb-2">
                    <button @click="showPaymentMethod()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                      <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                      Proceed to Receive Payment
                    </button>
                    <a @click="printBill()" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                  </div>
                </div>

                <div v-if="paymentMethod" class="send-method payment-panel ">
                  <label for="" class="mb-0 pb-0">Payment Method</label>
                  <p><small class="text-muted">How would you like to send this money?</small></p>

                  <div>
                    <div class="accordion" id="accordionExample">
                      <!--MPESA-->
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                              <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div>
                              <p>
                                A payment request of <strong class="text-black">KES {{billDetails.detailAmount}}</strong> will be sent to the MPESA number you enter below.
                              </p>
                            </div>
                            <div class="form-group">
                              <label for="">MPESA Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Phone No.</label>
                              </div>

                              <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                Send Payment Request
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>

                      <!--BANK-->
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                              <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>

                            </div>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div class="form-group">
                              <label for="">Enter The Bank's Receipt Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Bank Ref No.</label>
                              </div>

                              <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--CASH-->
                      <!-- <div class="accordion-item">
                           <h2 class="accordion-header" id="headingTwo">
                               <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                   <div class="flex-shrink-0 me-3">
                                       <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                                   </div>
                                   <div class="d-flex flex-column">
                                       <p class="m-0 p-0 text-uppercase fw-semibold">Cash</p>
                                       <p class="mb-0 p-0"> <small>By selecting this option you confirm that you have received the payment inform of cash</small></p>

                                   </div>
                               </button>
                           </h2>
                           <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                               <div class="accordion-body">

                                   <div class="form-check form-check-primary mb-3">
                                       <input v-model="confirmCash" class="form-check-input" type="checkbox" id="confirm-cash">
                                       <label class="form-check-label" for="confirm-cash">
                                           Confirm having received <strong>KES {{billDetails.billBalance}}</strong>
                                       </label>
                                   </div>

                                   {{message}}
                                   <br/>
                                   <button v-if="confirmCash" @click="showTransactionSummary('CASH')"  type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                       <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                       Receive Cash Payment
                                   </button>

                               </div>
                           </div>
                       </div>-->
                    </div>
                  </div>
                </div>

                <div v-if="transactionSummary" class="transaction-summary payment-panel">
                  <label for="">Transaction Breakdown</label>
                  <div class="border p-4 rounded ">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="text-muted ">
                          BillO NO.
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billDetails.billNo}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Bill For
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billDetails.incomeTypeDescription}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Payment Method
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{paymentData.paymentMode}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Transaction Amount
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          KES {{billDetails.billBalance}}
                        </div>
                      </div>


                      <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                      <div class="col-lg-6 text-uppercase">
                        <div class="text-muted mt-2 font-21">
                          Total
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end text-uppercase">
                        <div class=" text-right text-black font-21 fw-bold">
                          KES {{billDetails.billBalance}}
                        </div>
                      </div>
                    </div>

                    <div class="mpesa-confirmation ">
                      <div v-if="paymentData.paymentMode==='MPESA'">
                        <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ phoneNumber }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                          <br>
                          <br>
                          Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                      </div>

                      <div>{{message}}  </div>
                      <br>
                      <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="stk-timer-container d-none justify-content-center align-items-center">
                            <span class="mdi mdi-timer-outline font-16px"></span>
                            <span class="stk-timer px-2"></span>
                          </div>
                          <div class="justify-content-center align-items-center d-flex">
                            <span class="px-2">Send Request</span>
                            <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                          </div>
                        </div>
                      </button>
                    </div>


                  </div>
                </div>

                <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4">
                    <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                  </div>
                  <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                  <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
                  <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                    <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                    Print Receipt
                  </button>
                </div>



              </div>
            </div>
            <div class="modal-footer d-flex d-none bill-modal-footer">
              <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i>Previouse</button>
              <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
              <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of payment modal-->

      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">
                County Billing System
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay"></div>


  </body>


</template>


<script>

/* eslint-disable */

import {execute, getRight, biller, trade, liquor, authUrl, executeDownload, parking, uploadsLiqour, rent} from "@/api";

import NavigationBar from "@/components/Navigation.vue";
import ToolBarLiquor from "@/components/liquor/ToolBarLiquor.vue";
import setAccounts from "@/components/systemusers/SetAccounts.vue";
import moment from "moment/moment";
import declined from "@/components/sbp/Declined.vue";

export default {
  name: "LoginPage",
  computed: {
    declined() {
      return declined
    },
    setAccounts() {
      return setAccounts
    }
  },
  components: {ToolBarLiquor, NavigationBar},
  data() {
    return{
      phoneNumber:'',
      documentULR:'',
      selectedFile:null,
      approveRemarks:'',
      declinedReason: '',
      approvedStatus:'',
      eligibilityCheck:'',
      minutesNumber:'',
      comments:'',
      download:'Download Report',
      // set the initial current page as 1
      currentPage: 1,
      // set the total number of pages as 10 (you can change this according to your data source)
      totalPages: 0,
      // set the number of items per page as 5 (you can change this according to your preference)
      pageSize: 10,
      summary: {
        count: 0,
        total: 0
      },
      totalAmount: 0,
      search:'',
      dateFrom: '',
      dateTo: '',
      loading:false,
      message:null,
      fiscalYear:'',
      invoiceDetails: false,
      generatingInvoice: false,
      businessSearch: false,
      businessDetailsResult : false,
      businessID:null,
      feesAndChargeItems:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        customer: null
      }],
      subCounty:{},
      ward:{},
      billItem:[],
      quantity:1,
      applications:[{
        id: null,
        billNo: null,
        businessID:null,
        businessName:null,
        billTotal:null,
        receiptAmount:null,
        billBalance:null,
        statusID:null,
        status:null,
        printable:null,
        issuingOfficer:null,
        designation:null,
        dateOfIssue:null,
        stageStatus:null,
        comments:null,
        incomeTypeId:null,
        feeID:null,
        subCountyName:null,
        wardName:null,
        subCountyID:null,
        wardID:null,
        brimsCode:null,
        plotNumber:null,
        fiscalYear:null,
        applicant:null,
        phoneNumber:null,
        businessSubCategory:null,
        businessCategory:null,
        dateInspected:null,
        licenceCategory:null,
        licenceSubCategory:null,
        licenceDuration:null,
        licenceFeeID:null,
        licenceBillNo:null,
        licenceDetailAmount:null,
        licenceReceiptAmount:null,
        licenceBillBalance:null
      },],
      application:{
        id: null,
        billNo: null,
        businessID:null,
        businessName:null,
        billTotal:null,
        receiptAmount:null,
        billBalance:null,
        statusID:null,
        status:null,
        printable:null,
        issuingOfficer:null,
        designation:null,
        dateOfIssue:null,
        stageStatus:null,
        comments:null,
        incomeTypeId:null,
        feeID:null,
        subCountyName:null,
        wardName:null,
        subCountyID:null,
        wardID:null,
        brimsCode:null,
        plotNumber:null,
        fiscalYear:null,
        applicant:null,
        phoneNumber:null,
        businessSubCategory:null,
        businessCategory:null,
        dateInspected:null,
        licenceCategory:null,
        licenceSubCategory:null,
        licenceDuration:null,
        licenceFeeID:null,
        licenceBillNo:null,
        licenceDetailAmount:null,
        licenceReceiptAmount:null,
        licenceBillBalance:null,
        minutesNumber:null,
        minutesFile:null
      },
      licenceCategories: [{
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix: null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategoryID: null,
        zoneCategory: null
      }],
      licenceSubCategories: [
        {
          feeId: null,
          feeDescription: null,
          unitOfMeasure: null,
          unitFeeAmount: null,
          accountNo: null,
          incomeTypeId: null,
          feeType: null,
          accountDesc: null,
          costCenterNo: null,
          zone: null,
          typeDescription: null,
          prorated: null,
          duration: null
        }
      ],
      tradeCategories: [{
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix: null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategoryID: null,
        zoneCategory: null
      }],
      tradeSubCategories: [
        {
          feeId: null,
          feeDescription: null,
          unitOfMeasure: null,
          unitFeeAmount: null,
          accountNo: null,
          incomeTypeId: null,
          feeType: null,
          accountDesc: null,
          costCenterNo: null,
          zone: null,
          typeDescription: null,
          prorated: null,
          duration: null
        }
      ],
      subCounties: [{
        subCountyID: "",
        subCountyName: ""
      }],
      licenseDurations: [{
        id: null,
        duration: null
      }],
      wards: [{
        wardID: "",
        wardName: ""
      }],
      billDetails:{
        id: "",
        billNo: "",
        incomeTypeID: "",
        incomeTypeDescription: "",
        costCenterNo: "",
        accountNo: ",",
        description: "",
        feeID: "",
        detailAmount: "",
        receiptAmount: "",
        billBalance: "",
        wardID: "",
        subCountyID: "",
        status: "",
        dateCreated: ""
      },
      duration:'',
      selectedFeeAndCharge:{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc: null,
        costCenterNo: null,
        zone: null,
        typeDescription: null,
        prorated: null,
        duration: null
      },
      selectedIncomeType: {
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix: null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategoryID: null,
        zoneCategory: null
      },
      applicationStatuses: [
        {
          id: null,
          businessID:  null,
          statusID:  null,
          status:  null,
          description: null,
          dateCreated:  null,
          completed:  null,
          billNo: null,
          comments: null,
          issuingOfficer: null,
          updatedDate: null,
          location: null
        }
      ],
      transactionCompleted: false,
      transactionSummary: false,
      paymentPanel: true,
      paymentMethod: false,
      receiptDetails: {
        id: '',
        receiptNo: null,
        billNo: '',
        detailAmount: '',
        receiptAmount: '',
        billBalance: '',
        costCenterNo: '',
        accountNo: '',
        incomeTypeDescription: '',
        feeID: '',
        wardID: '',
        subCountyID:'',
        currency: '',
        source: '',
        transactionCode: '',
        paidBy: '',
        dateCreated: '',
        dateModified: '',
        createdBy: '',
        modifiedBy: '',
        isActive: '',
        status: ''
      },
      receiptInfos: [
        {
          id: '',
          receiptNo: '',
          billNo: '',
          billTotal: '',
          receiptAmount: '',
          billBalance: '',
          customer: '',
          clientPhoneNo: '',
          description: '',
          receiptDate: '',
          printCount: '',
          wardID: '',
          subCountyID: '',
          dateCreated: '',
          dateModified: '',
          createdBy: '',
          modifiedBy: '',
          printedBy: '',
          updated: '',
          isActive: '',
          status: ''
        }],
      paymentData: {
        bankReceiptNo: null,
        accNo: null,
        payBillNo: null,
        amount: null,
        phoneNumber: null,
        success: false,
        paymentMode:null,
        sessionNames:null,
        sessionIdNo:null,
        sessionPhoneNumber:null
      },

    }
  },
  mounted() {

    this.menuAccess();

    $("#timeline-carousel").owlCarousel({
      items: 1,
      loop: !1,
      margin: 0,
      nav: !0,
      navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
      dots: !1,
      responsive: {
        576: {
          items: 2
        },
        768: {
          items: 4
        }
      },
      startPosition: -1 // display the last item first
    });

    this.dateFrom = moment().startOf('month').format('YYYY-MM-DD')
    this.dateTo = moment().format('YYYY-MM-DD')

    this.getTradeCategory()
    this.getSubCounties()
    this.getApplications("")
    this.getLicenseDuration()
    this.getLicenceCategory()


    this.$watch('dateFrom', function (newValue) {
      if (this.dateFrom === '' || this.dateTo === '') {

      } else {
        this.getApplications("")
      }

    })
    this.$watch('dateTo', function (newValue) {
      if (this.dateFrom === '' || this.dateTo === '') {

      } else {
        this.getApplications("")
      }
    })

  },
  watch: {
    businessID(){
      const keyword = this.businessID;
      this.businessSearch = false
      this.getBusiness(keyword)
    },
    search(){
      const keyword = this.search;
      this.getApplications(keyword)
    },
    pageSize(newPageSize) {
      this.getApplications("")
      // get the total number of items from your data source (you can change this according to your logic)
      const totalItems = this.summary.count;
      // calculate the new total pages by dividing the total items by the new page size and rounding up
      const newTotalPages = Math.ceil(totalItems / newPageSize);
      // update the totalPages data property with the new value
      this.totalPages = newTotalPages;
      // reset the current page to 1
      this.currentPage = 1;

    },
  },
  methods: {
    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Liquor Permit");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then((res) => {
            // alert(res.data.message);
          })
          .catch((e) => {
            // alert(e.message);
          });

    },
    //pagination code
    pagination(currentPage, totalPages, maxVisibleButtons) {
      //initialize an empty array for the pages
      let pages = [];
      //calculate the start and end indices of the visible buttons
      let start = currentPage - Math.floor(maxVisibleButtons / 2);
      let end = currentPage + Math.floor(maxVisibleButtons / 2);
      //adjust the start and end indices if they are out of bounds
      if (start < 1) {
        start = 1;
        end = start + maxVisibleButtons - 1;
        if (end > totalPages) {
          end = totalPages;
        }
      }
      if (end > totalPages) {
        end = totalPages;
        start = end - maxVisibleButtons + 1;
        if (start < 1) {
          start = 1;
        }
      }
      //loop through the start and end indices and push the pages to the array
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      //add an ellipsis at the beginning if there are more pages before the start index
      if (start > 1) {
        pages.unshift("...");
      }
      //add an ellipsis at the end if there are more pages after the end index
      if (end < totalPages) {
        pages.push("...");
      }
// return the array of pages
      return pages;
    },
    // define a method to go to the previous page
    prevPage() {
      // check if the current page is not the first page
      if (this.currentPage > 1) {
        // decrement the current page by 1
        this.currentPage--;
        this.getApplications("")
      }
    },
    // define a method to go to the next page
    nextPage() {
      // check if the current page is not the last page
      if (this.currentPage < this.totalPages) {
        // increment the current page by 1
        this.currentPage++;
        this.getApplications("")
      }
    },
    // define a method to go to a specific page
    goToPage(page) {
      // check if the page is valid and not equal to the current page
      if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
        // set the current page to the given page
        this.currentPage = page;
        this.getApplications("")
      }
    },

    //number format
    numberFormat(num) {
      const britishNumberFormatter = new Intl.NumberFormat("en-GB");
      return britishNumberFormatter.format(num)
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'KES',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },

    getLicenseDuration(){
      const data = new FormData();
      data.append("function", "getLicenseDuration");
      execute(data,liquor)
          .then((res) =>{
            if (res.data.success) {
              this.licenseDurations = res.data.data.licenseDurations
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    handleFileChange: function (event) {
      console.log("File selected:", event.target.files[0]);
      this.selectedFile = event.target.files[0];
    },
    getSubCounties(){
      const data = new FormData();
      data.append("function", "getSubCounties");
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.subCounties = res.data.data.subCounties
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    getWards(subCountyID){
      const data = new FormData();
      data.append("function", "getWards");
      data.append("subCountyID",subCountyID);
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.wards = res.data.data.wards
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    getApplicationStatus() {
      const data = new FormData();
      data.append("function", "getApplicationStatus");
      data.append("billNo", this.application.billNo);

      execute(data, liquor)
          .then((res) => {
            if (res.data.success) {
              // Reorder the application statuses to ensure "Invoicing" comes first
              const statuses = res.data.data.applicationStatuses;
              const invoicingStatus = statuses.find(status => status.status === "Invoicing");
              const otherStatuses = statuses.filter(status => status.status !== "Invoicing");
              this.applicationStatuses = invoicingStatus ? [invoicingStatus, ...otherStatuses] : statuses;

            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },

    getLicenceCategory() {
      this.licenceCategories.splice(0)
      const data = new FormData();
      data.append("function", "getIncomeTypes");
      data.append("incomeTypePrefix", "SBP");
      execute(data, biller)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.licenceCategories = res.data.data.incomeTypes
            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    getLicenceSubCategory(event) {

      this.selectedIncomeType =  JSON.parse(event.target.value)

      this.licenceSubCategories.splice(0)
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId",this.selectedIncomeType.incomeTypeId);
      execute(data, biller)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.licenceSubCategories = res.data.data.feesAndCharges
            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    getHumanDate(dateData){
      //  const res = dateData.split(" ");
      if(dateData=="-"){
        return "-";
      }else {
        const dateObject = new Date(Date.parse(dateData));
        return dateObject.toDateString();
      }


    },
    selectedLicenceSubCategory(event) {
      const data = JSON.parse(event.target.value)
      this.selectedFeeAndCharge = data;
    },
    approveDeclineApplication(reviewStatus) {
      const data = new FormData();
      data.append("function", "approveDeclineApplication");
      data.append("billNo", this.application.billNo);
      data.append("createdBy",sessionStorage.getItem("sessionNames"))
      data.append("idNo", sessionStorage.getItem("sessionIdNo"))
      data.append("businessName", this.application.businessName);
      data.append("approveRemarks", this.approveRemarks);
      data.append("declinedReason", this.declinedReason);
      data.append("minutesNumber", this.minutesNumber);
      data.append("fileToUpload", this.selectedFile);
      data.append("reviewStatus", reviewStatus);//Approve/Decline
      execute(data, liquor)
          .then((res) => {
            if (res.data.success) {
            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    getTradeCategory() {
      this.loading = true
      this.message = null
      const data = new FormData();
      data.append("function", "getIncomeTypes");
      data.append("incomeTypePrefix", "SBP");
      execute(data, biller)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.tradeCategories = res.data.data.incomeTypes
            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    getTradeSubCategory(event) {
      this.loading = true
      this.getApplications(event.target.value)
      this.message = null
      this.tradeSubCategories.splice(0)
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId", event.target.value);
      execute(data, biller)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.tradeSubCategories = res.data.data.feesAndCharges
            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    selectedTradeSubCategory(event) {
      const data = JSON.parse(event.target.value)
      this.getApplications(data.feeId)
    },
    selectedLicenseDurations(event) {
      this.duration = event.target.value
    },
    selectedSubCounty(event) {
      const data = JSON.parse(event.target.value)
      this.getWards(data.subCountyID)
      this.getApplications(data.subCountyName)

    },
    selectedWard(event) {
      const data = JSON.parse(event.target.value)
      this.getApplications(data.wardName)
    },
    formatDateString(dateString) {
      // Convert the string to a Date object
      let date = new Date(dateString);

      // Define month names array
      let monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      // Function to format the time to AM/PM format
      function formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
      }

      // Format the date
      let formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()} ${formatAMPM(date)}`;

      return formattedDate;
    },
    downloadApplications(){
      this.download ="Please wait.."
      const data = new FormData();
      data.append("function", "downloadApplications");
      data.append("statusID", "2");
      data.append("dateFrom", this.dateFrom)
      data.append("dateTo", this.dateTo)
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);

      executeDownload(data,liquor).then(response => {
        this.download ="Download Report"
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = this.dateFrom+' to '+ this.dateTo+" applications.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getApplications(search){
      this.applications.splice(0)
      const data = new FormData();
      data.append("function", "getApplications");
      data.append("statusID", "4");
      data.append("printable", "true");
      data.append("search", search)
      data.append("dateFrom", this.dateFrom)
      data.append("dateTo", this.dateTo)
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      execute(data,liquor)
          .then((res) =>{
            if (res.data.success) {
              this.applications = res.data.data.applications

              this.totalItems = res.data.data.totalItems.count
              this.totalPages =  Math.ceil(this.totalItems / this.pageSize);

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    updateInspection(){
      const data = new FormData();
      data.append("function", "updateInspection");
      data.append("comments", this.comments);
      data.append("businessName", this.application.businessName);
      data.append("createdBy",sessionStorage.getItem("sessionNames"))
      data.append("idNo", sessionStorage.getItem("sessionIdNo"))
      data.append("billNo", this.application.billNo)
      data.append("licenceFeeID", this.selectedFeeAndCharge.feeId);
      data.append("licenceDuration", this.duration);
      data.append("licenceSubCategory", this.selectedFeeAndCharge.feeDescription);
      data.append("licenceCategory", this.selectedIncomeType.incomeTypeDescription);
      execute(data,liquor)
          .then((res) =>{
            if (res.data.success) {

              this.getApplications("")

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    applicationDetials(item){
      this.application = item;
      this.documentULR = uploadsLiqour+''+ this.application.minutesFile
      this.getBill(this.application.licenceBillNo)
      this.getApplicationStatus()
    },

    printViewBill(item){
      this.application = item;
      this.billDetails.billNo = item.licenceBillNo
      this.printBill()
    },
    printLiquorPermit(businessID) {

      const data = new FormData();
      data.append("function", "printLiquorPermit");
      data.append("businessID", businessID);
      execute(data, liquor)
          .then((res) => {
            if (res.data.success) {

              localStorage['params'] = JSON.stringify({
                businessID: businessID
              })
              const routeData = this.$router.resolve({name: 'liquor-permit'});
              window.open(routeData.href, '_blank');

            } else {
              this.businessNotFound = true
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },

    getBill(billNo) {
    //  this.message = "Please wait"
      const data = new FormData();
      data.append("function", "getBill");
      data.append("billNo", billNo);
      execute(data, biller)
          .then((res) => {
            if (res.data.success) {

              this.billDetails = res.data.data.billDetails
              this.billInfo = res.data.data.billInfo
              this.paymentData.amount = res.data.data.billDetails.billBalance
              this.paymentData.accNo = res.data.data.billDetails.billNo
              this.paymentData.sessionNames = sessionStorage.getItem("sessionNames")
              this.paymentData.sessionIdNo = sessionStorage.getItem("sessionIdNo")
              this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")
              this.message = null


            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message
          });
    },
    showPaymentMethod(){
      this.paymentMethod = true;
      this.paymentPanel = false;
      this.transactionSummary = false;
      this.transactionCompleted = false
    },
    printBill(){
      localStorage['params'] = JSON.stringify({
        billNo: this.billDetails.billNo
      })
      const routeData = this.$router.resolve({name: 'bill'});
      window.open(routeData.href, '_blank');
    },
    printReceipt() {
      if (this.receiptDetails.receiptNo == null) {
        alert("Try again later");
      } else {
        //  this.$refs.closeReceived.click();
        localStorage['params'] = JSON.stringify({
          receiptNo: this.receiptDetails.receiptNo
        })
        const routeData = this.$router.resolve({name: 'receipt'});
        window.open(routeData.href, '_blank');
      }
    },
    showTransactionSummary(paymentMode){

      this.paymentData.paymentMode = paymentMode

      if(paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }

      if(paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }

      this.paymentMethod = false;
      this.paymentPanel = false;
      this.transactionSummary = true;
      this.transactionCompleted = false
    },
    makePayment() {
      if(this.paymentData.paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }

      if(this.paymentData.paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }

      this.message = "Payment Request sent";
      this.paymentData.phoneNumber = this.phoneNumber

      const data = new FormData();
      data.append("function", "makePayment");
      data.append("paymentData", JSON.stringify(this.paymentData));

      let stream = null;
      if (this.paymentData.accNo.startsWith("SBP")) {
        stream = trade;
      }
      if (this.paymentData.accNo.startsWith("PKN")) {
        stream = parking;
      }

      if (this.paymentData.accNo.startsWith("RNT")) {
        stream = rent;
      }

      if (this.paymentData.accNo.startsWith("BLL")) {
        stream = biller;
      }

      execute(data, stream)
          .then((res) => {
            if (res.data.success) {
              this.paymentMethod = false;
              this.paymentPanel = false;
              //start checking payment
              this.timer = setInterval(() => {

                this.checkPayment();
              }, 3000);
            } else {
              this.rate = null;
              alert(res.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    checkPayment() {
      this.randomNumber = Math.floor(Math.random() * 3) + 1
      if(this.randomNumber === 1){
        this.message = "Waiting for payment.";
      }
      if(this.randomNumber === 2){
        this.message = "Waiting for payment..";
      }
      if(this.randomNumber ===3){
        this.message = "Waiting for payment...";
      }
      const data = new FormData();
      data.append("function", "checkPayment");
      data.append("accountRef", this.paymentData.accNo);

      let stream = null;
      if (this.paymentData.accNo.startsWith("SBP")) {
        stream = trade;
      }
      if (this.paymentData.accNo.startsWith("PKN")) {
        stream = parking;
      }
      if (this.paymentData.accNo.startsWith("RNT")) {
        stream = rent;
      }
      if (this.paymentData.accNo.startsWith("BLL")) {
        stream = biller;
      }
      execute(data, stream)
          .then((res) => {
            if (res.data.success) {
              clearInterval(this.timer);
              this.receipt = true;
              this.paymentData.success = false;
              this.transaction = res.data.data.transaction;
              this.receiptDetails = res.data.data.receipt.receiptDetails
              //Close the modal
              this.transactionSummary = false
              this.transactionCompleted = true
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },


  }
}

</script>


<style scoped>

</style>