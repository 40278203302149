    <template>
    <div class="row">
        <div class="col-12 active">
            <div class="card active">
                <div class="card-body mini-nav bg-primary pt-2 pb-3 active ">
                    <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
                        <button
                                class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary"
                                type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span class="mdi mdi-menu"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between active"
                             id="navbarNavAltMarkup">

                            <div class="navbar-nav">

                                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'cert-biz-stats'}" active-class="mm-active" class="nav-item nav-link">Dashboard</router-link>
                                <router-link v-if="getRight('PENDING VALIDATION')" :to="{name: 'pending-validation'}" active-class="mm-active" class="nav-item nav-link">Pending Validation</router-link>
                                <router-link v-if="getRight('PENDING INSPECTION')" :to="{name: 'pending-inspection'}" active-class="mm-active" class="nav-item nav-link">Pending Inspection</router-link>
                                <router-link v-if="getRight('PENDING APPROVAL')" :to="{name: 'pending-approval'}" active-class="mm-active" class="nav-item nav-link">Pending Approval</router-link>
                                <router-link v-if="getRight('DECLINED')" :to="{name: 'declined'}" active-class="mm-active" class="nav-item nav-link">Declined</router-link>
                                <router-link v-if="getRight('APPROVED')" :to="{name: 'approved'}" active-class="mm-active" class="nav-item nav-link">Approved</router-link>
                                <router-link v-if="getRight('PAID SBPs')" :to="{name: 'paid-sbp'}" active-class="mm-active" class="nav-item nav-link">Paid SBPs</router-link>
                                <router-link v-if="getRight('UNPAID SPBs')" :to="{name: 'unpaid-sbp'}" active-class="mm-active" class="nav-item nav-link">Unpaid SBPs</router-link>
                                <router-link v-if="getRight('PENDING BILLS')" :to="{name: 'sbp-unpaid-bills'}" active-class="mm-active" class="nav-item nav-link">Pending Bills</router-link>
                                <router-link v-if="getRight('SBP RECEIPTS')" :to="{name: 'sbp-paid-bills'}" active-class="mm-active" class="nav-item nav-link">SBP Receipt</router-link>
                                <router-link v-if="getRight('MAP VIEW')" :to="{name: 'sbp-map'}" active-class="mm-active" class="nav-item nav-link">Map View</router-link>
                                 <router-link  :to="{name: 'business-register'}" active-class="mm-active" class="nav-item nav-link">Business Registry</router-link>

                            </div>


                        </div>
                    </nav>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {getRight} from "@/api";

    export default {
        name: "ToolBar",
        data() {
            return {
                dashboard: true,
                validation: true,
                inspection: true,
                approval: true,
                declined: true,
                approved: true,
                paidSbp: true,
                unpaidSbp: true,
                unpaidBills: true,
                sbpPaidBills: true,
                sbpMap: true,
                selectedSub: null,
            }
            },
        methods:{
            getRight(type){
                return getRight(type)
            },
            mounted() {

                this.selectedSub = sessionStorage.getItem("selectedSub")

            },
            gotTo(route){
                this.$router.push(route);
                sessionStorage.setItem("selectedSub",route)
                this.selectedSub = route
            }
        }
    }
</script>

<style scoped>

</style>