<template>
  <div class="row">
    <div class="col-12 active">
      <div class="card active">
        <div class="card-body mini-nav bg-primary pt-2 pb-3 active ">
          <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
            <button
                class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary"
                type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                aria-label="Toggle navigation">
              <span class="mdi mdi-menu"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-between active"
                 id="navbarNavAltMarkup">
              <div class="navbar-nav">

                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'fire-dashboard'}" active-class="mm-active"
                             class="nav-item nav-link">Quick Stats
                </router-link>
                <!--                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'cert-biz-stats'}" active-class="mm-active" class="nav-item nav-link">Fire Permit Register</router-link>-->
                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'cert-fire-pending'}" active-class="mm-active"
                             class="nav-item nav-link">Pending Invoices
                </router-link>
                <!--                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'cert-biz-stats'}" active-class="mm-active" class="nav-item nav-link">Potential Businesses</router-link>-->
                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'pending-approval-fire'}" active-class="mm-active"
                             class="nav-item nav-link">Pending Approval
                </router-link>
                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'active-fireCert'}" active-class="mm-active"
                             class="nav-item nav-link">Active Certificates
                </router-link>
                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'expired-fireCert'}" active-class="mm-active"
                             class="nav-item nav-link">Expired Certificates
                </router-link>
                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'fire-paid-bills'}" active-class="mm-active"
                             class="nav-item nav-link">Fire Paid Bills
                </router-link>
                <!--
                                <router-link v-if="getRight('DASHBOARD')" :to="{name: 'cert-biz-stats'}" active-class="mm-active" class="nav-item nav-link">Map View</router-link>
                -->

              </div>

              <button class="btn btn-info waves-effect waves-light my-2 my-sm-0 text-uppercase"
                      data-bs-toggle="modal"
                      data-bs-target=".new-fire-invoice">Invoice A Business
              </button>

            </div>
          </nav>
        </div>
      </div>

    </div>
  </div>

  <!-- creating a fire invoice -->
  <div class="modal fade new-fire-invoice" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog "
       aria-labelledby="staticBackdropLabel " aria-hidden="true ">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header liq-header">
          <h5 class="modal-title text-uppercase" id="myLargeModalLabel">New Fire inspection Permit application</h5>

          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pb-4 searching-modal-body">
          <div v-if="businessSelector ==true " class="row business-selector">
            <div class="col-md-12 mb-3">
              <label for="">Search or Input establishment details</label>
              <select class="form-select fire-search-method" v-model="selectedOption">
                <option value="">Select Search Method</option>
                <option value="searchBusiness">Search from Existing Registered Businesses</option>
                <option value="paidInvoice">Use Paid Invoice</option>
              </select>

            </div>
          </div>

          <div class="row">

            <!-- existing business -->
            <div v-if="selectedOption ==='searchBusiness'" class="col-12 business-id-search">
              <label for="">Search For Business To continue</label>
              <div class="input-group mb-3">

                <input v-model="businessID" type="text" class="form-control" placeholder="Enter Business ID">
                <button @click="getBusiness" type="button" class="btn btn-primary waves-effect waves-light search-bz-btn">
                  <i class="bx bx-search-alt-2 font-size-16 align-middle me-2"></i> Search
                </button>
              </div>

              <!-- business serach results start here display only  when there are results to be shown -->
              <div class="row pt-3">

                <!-- use this loader when serching for the business -->
                <div class="searching-biz-loader d-none">
                  <div class="loader-modal p-5">
                    <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                      <div class="spinner-border text-dark m-1" role="status">
                        <span class="sr-only fw-bold">Searching</span>
                      </div>
                      <p class="pt-4 fw-strong text-uppercase fw-bold">Please wait patiently while our system looks <br>
                        for the business.</p>
                    </div>
                  </div>
                </div>

                <div v-if="businessDetailsResult" class="col-12 biz-search-results">
                  <table class="table table-sm text-uppercase table-striped">
                    <thead class="table-dark">
                    <th colspan="2">Search Results for Business with <span class="text-warning">Business ID 1234</span>
                    </th>
                    </thead>
                    <tbody>
                    <tr>
                      <th>
                        Name of Establishment
                      </th>
                      <td>
                        {{businessDetails.buildingName}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Sub County
                      </th>
                      <td>
                        {{businessDetails.subCountyName}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Ward
                      </th>
                      <td>
                       {{businessDetails.wardName}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Physical Address
                      </th>
                      <td>
                       {{businessDetails.physicalAddress}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Building/Name of Premises
                      </th>
                      <td>
                        {{businessDetails.buildingName}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Postal Address
                      </th>
                      <td>
                       {{businessDetails.postalAddress}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Applicant's Name
                      </th>
                      <td>
                       {{businessDetails.fullNames}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Phone Number
                      </th>
                      <td>
                        {{businessDetails.ownerPhone}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Email Address
                      </th>
                      <td>
                        {{businessDetails.ownerEmail}}
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <div class="row">
                    <div class="col-12">
                      <button @click="assessmentInvoice = true , businessDetailsResult = false, businessSelector = false, selectedOption =''" type="button"
                              class="btn btn-success waves-effect waves-light w-100 go-to-assesment d-flex justify-content-center align-items-center">
                        Next <i
                          class="dripicons-arrow-thin-right font-size-16 align-middle me-2 d-flex align-items-center mx-2"></i>
                      </button>

                    </div>

                  </div>
                </div>

                <!-- use this for a search that has no results -->
                <div v-if="message !==null" class="col-12 no-results">
                  <div
                      class="p-4 border-radius border-solid border-primary border-2 d-flex flex-column align-items-center justify-content-center">
                    <div class="img mb-4 pt-3">
                      <img src="assets/images/no-result-found-icon.svg" style="height: 65px;" class="img" alt="">
                    </div>
                    <div class="text-center">
                      <h4 class="card-title text-uppercase text-dark">No results Found</h4>
                      <p>
                        The business ID you entered does not correspond to any registered business. Please try your
                        search again <br> to find the desired results.</p>


                    </div>
                  </div>
                </div>

              </div>
              <!-- end of business serach results -->
            </div>


            <!-- fire invoicing -->
            <div v-if="fireInvoiceCreator" class="col-12 fire-invoice-creator pt-4">
              <div class="row">
                <div class="col-6">
                  <label for="">Permit Category</label>


                        <select @change="selectedIncomeTypeMain($event)" title="&#45;&#45; Parking Duration &#45;&#45;" class="form-control show-tick" data-live-search="true" >
                          <option value=""  selected>Select Option</option>
                          <option v-for="(item, index) in incomeTypesMain" :value="JSON.stringify(item)" :key="index">
                            {{ item.incomeTypeDescription}}
                          </option>
                        </select>


                </div>

                <div class="col-6">
                  <label for="">Permit Type</label>

                  <select @change="selectedFeeAndChargeMain($event)" title="&#45;&#45; Select Car Type &#45;&#45;" class="form-control show-tick" data-live-search="true">
                    <option v-for="(item, index) in feesAndChargesMain" :value="JSON.stringify(item)" :key="index">
                      {{ item.feeDescription }} KES {{item.unitFeeAmount}}
                    </option>
                  </select>

                </div>

                <div class="col-12">
                  <div class="col-12">
                    <div class="py-2 mt-3">
                      <h5 for="">Other Possible Fire and Safety fees</h5>
                      <p>select any other fee's/levies that are applicable to the business in relation to fire. remember to
                        indicate quantities where applicable</p>
                    </div>
                    <div class="py-1 mt-3">
                      <select @change="selectedIncomeType($event)"  title="-- Income Type --" class="form-control show-tick" data-live-search="true" v-model="incomeType">
                        <option v-for="(item, index) in incomeTypes" :value="JSON.stringify(item)" :key="index">
                          {{ item.incomeTypeDescription }}
                        </option>
                      </select>
                    </div>
                  </div>


                  <div class="col-12">
                    <div class="">
                      <table class="table table-editable-1 align-middle table-edits">
                        <thead class="table-light">
                        <tr class="text-uppercase table-dark">
                          <th>#</th>
                          <th class="">Quantity</th>
                          <th class="">feeDescription</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr  v-for="(item,index) in rowItemsFees" :key="index" >
                          <td style="width: 80px " class="categoryIndex ">{{index+1}}</td>

                          <td class="">
                            <input v-model="item.quantity" type="text" class="form-control " placeholder="Quantity(n)" spellcheck="false" data-ms-editor="true">
                          </td>

                          <td>
                            <select v-model="item.description" class="form-control bg-white" title="Select Traffic offence" data-live-search="true" >
                              <option v-for="(item2, index) in feesAndCharges" :value="item2.feeId+':'+ item2.feeDescription+':'+ item2.unitFeeAmount+':'+item2.incomeTypeId" :key="index">
                                {{ item2.feeDescription }} KES {{totalAmount(item2.unitFeeAmount,item.quantity)}}
                              </option>
                            </select>

                          </td>

                          <td @click="removeRowItem(index)" class="text-right cell-change d-flex align-items-center justify-content-end">
                            <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 " title="Cancel "><i class="bx bx-x "></i></a>
                          </td>
                        </tr>

                        </tbody>
                        <tfoot>
                        <tr>
                          <td @click="addRowItem()" colspan="7" class="bg-light add-field-1 cursor-pointer">
                            <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Additional Measures</span></span>
                          </td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div>
                </div>


                <div class="col-12">
                  <button @click="addToBill" target="_blank" type="button"
                          class="btn btn-success waves-effect waves-light w-100 generate-invoice-btn d-flex justify-content-center align-items-center">
                    Next <i
                      class="dripicons-arrow-thin-right font-size-16 align-middle me-2 d-flex align-items-center mx-2"></i>
                  </button>
                </div>
              </div>
            </div>

            <!-- Form for invoice search -->
            <div v-if="selectedOption ==='paidInvoice'"  class="col-12 inv-results">
              <div class="row">
                <div class="col-12">
                  <div class="mb-3 mt-5">
                    <label for="formrow-firstname-input" class="form-label">Search for Invoice</label>
                    <input type="text" class="form-control" id="formrow-firstname-input"
                           placeholder="Enter Invoivece Number" spellcheck="false" data-ms-editor="true">
                  </div>
                </div>

                <div class="col-12">
                  <button class="btn btn-primary w-100 text-center text-uppercase inv-serach-btn mb-3">
                    Search
                  </button>
                </div>
              </div>
            </div>

            <!-- Results for business search with invoice -->
            <div class="col-12 inv-details-cont">

              <!-- invoice loader -->
              <div v-if="loading" class="row inv-loader">
                <div class="">
                  <div class="loader-modal p-5">
                    <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                      <div class="spinner-border text-dark m-1" role="status">
                        <span class="sr-only fw-bold">Searching</span>
                      </div>
                      <p class="pt-4 fw-strong text-uppercase fw-bold">Please wait patiently while our system looks <br>
                        for Invoice.</p>
                    </div>
                  </div>
                </div>
              </div>


              <!-- the invoice and business details -->

              <!-- show this for an already paid invoice -->
              <div class="row invoice-details d-none">
                <div class="col-12">
                  <div>
                    <table class="table table-sm text-uppercase table-striped">
                      <thead class="">
                      <tr class="table-dark">
                        <th colspan="2">Invoice Details <span class="text-warning">Invoice Number ID 1234</span></th>
                      </tr>
                      <tr class="table-light">
                        <th class="text-uppercase">Invoice Item</th>
                        <th class="text-uppercase text-right">Amount</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <th>
                          SBP Registration fee
                        </th>
                        <td class="text-right">
                          KES 3000
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Fire inspection Fees
                        </th>
                        <td class="text-right">
                          KES 3000
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Small enterprice
                        </th>
                        <td class="text-right">
                          KES 3000
                        </td>
                      </tr>


                      </tbody>
                      <tfoot>
                      <tr class="table-success">
                        <th>Sub Total</th>
                        <th class="text-right">KES 5,500</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="col-12">
                  <div class="alert alert-success" role="alert">
                    <h6 class="fw-bold text-uppercase">The invoice has been paid For</h6>
                  </div>
                </div>

                <div class="col-12 mt-4">
                  <div>
                    <table class="table table-sm text-uppercase table-striped">
                      <thead class="table-dark">
                      <tr>
                        <th colspan="2">Search Results for Business with <span
                            class="text-warning">Business ID 1234</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <th>
                          Name of Establishment
                        </th>
                        <td>
                          The big Palace Bar and Grill
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Sub County
                        </th>
                        <td>
                          Rachuonyo South Subcounty
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Ward
                        </th>
                        <td>
                          Name of the Ward
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Physical Address
                        </th>
                        <td>
                          Next to the Big Super Market
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Building/Name of Premiscess
                        </th>
                        <td>
                          The big Tower
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Postal Address
                        </th>
                        <td>
                          123-100009 Eldoret
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Applicant's Name
                        </th>
                        <td>
                          John Doe
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Phone Number
                        </th>
                        <td>
                          0701234567
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Email Address
                        </th>
                        <td>
                          email@mail.com
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-12">
                  <button class="btn btn-primary w-100 btn-inv-details ">Next</button>
                </div>
              </div>


              <!-- application assesment form for invoice searched businesses-->
              <div v-if="assessmentInvoice" class="row assessment-invoice">

                <div class="col-12">
                  <h6>Business Name: {{businessDetails.buildingName}}</h6>
                </div>
                <div class="col-12">
                  <div class="alert alert-primary" role="alert">
                    <h6 class="mb-0 pb-0">Fire Safety Risk Assessment</h6>
                  </div>
                </div>

                <div class="col-12">
                  <h5 for="">Safety Measures in Place</h5>
                  <p>Select from below any Safety measure already deployed by the Business. You can include additional
                    measures </p>

                </div>
                <div class="col-12 mb-4">
                  <div class="">
                    <table class="table table-editable-1 align-middle table-edits">
                      <thead class="table-light">
                      <tr class="text-uppercase table-dark">
                        <th>#</th>
                        <th class="" colspan="2">Safety Measure</th>
                      </tr>
                      </thead>
                      <tbody>

                      <tr v-for="(item, index) in fireSafety" :key="index">
                        <td style="width: 20px">{{ index + 1 }}.</td>
                        <td class="pl-0 px-0" colspan="2">
                          <div class="d-flex">
                            <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                              <input
                                  class="form-check-input w-auto h-auto me-3"
                                  type="checkbox"
                                  :id="'measure' + index"
                                  v-model="item.selected"
                              >
                              <label class="form-check-label" :for="'measure' + index">
                                {{ item.category }}
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>


                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="7" class="bg-light add-field-1 cursor-pointer d-none">
                          <span class="d-flex align-items-center "><i
                              class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span
                              class="pl-5 ">Additional Measures</span></span>
                        </td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="col-12">
                  <h5 for="">Fire Safetey Recommendations</h5>
                  <p>Enter any recommendations that the applicant should fullfill below.</p>

                </div>


                <div class="col-12">
                  <div class="">
                    <table class="table table-editable-3 align-middle table-edits">
                      <thead class="table-light">
                      <tr class="text-uppercase table-dark">
                        <th>#</th>
                        <th colspan="2">Recommendations</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(recommendation, index) in recommendations" :key="index" data-id="1">
                        <td style="width: 20px">{{ index + 1 }}.</td>
                        <td colspan="">
                          <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Recommendation"
                              spellcheck="false"
                              v-model="recommendation.text"
                          >
                        </td>
                        <td>
                          <button
                              @click="removeRecommendation(index)"
                              class="btn btn-danger"
                              type="button"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="3" class="bg-light add-field-3 cursor-pointer">
            <span class="d-flex align-items-center" @click="addRecommendation">
              <i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21"></i>
              <span class="pl-5">Additional Measures</span>
            </span>
                        </td>
                      </tr>
                      </tfoot>
                    </table>

                  </div>
                </div>

                <div class="col-12">
                  <button @click="fireInvoiceCreator = true , assessmentInvoice = false" target="_blank" type="button"
                          class="btn btn-success waves-effect waves-light w-100 d-flex justify-content-center align-items-center btn-assesment-nv">
                    Next <i
                      class="dripicons-arrow-thin-right font-size-16 align-middle me-2 d-flex align-items-center mx-2"></i>
                  </button>
                </div>
              </div>

              <!-- Sending OTP to the customer during invoicing -->
              <div class="row otp-invoice d-none">
                <div class="col-12">

                  <div class="col-12">
                    <h5>Certificate Type and Verification</h5>
                  </div>

                  <div class="row">
                    <div class="col-12 mb-3">
                      <label for="">Certificate Category</label>
                      <select class="form-select liq-search-method">
                        <option value="">Select Certificate Type</option>
                        <option value="">Certififcta Category 1</option>
                        <option value="">Certififcta Category 2</option>
                        <option value="">Certififcta Category 3</option>
                        <option value="">Certififcta Category 4</option>
                      </select>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-12">
                      <label for="" class="mb-0">OTP</label>
                      <p>An OTP has been sent to the phone number ending in <strong class="text-black">***123</strong>.
                        Please share the OTP to complete the process.</p>
                      <input type="text" class="form-control" placeholder="Enter OTP">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <button class="btn btn-primary w-100 text-uppercase mt-4 send-otp-inv">Submit</button>
                </div>
              </div>

              <!-- succesfull creation of the certificates -->
              <div class="row invoice-success-certificate d-none">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="col-8">
                    <div class="text-center d-flex justify-content-center align-items-center flex-column">
                      <div class="w-100 my-4 d-flex align-items-center justify-content-center">
                        <div class="avatar-md">
                          <div class="avatar-title bg-light rounded-circle text-success font-size-28">
                            <i class="bx bx-check-double"></i>
                          </div>
                        </div>
                      </div>
                      <h4>Succesfully Created</h4>
                      <p>The Permit has been succesfully created. Click the button bellow to print the respective
                        certificate. Otherwise you can ignore.</p>
                      <a href="PrintDocs/Fire Permit/homabya trade permit.html" target="new"
                         class="btn btn-success mt-4 text-uppercase mb-4">Print Certificate</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- matters payment and billing starts here -->

        <div v-if="generatedInvoice" class="modal-body receipt-billing-modal-body">
          <div v-if="loading" class="billing-receipting-loader">
            <div class="loader-modal p-5">
              <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                <div class="spinner-border text-dark m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <p class="pt-4 fw-strong text-uppercase">Generating Invoice Please Wait</p>
              </div>
            </div>
          </div>


          <div v-if="message !=null" class="alert alert-danger" role="alert">
            {{message}}
          </div>

          <!-- generated invoice results -->
          <div v-if="invoiceDetails" class="row generated-invoice">
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <address>
                    <strong class="text-capitalize">Invoice Prepared for:</strong>
                    <br>Establishment Name: <span class="fw-semibold text-black">{{businessDetails.businessName}}</span><br>
                    Phone: <span class="fw-semibold text-black">{{businessDetails.ownerPhone}}</span>
                    <br>
                    <br> Prepared on {{billDetails.dateCreated}} By {{businessDetails.createdBy}}
                  </address>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-nowrap vertical-align-middle table-bordered ">
                  <thead class="table-dark">
                  <tr class="vertical-align-middle">
                    <th style="width: 70px;">1.</th>
                    <th class="text-uppercase" colspan="3">
                      Invoice No:. <span class="fw-bold text-warning">{{billDetails.billNo}}</span>
                    </th>

                  </tr>
                  </thead>
                  <thead class="bg-light">
                  <tr>
                    <th style="width: 70px;">No.</th>
                    <th colspan="2">Item Description (X QTY)</th>
                    <th class="text-end">Amount</th>

                  </tr>
                  </thead>
                  <tfoot class="bg-light text-uppercase table-bordered">
                  <tr>
                    <td></td>

                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      Invoice Sub Total
                    </td>
                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      KES {{billDetails.detailAmount}}
                    </td>

                  </tr>
                  <tr>
                    <td></td>

                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      Amount Paid
                    </td>
                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      KES {{billDetails.receiptAmount}}
                    </td>

                  </tr>

                  <tr class="table-success">
                    <td></td>

                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      Total Payable
                    </td>
                    <td class="border-0 text-end text-right">
                      <h5 class="m-0 text-uppercase fw-bold">KES {{billDetails.billBalance}}</h5>
                    </td>

                  </tr>
                  </tfoot>
                  <tbody class="text-uppercase">
                  <tr :key="index" :value="item" v-for="(item, index) in billInfo">
                    <td>{{index+1}}</td>
                    <td colspan="2">
                      <p class="mb-0 p-0 m-0"> {{item.feeDescription}} (X{{item.quantity}})</p>
                    </td>
                    <td class="text-end text-right">KES {{item.billTotal}}</td>

                  </tr>


                  </tbody>
                </table>
              </div>

              <div class="d-flex text-right justify-content-end align-self-end">
                <button data-bs-dismiss="modal" type="button" data-toggle="modal" data-target="#payment-modal"
                        class="btn btn-primary waves-effect btn-label waves-light fw-bold text-uppercase me-3">
                  <i class="mdi mdi-send label-icon"></i> Receipt Invoice
                </button>

                <a @click="printBill" href="javascript: void(0);" target="new"
                   class="btn btn-success waves-effect waves-light fw-semibold text-uppercase" type="button"><i
                    class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Invoice</a>
              </div>
            </div>
          </div>
          <!-- end of generated receipt results -->
        </div>


      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- end of fire invoice -->

  <!-- payment modal -->
  <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header d-none">
          <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


          <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
          </button>

        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-between align-items-center">
            <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


            <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
            </button>
          </div>

          <div class="payment-panel-parent">

            <div v-if="paymentPanel" class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
              <div class="success-image mb-4 pb-4 pt-4">
                <img src="assets/images/bills.svg" height="200" alt="">
              </div>
              <h4 class="text-black fw-bold">{{message}}</h4>
              <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
              <div class="d-flex w-100 pb-2">
                <button @click="showPaymentMethod()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                  <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                  Receive Payment
                </button>
                <a @click="printBill()" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
              </div>
            </div>

            <div v-if="paymentMethod" class="send-method payment-panel ">
              <label for="" class="mb-0 pb-0">Payment Method</label>
              <p><small class="text-muted">How would you like to send this money?</small></p>

              <div>
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div class="flex-shrink-0 me-3">
                          <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                        </div>
                        <div class="d-flex flex-column">
                          <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                          <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                        </div>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <div>
                          <p>
                            A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to the MPESA number you enter below.
                          </p>
                        </div>
                        <div class="form-group">
                          <label for="">MPESA Number</label>
                          <div class="form-floating mb-3">
                            <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" placeholder="Enter Name">

                            <label for="floatingnameInput">Phone No.</label>
                          </div>

                          <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                            <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                            Send Payment Request
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div class="flex-shrink-0 me-3">
                          <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                        </div>
                        <div class="d-flex flex-column">
                          <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                          <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>


                        </div>
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <div class="form-group">
                          <label for="">Enter The Bank's Receipt Number</label>
                          <div class="form-floating mb-3">
                            <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                            <label for="floatingnameInput">Bank Ref No.</label>
                          </div>

                          <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div v-if="transactionSummary" class="transaction-summary payment-panel">
              <label for="">Transaction Breakdown</label>
              <div class="border p-4 rounded ">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="text-muted ">
                      BillO NO.
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end">
                    <div class=" text-right text-black">
                      {{billDetails.billNo}}
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="text-muted mt-2">
                      Bill For
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end">
                    <div class=" text-right text-black">
                      {{billDetails.incomeTypeDescription}}
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="text-muted mt-2">
                      Payment Method
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end">
                    <div class=" text-right text-black">
                      {{paymentData.paymentMode}}
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="text-muted mt-2">
                      Transaction Amount
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end">
                    <div class=" text-right text-black">
                      KES {{billDetails.billBalance}}
                    </div>
                  </div>


                  <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                  <div class="col-lg-6 text-uppercase">
                    <div class="text-muted mt-2 font-21">
                      Total
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end text-uppercase">
                    <div class=" text-right text-black font-21 fw-bold">
                      KES {{billDetails.billBalance}}
                    </div>
                  </div>
                </div>

                <div class="mpesa-confirmation ">
                  <div v-if="paymentData.paymentMode==='MPESA'">
                    <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ phoneNumber }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                      <br>
                      <br>
                      Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                  </div>

                  <div>{{message}}  </div>
                  <br>
                  <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="stk-timer-container d-none justify-content-center align-items-center">
                        <span class="mdi mdi-timer-outline font-16px"></span>
                        <span class="stk-timer px-2"></span>
                      </div>
                      <div class="justify-content-center align-items-center d-flex">
                        <span class="px-2">Send Request</span>
                        <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                      </div>
                    </div>
                  </button>
                </div>


              </div>
            </div>

            <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
              <div class="success-image mb-4">
                <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
              </div>
              <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
              <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
              <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                Print Receipt
              </button>
            </div>



          </div>
        </div>
        <div class="modal-footer d-flex d-none bill-modal-footer">
          <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i>Previouse</button>
          <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
          <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
            <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end of payment modal -->

</template>

<script>
/* eslint-disable */
import {biller, execute, fire, getRight, parking, rent, trade} from "@/api";
import vueHelmet from "vue-helmet/src/VueHelmet";

export default {
  name: "ToolBarFire",
  data() {
    return {
      generatedInvoice: false,
      invoiceDetails: false,

      loading: false,
      message: null,
      dashboard: true,
      validation: true,
      inspection: true,
      approval: true,
      declined: true,
      approved: true,
      paidSbp: true,
      unpaidSbp: true,
      unpaidBills: true,
      sbpPaidBills: true,
      sbpMap: true,
      selectedSub: null,
      selectedOption: null,
      businessID: "",
      businessSelector: true,
      businessDetailsResult: false,
      assessmentInvoice: false,
      fireInvoiceCreator: false,
      businessDetails: {
        id: "",
        businessID: "",
        businessName: "",
        subCountyID: "",
        subCountyName: "",
        wardID: "",
        wardName: "",
        plotNumber: "",
        physicalAddress: "",
        buildingName: "",
        buildingOccupancy: "",
        floorNo: "",
        roomNo: "",
        premiseSize: "",
        numberOfEmployees: "",
        tonnage: "",
        businessDes: "",
        businessCategory: "",
        businessSubCategory: "",
        incomeTypeID: "",
        feeID: "",
        businessEmail: "",
        postalAddress: "",
        postalCode: "",
        businessPhone: "",
        contactPersonNames: "",
        contactPersonIDNo: "",
        businessRole: "",
        contactPersonPhone: "",
        contactPersonEmail: "",
        fullNames: "",
        ownerID: "",
        ownerPhone: "",
        ownerEmail: "",
        kraPin: "",
        createdBy: "",
        createdByIDNo: "",
        dateCreated: "",
        lat: 0.0,
        lng: 0.0
      },
      fireSafety: [],
      selectedItems: [],
      recommendations: [],
      incomeType: {
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix: null,
        fundDescription: null,
        status: null
      },
      description: '',
      amount: 0,
      unitFeeAmount: 0,
      feeID: '',
      quantity: 1,
      incomeTypesMain: [],
      incomeTypes: [],
      feesAndChargesMain:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        quantity: 1
      }],
      feesAndCharges:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        quantity: 1
      }],
      rowItemsFees:[{
        customer:null,
        feeId: null,
        description: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        quantity: 1
      }],
      rowItemsFeesMain:[{
        customer:null,
        feeId: null,
        description: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        quantity: 1
      }],
      feesAndChargeItems: [],
      billItem: [],

      transactionCompleted: false,
      transactionSummary: false,
      paymentPanel: true,
      paymentMethod: false,
      billDetails:{
        id:'',
        billNo:'',
        incomeTypeID:'',
        incomeTypeDescription:'',
        costCenterNo:'',
        accountNo:'',
        feeID:'',
        detailAmount:'',
        receiptAmount:'',
        billBalance:'',
        wardID:'',
        subCountyID:'',
        status:'',
        dateCreated:''
      },
      billInfo:[],

      paymentData: {
        bankReceiptNo: null,
        accNo: null,
        payBillNo: null,
        amount: null,
        phoneNumber: null,
        success: false,
        paymentMode:null,
        sessionNames:null,
        sessionIdNo:null,
        sessionPhoneNumber:null
      },
      timer: null,
      categoriesSaftyString:'',
      recommendationsString:'',

      billGenerated: {
        billNo: null,
        payBillNo: null,
        amount: null,
        success: true
      },
      confirmCash: false,
      phoneNumber:'',
      customerName:'',
      email:'',

    }
  },
  mounted() {
    this.selectedSub = sessionStorage.getItem("selectedSub");
    this.getFireMeasures();
    this.getIncomeTypes();
    this.rowItemsFees.splice(0)
    this.getIncomeTypesMain()
  },
  watch:{

    rowItemsFees: {
      handler(newValue) {
        // Iterate over each item in rowItemsFees
        newValue.forEach(item => {
          if (item.description && item.description.trim() !== '') {
            // Split the description
            const parts = item.description.split(':');

            // Check if parts have the expected length
            if (parts.length >= 4) {
              const feeId = parts[0];
              const feeDescription = parts[1];
              const unitFeeAmount = parts[2];
              const incomeTypeId = parts[3];

              // Update the item with the extracted values
              item.feeId = feeId;
              item.feeDescription = feeDescription;
              item.unitFeeAmount = unitFeeAmount;
              item.incomeTypeId = incomeTypeId;
            }
          }
        });
      },
      deep: true // Watch deeply to detect changes within array items
    },

  },
  methods: {
    getRight(type) {
      return getRight(type);
    },
    goTo(route) {
      this.$router.push(route);
      sessionStorage.setItem("selectedSub", route);
      this.selectedSub = route;
    },
    getBusiness() {
      this.loading = true;
      this.message = null;
      this.businessDetailsResult = false;
      const data = new FormData();
      data.append("function", "getBusiness");
      data.append("businessID", this.businessID);
      execute(data, trade)
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.businessDetailsResult = true;
              this.businessDetails = res.data.data.business;
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },
    getFireMeasures() {
      const data = new FormData();
      data.append("function", "getFireMeasures");
      execute(data, fire)
          .then((res) => {
            if (res.data.success) {
              this.fireSafety = res.data.data.fireSafety.map(item => ({
                ...item,
                selected: false
              }));
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },
    updateSelectedItems() {
      this.selectedItems = this.fireSafety.filter(item => item.selected);
      //{ "id": "2", "category": "They have A Rear escape route", "selected": true }, { "id": "3", "category": "General House Keeping is good", "selected": true }, { "id": "4", "category": "Adequate Fire Signs", "selected": true }, { "id": "5", "category": "Electrical Installation Done To Recommended\n", "selected": true } ]
      this.categoriesSaftyString = this.selectedItems
          .map(item => item.category) // Map to an array of category values
          .join(', '); // Join the array into a comma-separated string
    },
    addRecommendation() {
      this.recommendations.push({ text: '' });
    },
    removeRecommendation(index) {
      this.recommendations.splice(index, 1);
    },
    submitRecommendations() {

      this.recommendationsString = this.recommendations
          .map(r => r.text) // Get the text property from each recommendation
          .join(', '); // Join all texts into a single comma-separated string

    },

    getIncomeTypesMain() {
      const data = new FormData();
      data.append("function", "getIncomeTypes");
      data.append("incomeTypePrefix", "FIREINSPECTION");
      data.append("keyword", "Certificate");
      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.incomeTypesMain = res.data.data.incomeTypes;
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },

    selectedIncomeTypeMain(event){
      const value = event.target.value
      const result = JSON.parse(value);
      this.getFeesAndChargesMain(result.incomeTypeId)
    },

    selectedFeeAndChargeMain(event) {

      this.updateSelectedItems()
      this.submitRecommendations()

      //[General House Keeping is good, Electrical Installation Done To Recommended \n, They have A Rear escape route, Adequate Fire Signs, Provide red sig, Recommendations: adding red door]
      const selectedItem = JSON.parse(event.target.value);
      this.rowItemsFeesMain[0] = selectedItem;
      this.rowItemsFeesMain[0].customer = "Business ID:"+this.businessDetails.businessID,
      this.rowItemsFeesMain[0].description = `[${this.categoriesSaftyString} ,Recommendations : ${this.recommendationsString}]`
    },

    getFeesAndChargesMain(incomeTypeId) {
      this.feesAndCharges = []; // Clear the array
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId", incomeTypeId);
      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.feesAndChargesMain = res.data.data.feesAndCharges;
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },


    //Others
    getIncomeTypes() {
      const data = new FormData();
      data.append("function", "getIncomeTypes");
      data.append("incomeTypePrefix", "FIREINSPECTION");
      data.append("keyword", "Other");
      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.incomeTypes = res.data.data.incomeTypes;
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },
    getFeesAndCharges(incomeTypeId) {
      this.feesAndCharges = []; // Clear the array
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId", incomeTypeId);
      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.feesAndCharges = res.data.data.feesAndCharges;
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },
    selectedIncomeType(event){
      const value = event.target.value
      const result = JSON.parse(value);
      this.getFeesAndCharges(result.incomeTypeId)
    },
    selectedFeeAndCharge(event, index) {
      const selectedItem = JSON.parse(event.target.value);
      this.feesAndChargeItems[index].feeAndCharge = selectedItem;
      this.feesAndChargeItems[index].unitFeeAmount = selectedItem.unitFeeAmount;
      this.amount = this.quantity * this.feesAndChargeItems[index].unitFeeAmount;
    },

    //value="item2.feeId+':'+ item2.feeDescription+':'+ item2.unitFeeAmount+':'+item2.incomeTypeId
    addRowItem(quantity,description){
      this.rowItemsFees.push({
        quantity:quantity,
        description:description
      })
    },
    removeRowItem(position){
      this.rowItemsFees.splice(position,1)
    },
    totalAmount(amount, quantity) {
      // Convert amount and months to numbers before multiplying
      amount = Number(amount);
      quantity = Number(quantity);
      // Return the result of multiplication
      return amount * quantity;
    },

    addToBill(){

      for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer,quantity,description} of this.rowItemsFeesMain) {

        this.billItem.push({
          fiscalYear: this.fiscalYear,
          typeDescription,
          feeDescription,
          quantity,
          unitFeeAmount: unitFeeAmount,
          accountNo,
          costCenterNo,
          incomeTypeId,
          revenueStreamItem: "",
          feeId,
          amount:  unitFeeAmount,
          customer: customer,
          zone: "",
          subCountyID:this.businessDetails.subCountyID,
          subCountyName:this.businessDetails.subCountyName,
          wardID: this.businessDetails.wardID,
          wardName: this.businessDetails.wardName,
          idNo: sessionStorage.getItem("sessionIdNo"),
          phoneNumber: sessionStorage.getItem("sessionPhoneNumber"),
          names: sessionStorage.getItem("sessionNames"),
          customerPhoneNumber: this.businessDetails.ownerPhone,
          description
        });

      }

      for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer,quantity} of this.rowItemsFees) {

        this.billItem.push({
          fiscalYear: this.fiscalYear,
          typeDescription,
          feeDescription,
          quantity,
          unitFeeAmount: unitFeeAmount,
          accountNo,
          costCenterNo,
          incomeTypeId,
          revenueStreamItem: "",
          feeId,
          amount:  unitFeeAmount * quantity,
          customer: customer,
          zone: "",
          subCountyID:this.businessDetails.subCountyID,
          subCountyName:this.businessDetails.subCountyName,
          wardID: this.businessDetails.wardID,
          wardName: this.businessDetails.wardName,
          idNo: sessionStorage.getItem("sessionIdNo"),
          phoneNumber: sessionStorage.getItem("sessionPhoneNumber"),
          names: sessionStorage.getItem("sessionNames"),
          customerPhoneNumber: this.businessDetails.ownerPhone,
          description: "",
        });

      }


      this.generateBill()

    },
    generateBill(){
      this.fireInvoiceCreator = false
      this.generatedInvoice = true;
      this.loading = true
      const data = new FormData();
      data.append("function", "generateBill3");
      data.append("billItem", JSON.stringify(this.billItem));
      execute(data,biller)
          .then((res) =>{
            this.loading = false
            if (res.data.success) {

              this.invoiceDetails = true

              this.billDetails = res.data.data.bill.billDetails
              this.billInfo = res.data.data.bill.billInfo
              this.paymentData.amount = res.data.data.bill.billDetails.billBalance
              this.paymentData.accNo = res.data.data.bill.billDetails.billNo
              this.paymentData.payBillNo = res.data.data.billGenerated.payBillNo
              this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")
              this.paymentData.sessionNames = sessionStorage.getItem("sessionNames")
              this.paymentData.sessionIdNo = sessionStorage.getItem("sessionIdNo")

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    printBill() {
      localStorage['params'] = JSON.stringify({
        billNo: this.billDetails.billNo
      })
      const routeData = this.$router.resolve({name: 'bill'});
      window.open(routeData.href, '_blank');
    },
    printReceipt() {
      if (this.receiptDetails.receiptNo == null) {
        alert("Try again later");
      } else {
        //  this.$refs.closeReceived.click();
        localStorage['params'] = JSON.stringify({
          receiptNo: this.receiptDetails.receiptNo
        })
        const routeData = this.$router.resolve({name: 'receipt'});
        window.open(routeData.href, '_blank');
      }
    },
    showPaymentMethod(){
      this.paymentMethod = true;
      this.paymentPanel = false;
      this.transactionSummary = false;
      this.transactionCompleted = false
    },
    showTransactionSummary(paymentMode){

      this.paymentData.paymentMode = paymentMode

      if(paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }
      if(paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }

      }

      this.paymentMethod = false;
      this.paymentPanel = false;
      this.transactionSummary = true;
      this.transactionCompleted = false
    },
    makePayment() {
      if(this.paymentData.paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }
      if(this.paymentData.paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }
      this.message = "Payment Request sent";
      this.paymentData.phoneNumber = this.phoneNumber

      const data = new FormData();
      data.append("function", "makePayment");
      data.append("paymentData", JSON.stringify(this.paymentData));

      let stream = null;
      if (this.paymentData.accNo.startsWith("SBP")) {
        stream = trade;
      }
      if (this.paymentData.accNo.startsWith("PKN")) {
        stream = parking;
      }

      if (this.paymentData.accNo.startsWith("RNT")) {
        stream = rent;
      }

      if (this.paymentData.accNo.startsWith("BLL")) {
        stream = biller;
      }

      execute(data, stream)
          .then((res) => {
            if (res.data.success) {
              this.paymentMethod = false;
              this.paymentPanel = false;
              //start checking payment
              this.timer = setInterval(() => {

                this.checkPayment();
              }, 3000);
            } else {
              this.rate = null;
              alert(res.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    checkPayment() {
      this.randomNumber = Math.floor(Math.random() * 3) + 1
      if(this.randomNumber === 1){
        this.message = "Waiting for payment.";
      }
      if(this.randomNumber === 2){
        this.message = "Waiting for payment..";
      }
      if(this.randomNumber ===3){
        this.message = "Waiting for payment...";
      }
      const data = new FormData();
      data.append("function", "checkPayment");
      data.append("accountRef", this.paymentData.accNo);

      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.message = null;
              clearInterval(this.timer);
              this.receipt = true;
              this.paymentData.success = false;
              this.transaction = res.data.data.transaction;
              this.receiptDetails = res.data.data.receipt.receiptDetails
              //Close the modal
              this.transactionSummary = false
              this.transactionCompleted = true
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    }


  }
}
</script>

<style scoped>

</style>