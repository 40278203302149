<template>

<head>

    <meta charset="utf-8" />
    <title>Hotline Transporters Sacco | All invoices</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body @contextmenu.prevent data-sidebar="dark">



    <!-- Begin page -->
    <div id="layout-wrapper">



        <NavigationBar/>



        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Revenue Collections For Sub county for the period 1 Jan 2023 to 31 Jan 2023</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Reports</a></li>
                                        <li class="breadcrumb-item"><a href="#">Revenue collection by county</a></li>
                                        <li class="breadcrumb-item"><a href="#">Sub County</a></li>
                                        <li class="breadcrumb-item"><a href="#">Revenue Agents</a></li>
                                        <li class="breadcrumb-item active">ALex</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->


                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white d-flex justify-content-between align-items-center w-100 border-bottom p-3">
                                    <div>
                                        <h4 class="card-title">Alex's Collections for  Town Subcounty</h4>
                                    </div>
                                    <div>
                                        <!-- select agents from the selected subcounty -->
                                        <select class="form-select w-auto font-13px ">
                                            <option value="10">Agent 1</option>
                                            <option value="25">Agent 2</option>
                                            <option value="50">Agent 3</option>
                                            <option value="100">Agent 4</option>

                                        </select>
                                    </div>
                                </div>
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                      <div class="flex-grow-1">
                                        <div class="search-box mb-0 me-3">
                                            <div class="position-relative">
                                                <input type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l">
                                                <i class="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                      </div>

                                        <div class="d-flex">
                                            <div class="d-flex align-items-center me-3 pe-3">
                                                <span class="pr-3 fw-semibold">From:</span>
                                                <input class="form-control" placeholder="Start date" type="date">
                                                <span class="px-3 fw-semibold">TO:</span>
                                                <input class="form-control" placeholder="Start date" type="date">
                                            </div>
                                            <button v-if="getRight('DOWNLOAD REPORTS')"  data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                                            </button>

                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button v-if="getRight('DOWNLOAD REPORTS')" type="button"  data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive overflow-visible">

                                        <table class="table align-middle table-hover  contacts-table table-striped " id="">
                                            <thead class="table-light">
                                                <tr class="table-dark">
                                                    <th>Receipt No.</th>
                                                    <th scope="col">Paid By</th>
                                                    <th>Date</th>
                                                    <th scope="col">Payment Method</th>
                                                    <th>Payment Details</th>
                                                    <th>Served By</th>
                                                    <th>Status</th>
                                                    <th>Validity Status</th>
                                                    <th scope="col" class="text-right">Amount</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <!--    <tr>
                                                    <td class="text-capitalize text-nowrap"><strong>PTY67809</strong></td>
                                                    <td class="text-capitalize text-nowrap">
                                                        <a href="reports-subconty-details.html">Kelvin 0704 549 859</a>
                                                    </td>
                                                    <td class="text-capitalize text-nowrap">22 Apr 2023 04:56 AM</td>
                                                    <td class="text-capitalize text-nowrap">
                                                        <span class="fw-semibold">MPESA RECSPPTHF</span>
                                                    </td>
                                                    <td>Daily Parking for KAD 123 Pick Up</td>
                                                    <td>
                                                        <a href="">Alex Wanjala</a>
                                                    </td>
                                                    <td><span class="badge badge-soft-success text-uppercase">PAID</span></td>
                                                    <th class="text-capitalize text-nowrap text-right">KES 220,000</th>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <a type="button" href="#" class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3">View Receipt</a>

                                                        </div>
                                                    </td>
                                                </tr>-->
                                            </tbody>
                                            <tfoot class="table-dark">
                                                <tr>

                                                    <th colspan="7">
                                                        23 Transactions
                                                    </th>


                                                    <td class="text-nowrap text-right " colspan="">
                                                        <span class="fw-semibold ">KES 2,000,000</span>
                                                    </td>
                                                    <TD></TD>
                                                    <td></td>


                                                </tr>

                                            </tfoot>
                                        </table>
                                    </div>

                                </div>

                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example " class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px ">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <li class="page-item disabled">
                                                <a class="page-link" href="#" tabindex="-1">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active">
                                                <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Bill details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Bill Prepared for:</strong><br>
                                        Kelvin Njuguna AT Mombasa Road<br>
                                        email@mail.com, 0704 549 859<br>
                                        ID No. 12345689. Car Plate No. KAB 123P <br/>
                                        <br>
                                        On 1 Mar 2022, 10:20 AM

                                    </address>
                                </div>
                                <div class="col-sm-6 text-sm-end d-none">
                                    <address class="mt-2 mt-sm-0">
                                        <strong>Payment Method</strong><br>
                                        <span class="fw-medium">MPESA</span><br>
                                        Phone No. 0704 549 859<br> <br>
                                        On 1 Mar 2022, 10:20 AM
                                    </address>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">ND12368</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap vertical-align-middle">
                                        <thead class="bg-light">
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Offense</th>
                                                <th class="text-end">Amount</th>

                                            </tr>
                                        </thead>
                                        <tfoot class="bg-light">
                                            <tr>
                                                <td colspan="2" class="border-0 text-end text-right">
                                                    <strong>Total</strong>
                                                </td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 11,250</h5>
                                                </td>

                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>01</td>
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0">Driving Without identification plates affixed or plates not fixed in the prescribed manner</p>
                                                </td>
                                                <td class="text-end">KES 24,500</td>

                                            </tr>

                                            <tr title="Drving a vehicle without a valid inspection certificate inspected">
                                                <td>02</td>
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0">Drving a vehicle without a valid inspection certificate inspected</p>

                                                </td>
                                                <td class="text-end">KES 24,500</td>

                                            </tr>




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <button type="button" class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Bill
                            </button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong></td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method" data-style="btn-secondary w-100" data-live-search="true" title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                     </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex " placeholder="Phone No." spellcheck="false" data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex" placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#" class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
             County Government
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>
    </body>

</template>

<script>
    import NavigationBar from "@/components/Navigation";
    import {getRight} from "@/api";
    export default {
        name: "ReportsAgentsDetails",
        components: {NavigationBar},

        methods:{
            getRight(type){
                return getRight(type)
            }
        }
    }
</script>

<style scoped>

</style>