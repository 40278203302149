<template>

<head>

    <meta charset="utf-8" />
    <title>Hotline Transporters Sacco | All invoices</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
        type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body @contextmenu.prevent data-sidebar="dark">
    <!-- Begin page -->
    <div id="layout-wrapper">


        <NavigationBar/>


        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Sub County Targets</h4>
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Set Ups</a></li>
                                        <li class="breadcrumb-item"><a href="#">Revenue Targets</a></li>
                                        <li class="breadcrumb-item active">Sub County Targets</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- tool bar -->

                  <!-- <ToolBarTargets/>-->

                    <ToolBarTargets/>
                    <!-- end of toolbar -->

                    <!-- target cards -->

                    <!-- end of target cards -->
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div
                                    class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100"
                                        role="toolbar">
                                        <div class="flex-grow-1">
                                            <div class="search-box mb-0 me-3">
                                                <div class="position-relative">
                                                    <input type="text"
                                                        class="form-control bg-light border-light rounded"
                                                        placeholder="Search..." fdprocessedid="husj3l">
                                                    <i class="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex">

                                            <button data-bs-toggle="modal" data-bs-target=".updownload-modal"
                                                type="button"
                                                class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="mdi mdi-microsoft-excel font-size-16"></i> <span
                                                    class="pl-1 d-md-inline">Upload Excel File</span>
                                            </button>

                                            <button data-bs-toggle="modal" data-bs-target=".download-modal"
                                            type="button"
                                            class="btn btn-success dropdown-toggle option-selector me-3 text-capitalize ">
                                            <i class="dripicons-download font-size-16"></i> <span
                                                class="pl-1 d-md-inline">Download</span>
                                        </button>

                                        </div>


                                    </div>

                                </div>
                                <div class="card-body">
                                    <div class="table-responsive overflow-visible">

                                        <table class="table align-middle table-hover  contacts-table" id="">
                                            <thead class="table-light">
                                                <tr class="table-dark">
                                                    <th>#</th>
                                                    <th scope="col">Sub County</th>
                                                   <!-- <th>Zones</th>-->
                                                    <th class="text-right">Daily Target</th>
                                                    <th class="text-right">Monthly Target</th>
                                                    <th class="text-right">Financial Year Target</th>
                                                    <th></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr :key="index" :value="item" v-for="(item, index) in subCounties">
                                                    <th>{{index+1}}.</th>

                                                    <td class="">
                                                        {{item.subCountyName}}
                                                    </td>
                                                   <!-- <td>
                                                        45
                                                    </td>-->
                                                    <td class="text-right">
                                                        KES {{numberFormat(item.dailyTargets)}}
                                                    </td>
                                                    <td class="text-right">
                                                        KES {{numberFormat(item.monthlyTargets)}}
                                                    </td>
                                                    <td class="text-right">
                                                        KES {{numberFormat(item.financialYearTargets)}}
                                                    </td>

                                                    <td>
                                                        <button @click="selectedSubCounty(item)" data-bs-toggle="modal" data-bs-target=".target-modal"
                                                            class="btn btn-primary btn-sm"><i
                                                                class="mdi mdi-file-document-edit-outline"></i>
                                                            Edit</button>
                                                    </td>
                                                </tr>




                                            </tbody>

                                        </table>
                                    </div>

                                </div>

                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example "
                                        class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px ">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <li class="page-item disabled">
                                                <a class="page-link" href="#" tabindex="-1">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active">
                                                <a class="page-link" href="#">2 <span
                                                        class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade updownload-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Targets Upload</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <address>
                                        <strong class="text-capitalize">Revenue Target for The Year</strong><br>
                                        2023/2024
                                    </address>
                                </div>

                                <div class="col-12">
                                    <address>
                                        <strong class="text-capitalize">Sample Excel Sheet</strong><br>
                                        <p><a href="#"><i class="mdi mdi-download"></i> Download Sample Excel File</a>
                                        </p>
                                    </address>
                                </div>

                                <div class="col-12">
                                    <label><strong>Select the file</strong></label>
                                </div>
                                <div class="col-12">
                                    <div>
                                        <form action="#" class="dropzone">
                                            <div class="fallback">
                                                <input name="file" type="file" multiple="multiple">
                                            </div>
                                            <div class="dz-message needsclick">
                                                <div class="mb-3">
                                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                </div>

                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div class="col-12">
                                    <button type="button"
                                        class="btn btn-primary dropdown-toggle option-selector text-capitalize w-100 mt-5">
                                        <i class="bx bxs-download  font-size-16"></i> <span
                                            class="pl-1 d-md-inline">Upload Excel File</span>
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- setting target -->
            <div class="modal fade target-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Collections Targets for {{subCounty.subCountyName}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">

                                <!-- calculated by dividing FY target by 365 -->

                                <div class="col-12">
                                    <label><strong>Daily Target Amount</strong></label>
                                </div>
                                <div class="col-12 mb-3">
                                    <input v-model="subCounty.dailyTargets" class="form-control" type="text" placeholder="Enter Amount">
                                </div>

                                <!-- calculated by dividing FY targets by 12 -->

                                <div class="col-12">
                                    <label><strong>Monthly Target</strong></label>
                                </div>
                                <div class="col-12 mb-3">
                                    <input v-model="subCounty.monthlyTargets" class="form-control" type="text" placeholder="Enter Amount">
                                </div>

                                <!-- this is what has been provided -->

                                <div class="col-12">
                                    <label><strong>Financial Year Target</strong></label>
                                </div>
                                <div class="col-12 mb-3">
                                    <input  v-model="subCounty.financialYearTargets" class="form-control" type="text" placeholder="Enter Amount">
                                </div>



                                <div class="col-12">
                                    <button @click="updateSubCountyTargets()" type="button" data-bs-dismiss="modal"
                                        class="btn btn-primary dropdown-toggle option-selector text-capitalize w-100 mt-5">
                                        <i class="mdi mdi-content-save-all  font-size-16"></i> <span
                                            class="pl-1 d-md-inline">Save Changes</span>
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of target setting -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong>
                                                </td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method"
                                                        data-style="btn-secondary w-100" data-live-search="true"
                                                        title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                    </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex "
                                                            placeholder="Phone No." spellcheck="false"
                                                            data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex"
                                                        placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#"
                                                            class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()"
                                    class="btn btn-success waves-effect waves-light me-1"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);"
                                    class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <!-- download modaL -->
            <div class="modal fade download-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Download Targets</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <address>
                                        <strong class="text-capitalize">Report Title</strong><br>
                                        Revenue Collections By Subcounties in Meru County
                                    </address>
                                </div>



                                <div class="col-12">
                                    <label><strong>Select File Type</strong></label>
                                    <select class="form-select">
                                        <option>Select Document Type</option>
                                        <option>PDF file</option>
                                        <option>Excel File</option>
                                    </select>
                                </div>

                                <div class="col-12">
                                    <button  type="button" class="btn btn-primary dropdown-toggle option-selector text-capitalize w-100 mt-5">
                                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- END OF DOWNLOAD MODAL -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
             County Government
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>


</template>

<script>

    import ToolBarTargets from "@/components/systemsettings/ToolBarTargets";
    import NavigationBar from "@/components/Navigation";
    import {biller, execute} from "@/api";
    export default {
        name: "TargetsSubCounty",
        components: {NavigationBar, ToolBarTargets},
        data() {
            return {
                subCounties:[
                    {
                        subCountyName: null,
                        subCountyID: null,
                        dailyTargets: null,
                        monthlyTargets: null,
                        financialYearTargets: null
                    }
                ],
                subCounty:
                    {
                        subCountyName: null,
                        subCountyID: null,
                        dailyTargets: null,
                        monthlyTargets: null,
                        financialYearTargets: null
                    }
                ,
                currentPage:'',
            };
        },
        mounted() {

            this.getSubCountyTargets()
             this.menuAccess()
        },
        methods:{

          menuAccess(){

            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "Targets Sub County");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then(() => {
                  // alert(res.data.message);
                })
                .catch(() => {
                  // alert(e.message);
                });

          },

            selectedSubCounty(item) {
               this.subCounty = item
            },
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            getSubCountyTargets() {
                const data = new FormData();
                data.append("function", "getSubCountyTargets");
                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.subCounties = res.data.data.subCounties
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            updateSubCountyTargets() {
                const data = new FormData();
                data.append("function", "updateSubCountyTargets");
                data.append("subCounty", JSON.stringify(this.subCounty));
                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.subCounties.splice(0)
                            this.getSubCountyTargets()
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
        }
    }
</script>

<style scoped>

</style>