<script setup>

</script>

<template>
  <div class="row">
    <div class="col-12 active">
      <div class="card active">
        <div class="card-body mini-nav bg-primary pt-2 pb-3 active ">
          <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
            <button
                class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary"
                type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                aria-label="Toggle navigation">
              <span class="mdi mdi-menu"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-between active"
                 id="navbarNavAltMarkup">
              <div class="navbar-nav">

                <a :class="activeClass" @click="gotTo('cert-alcohol-dashboard')" href="javascript:void(0)">Quick Stats</a>
                <a :class="activeClass2" @click="gotTo('cert-alcohol-ongoing')" href="javascript:void(0)">Ongoing Applications</a>
                <a :class="activeClass3" @click="gotTo('cert-alcohol-inspection')" href="javascript:void(0)">Pending Inspection</a>
                <a :class="activeClass4" @click="gotTo('cert-alcohol-review')" href="javascript:void(0)">Pending Court Review</a>
                <a :class="activeClass5" @click="gotTo('cert-alcohol-approved')" href="javascript:void(0)">Awaiting Certification</a>
<!--                <a :class="activeClass6" @click="gotTo('cert-alcohol-ongoing')" href="javascript:void(0)">Declined </a>
                <a :class="activeClass7" @click="gotTo('cert-alcohol-ongoing')" href="javascript:void(0)">Certificate Invoices</a>-->
                <a :class="activeClass8" @click="gotTo('cert-alcohol-active')" href="javascript:void(0)">Active Certificates</a>
<!--                <a :class="activeClass9" @click="gotTo('cert-alcohol-ongoing')" href="javascript:void(0)">Expired Certificates</a>
                <a :class="activeClass10" @click="gotTo('cert-alcohol-ongoing')" href="javascript:void(0)">Map View</a>-->
              </div>

              <form class="form-inline my-2 my-lg-0">
                <button type="button"  class="btn btn-info waves-effect waves-light my-2 my-sm-0 text-uppercase" data-toggle="modal" data-target=".new-alcohol-application">New Application</button>
              </form>


            </div>
          </nav>
        </div>
      </div>

    </div>
  </div>

  <!-- Bill creation modal -->
  <div class="modal fade new-alcohol-application" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog " aria-labelledby="staticBackdropLabel " aria-hidden="true ">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header liq-header">
          <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Alcoholic Drinks Licence New Application</h5>

          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pb-4 searching-modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="">Search or Input establishment details</label>
              <select v-model="selectedMethod" class="form-select liq-search-method" >
                <option value="">Select Search Method</option>
                <option value="search">Search from Existing Registered Businesses</option>
                <option value="register" class="register">Unregestered Business</option>
              </select>

            </div>
          </div>

          <div class="row">

            <!-- searching for an already registered business that has a valid certificate generated from the system -->
            <div v-if="selectedMethod === 'search'" class="col-12 business-id-search">
              <label for="">Search For Business To continue</label>
              <div class="input-group mb-3">

                <input v-model="businessID" type="text" class="form-control" placeholder="Enter Business ID">
                <button type="button" class="btn btn-primary waves-effect waves-light search-bz-btn">
                  <i class="bx bx-search-alt-2 font-size-16 align-middle me-2"></i> Search
                </button>
              </div>

              <!-- business serach results start here display only  when there are results to be shown -->
              <div class="row pt-3">

                <!-- use this loader when serching for the business -->
                <div  class="searching-biz-loader" v-if="businessSearch">
                  <div class="loader-modal p-5" >
                    <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                      <div class="spinner-border text-dark m-1" role="status">
                        <span class="sr-only fw-bold">Searching</span>
                      </div>
                      <p class="pt-4 fw-strong text-uppercase fw-bold">Please wait patiently while our system looks <br> for the business.</p>
                    </div>
                  </div>
                </div>

                <div class="col-12 biz-search-results" v-if="businessDetailsResult">
                  <table class="table table-sm text-uppercase table-striped">
                    <thead class="table-dark">
                    <th colspan="2">Search Results for Business with <span class="text-warning">Business ID {{businessDetails.businessID}}</span></th>
                    </thead>
                    <tbody>
                    <tr>
                      <th>
                        Name of Establishment
                      </th>
                      <td>
                        {{businessDetails.businessName}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Sub County
                      </th>
                      <td>
                        {{businessDetails.subCountyName}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Ward
                      </th>
                      <td>
                        {{businessDetails.wardName}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Physical Address
                      </th>
                      <td>
                        {{businessDetails.physicalAddress}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Building/Name of Premises
                      </th>
                      <td>
                        {{businessDetails.buildingName}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Postal Address
                      </th>
                      <td>
                        {{businessDetails.postalCode}}   {{businessDetails.postalAddress}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Applicant's Name
                      </th>
                      <td>
                        {{businessDetails.fullNames}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Phone Number
                      </th>
                      <td>
                        {{businessDetails.ownerPhone}}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Email Address
                      </th>
                      <td>
                        {{businessDetails.ownerEmail}}
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <div class="row">
                    <div class="col-12">
                      <button @click="registerBusiness()" type="button" class="btn btn-success waves-effect waves-light w-100 generate-invoice-btn">
                        <i class="mdi mdi-checkbox-multiple-marked font-size-16 align-middle me-2"></i> {{invoiceMessage}}
                      </button>
                    </div>
                  </div>
                </div>


                <!-- use this for a search that has no results -->
                <div class="col-12 no-results d-none">
                  <div class="p-4 border-radius border-solid border-primary border-2 d-flex flex-column align-items-center justify-content-center">
                    <div class="img mb-4 pt-3">
                      <img src="assets/images/no-result-found-icon.svg" style="height: 65px;" class="img" alt="">
                    </div>
                    <div class="text-center">
                      <h4 class="card-title text-uppercase text-dark">No results Found</h4>
                      <p>
                        The business ID you entered does not correspond to any registered business. Please try your search again <br> to find the desired results.</p>


                    </div>
                  </div>
                </div>


              </div>
              <!-- end of business serach results -->
            </div>


            <!-- form for registering ann unregistered business -->
            <div v-if="selectedMethod === 'register'" class="col-12 unregistered-biz-cont">
              <div class="col-12">
                <div class="mb-3">
                  <label for="formrow-firstname-input" class="form-label">Name of Establishment</label>
                  <input v-model="businessDetails.businessName" type="text" class="form-control" id="formrow-firstname-input" placeholder="Enter the Business Name" spellcheck="false" data-ms-editor="true">
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-8">
                  <div class="row">

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Name of Premices</label>
                        <input v-model="businessDetails.buildingName"  type="text" class="form-control" id="formrow-firstname-input" placeholder="Building Name" spellcheck="false" data-ms-editor="true">
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <label class="text-nowrap">Sub County</label>
                      <select class="form-select mb-3" title="Select Subcounty" v-model="subCounty">
                        <option v-for="(item, index) in subCounties" :value="item" :key="index">
                          {{ item.subCountyName }}
                        </option>
                      </select>


                    </div>

                    <div class="col-sm-12 col-md-6">
                      <label class="text-nowrap">Ward</label>
                      <select class="form-select mb-3" title="Select Subcounty" v-model="ward">
                        <option v-for="(item, index) in wards" :value="item" :key="index">
                          {{ item.wardName }}
                        </option>
                      </select>

                    </div>


                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Applicant's Name</label>
                        <input v-model="businessDetails.fullNames" type="text" class="form-control" id="formrow-firstname-input" placeholder="eg John Doe" spellcheck="false" data-ms-editor="true">
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Applicant's Postal Address</label>
                        <input v-model="businessDetails.postalAddress" type="text" class="form-control" id="formrow-firstname-input" placeholder="eg 123-001200 Nairobi" spellcheck="false" data-ms-editor="true">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Physical Address</label>
                        <textarea v-model="businessDetails.physicalAddress" placeholder="Enter the Business's Physical Address" class="form-control" name="" id="" cols="30" rows="10"></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Appilicant's Phone</label>
                    <input v-model="businessDetails.ownerPhone" type="text" class="form-control" id="formrow-firstname-input" placeholder="Enter Phone Number" spellcheck="false" data-ms-editor="true">
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Appilicant's Email</label>
                    <input v-model="businessDetails.ownerEmail" type="email" class="form-control" id="formrow-firstname-input" placeholder="email@email.com" spellcheck="false" data-ms-editor="true">
                  </div>
                </div>

<!--                <div class="col-12 mt-3">
                  <h4 class="text-capitalize">Establishment's Physical location</h4>
                  <p>Select the address where this establishmnet is located from this map.</p>
                </div>

                <div class="col-12">
                  <div class="">
                    <div class="card-header bg-white boarder-bottom-1 d-none">
                      <div class="row d-flex justify-content-between">
                        <div class="col-12">
                          <div class="search-box mb-2 me-2">
                            <div class="position-relative">
                              <input type="text" class="form-control bg-light border-light rounded map-searcher" id="pac-inpu" placeholder="Search for location">
                              <i class="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

&lt;!&ndash;                    <div class="" style="height: 350px;">
                      <div id="map" class="w-100 mt-1 h-100 live-map-cont main-map-container"></div>
                    </div>&ndash;&gt;

                  </div>

&lt;!&ndash;                  <div class="">
                    <div class="position-relative ">
                      &lt;!&ndash; map serach input box &ndash;&gt;
                      <div class="form-group map-searcher mt-3 pt-3">
                        <input type="text" class="form-control form-control-lg text-black" placeholder="Search for a location" id="pac-input">
                        <button title="Clear search results" class="btn btn-transparent border-0 clear-map"><i class="mdi mdi-close d-none"></i></button>
                      </div>
                    </div>
                  </div>&ndash;&gt;

                </div>-->

                <div class="col-12">
                  <button @click="registerBusiness()"  target="_blank" type="button" class="btn btn-success waves-effect waves-light w-100 generate-invoice-btn">
                    <i class="mdi mdi-checkbox-multiple-marked font-size-16 align-middle me-2"></i> {{invoiceMessage}}
                  </button>
                </div>




              </div>
            </div>

          </div>
        </div>

        <!-- matters payment and billing starts here  d-none -->
        <div class="modal-body receipt-billing-modal-body">
          <div class="billing-receipting-loader" v-if="generatingInvoice">
            <div class="loader-modal p-5">
              <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                <div class="spinner-border text-dark m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <p class="pt-4 fw-strong text-uppercase">Generating Invoice Please Wait</p>
              </div>
            </div>
          </div>

          <!-- generated invoice results d-none -->
          <div class="row generated-invoice" v-if="invoiceDetails">
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <address>
                    <strong class="text-capitalize">Invoice Prepared for:</strong>
                    <br>Establishment Name: <span class="fw-semibold text-black">{{billDetails.description}}</span><br>
                    Phone: <span class="fw-semibold text-black">{{billDetails.customerPhoneNumber}}</span>
                    <br>
                    <br> Prepared on {{billDetails.dateCreated}} By {{billDetails.idNo}}</address></div>
              </div>
              <div class="table-responsive">
                <table class="table table-nowrap vertical-align-middle table-bordered ">
                  <thead class="table-dark">
                  <tr class="vertical-align-middle">
                    <th style="width: 70px;">1.</th>
                    <th class="text-uppercase" colspan="3">
                      Invoice No:. <span class="fw-bold text-warning">{{billDetails.billNo}}</span>
                    </th>

                  </tr>
                  </thead>
                  <thead class="bg-light">
                  <tr>
                    <th style="width: 70px;">No.</th>
                    <th colspan="2">Item Description (X QTY)</th>
                    <th class="text-end">Amount</th>

                  </tr>
                  </thead>
                  <tfoot class="bg-light text-uppercase table-bordered">
                  <tr>
                    <td></td>

                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      Invoice Sub Total
                    </td>
                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      KES {{billDetails.detailAmount}}
                    </td>

                  </tr>
                  <tr>
                    <td></td>

                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      Amount Paid
                    </td>
                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      KES {{billDetails.receiptAmount}}
                    </td>

                  </tr>

                  <tr class="table-success">
                    <td></td>

                    <td class="text-right fw-bold text-uppercase" colspan="2">
                      Total Payable
                    </td>
                    <td class="border-0 text-end text-right">
                      <h5 class="m-0 text-uppercase fw-bold">KES {{billDetails.detailAmount}}</h5>
                    </td>

                  </tr>
                  </tfoot>
                  <tbody class="text-uppercase">
                  <tr :key="index" :value="item" v-for="(item, index) in billInfo">
                    <td>{{index+1}}</td>
                    <td colspan="2">
                      <p class="mb-0 p-0 m-0"> {{item.feeDescription}}</p>
                    </td>
                    <td class="text-end text-right">KES {{item.billTotal}}</td>

                  </tr>


                  </tbody>
                </table>
              </div>

              <div class="d-flex text-right justify-content-end align-self-end">
                <button type="button" data-toggle="modal" data-target="#payment-modal" class="btn btn-primary waves-effect btn-label waves-light fw-bold text-uppercase me-3">
                  <i class="mdi mdi-send label-icon"></i> Receipt Invoice
                </button>
                <a @click="printBill()" class="btn btn-success waves-effect waves-light fw-semibold text-uppercase" type="button"><i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Invoice</a>
              </div>
            </div>
          </div>
          <!-- end of generated receipt results -->
        </div>


      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- end of bill creation modal -->

  <!-- payment modal -->
  <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header d-none">
          <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


          <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
          </button>

        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-between align-items-center">
            <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


            <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
            </button>
          </div>

          <div class="payment-panel-parent">

            <div v-if="paymentPanel" class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
              <div class="success-image mb-4 pb-4 pt-4">
                <img src="assets/images/bills.svg" height="200" alt="">
              </div>
              <h4 class="text-black fw-bold">{{message}}</h4>
              <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
              <div class="d-flex w-100 pb-2">
                <button @click="showPaymentMethod()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                  <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                  Receive Payment
                </button>
                <a @click="printBill()" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
              </div>
            </div>

            <div v-if="paymentMethod" class="send-method payment-panel ">
              <label for="" class="mb-0 pb-0">Payment Method</label>
              <p><small class="text-muted">How would you like to send this money?</small></p>

              <div>
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div class="flex-shrink-0 me-3">
                          <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                        </div>
                        <div class="d-flex flex-column">
                          <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                          <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                        </div>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <div>
                          <p>
                            A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to the MPESA number you enter below.
                          </p>
                        </div>
                        <div class="form-group">
                          <label for="">MPESA Number</label>
                          <div class="form-floating mb-3">
                            <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                            <label for="floatingnameInput">Phone No.</label>
                          </div>

                          <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                            <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                            Send Payment Request
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div class="flex-shrink-0 me-3">
                          <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                        </div>
                        <div class="d-flex flex-column">
                          <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                          <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>


                        </div>
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <div class="form-group">
                          <label for="">Enter The Bank's Receipt Number</label>
                          <div class="form-floating mb-3">
                            <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                            <label for="floatingnameInput">Bank Ref No.</label>
                          </div>

                          <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div v-if="transactionSummary" class="transaction-summary payment-panel">
              <label for="">Transaction Breakdown</label>
              <div class="border p-4 rounded ">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="text-muted ">
                      BillO NO.
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end">
                    <div class=" text-right text-black">
                      {{billDetails.billNo}}
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="text-muted mt-2">
                      Bill For
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end">
                    <div class=" text-right text-black">
                      {{billDetails.incomeTypeDescription}}
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="text-muted mt-2">
                      Payment Method
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end">
                    <div class=" text-right text-black">
                      {{paymentData.paymentMode}}
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="text-muted mt-2">
                      Transaction Amount
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end">
                    <div class=" text-right text-black">
                      KES {{billDetails.billBalance}}
                    </div>
                  </div>


                  <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                  <div class="col-lg-6 text-uppercase">
                    <div class="text-muted mt-2 font-21">
                      Total
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-end text-uppercase">
                    <div class=" text-right text-black font-21 fw-bold">
                      KES {{billDetails.billBalance}}
                    </div>
                  </div>
                </div>

                <div class="mpesa-confirmation ">
                  <div v-if="paymentData.paymentMode=='MPESA'">
                    <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ phoneNumber }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                      <br>
                      <br>
                      Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                  </div>

                  <div>{{message}}  </div>
                  <br>
                  <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="stk-timer-container d-none justify-content-center align-items-center">
                        <span class="mdi mdi-timer-outline font-16px"></span>
                        <span class="stk-timer px-2"></span>
                      </div>
                      <div class="justify-content-center align-items-center d-flex">
                        <span class="px-2">Send Request</span>
                        <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                      </div>
                    </div>
                  </button>
                </div>


              </div>
            </div>

            <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
              <div class="success-image mb-4">
                <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
              </div>
              <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
              <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
              <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                Print Receipt
              </button>
            </div>



          </div>
        </div>
        <div class="modal-footer d-flex d-none bill-modal-footer">
          <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i>Previouse</button>
          <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
          <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
            <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end of payment modal -->
</template>

<script>
/* eslint-disable */ /* eslint-disable */

import {biller, execute, liquor, parking, trade} from "@/api";

export default {
  name: "ToolBarLiquor",
  data(){
    return{
      invoiceMessage:'Generate Invoice',
      selectedMethod:"",
      todayHighlights:[
        {
          title: "LOGGED VEHICLES",
          amount: "41,695"
        }
      ],
      subCountyName:'',
      invoiceDetails: false,
      generatingInvoice: false,
      businessSearch: false,
      businessDetailsResult : false,
      businessDetails:{
        id: "",
        businessID: "",
        businessName: "",
        subCountyID: "",
        subCountyName: "",
        wardID: "",
        wardName: "",
        plotNumber:"",
        physicalAddress: "",
        buildingName: "",
        buildingOccupancy: "",
        floorNo: "",
        roomNo: "",
        premiseSize: "",
        numberOfEmployees: "",
        tonnage :"",
        businessDes: "",
        businessCategory: "",
        businessSubCategory : "",
        incomeTypeID : "",
        feeID : "",
        businessEmail: "",
        postalAddress : "",
        postalCode: "",
        businessPhone: "",
        contactPersonNames: "",
        contactPersonIDNo: "",
        businessRole: "",
        contactPersonPhone: "",
        contactPersonEmail: "",
        fullNames: "",
        ownerID: "",
        ownerPhone: "",
        ownerEmail: "",
        kraPin: "",
        createdBy: "",
        createdByIDNo: "",
        dateCreated: "",
        lat : 0.0,
        lng : 0.0
      },
      subCounty:{},
      ward:{},

      download:'Download Report',
      // set the initial current page as 1
      currentPage: 1,
      // set the total number of pages as 10 (you can change this according to your data source)
      totalPages: 0,
      // set the number of items per page as 5 (you can change this according to your preference)
      pageSize: 10,
      summary: {
        count: 0,
        total: 0
      },
      receiptDetails: {
        id: '',
        receiptNo: null,
        billNo: '',
        detailAmount: '',
        receiptAmount: '',
        billBalance: '',
        costCenterNo: '',
        accountNo: '',
        incomeTypeDescription: '',
        feeID: '',
        wardID: '',
        subCountyID:'',
        currency: '',
        source: '',
        transactionCode: '',
        paidBy: '',
        dateCreated: '',
        dateModified: '',
        createdBy: '',
        modifiedBy: '',
        isActive: '',
        status: ''
      },
      receiptInfos: [
        {
          id: '',
          receiptNo: '',
          billNo: '',
          billTotal: '',
          receiptAmount: '',
          billBalance: '',
          customer: '',
          clientPhoneNo: '',
          description: '',
          receiptDate: '',
          printCount: '',
          wardID: '',
          subCountyID: '',
          dateCreated: '',
          dateModified: '',
          createdBy: '',
          modifiedBy: '',
          printedBy: '',
          updated: '',
          isActive: '',
          status: ''
        }],
      totalAmount: 0,
      search:'',
      dateFrom: '',
      dateTo: '',
      loading:false,
      message:null,
      fiscalYear:'',
      businessID:null,
      feesAndChargeItems:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        customer: null
      }],
      billItem:[],
      quantity:1,
      transactionCompleted: false,
      transactionSummary: false,
      paymentPanel: true,
      paymentMethod: false,
      billDetails:{
        id:'',
        billNo:'',
        incomeTypeID:'',
        incomeTypeDescription:'',
        costCenterNo:'',
        accountNo:'',
        feeID:'',
        detailAmount:'',
        receiptAmount:'',
        billBalance:'',
        wardID:'',
        subCountyID:'',
        status:'',
        dateCreated:''
      },
      billInfo:[],
      paymentData: {
        bankReceiptNo: null,
        accNo: null,
        payBillNo: null,
        amount: null,
        phoneNumber: null,
        success: false,
        paymentMode:null,
        sessionNames:null,
        sessionIdNo:null,
        sessionPhoneNumber:null
      },
      applications:[{
        id: null,
        billNo: null,
        businessID:null,
        businessName:null,
        billTotal:null,
        receiptAmount:null,
        billBalance:null,
        statusID:null,
        status:null,
        printable:null,
        issuingOfficer:null,
        designation:null,
        dateOfIssue:null,
        stageStatus:null,
        comments:null,
        incomeTypeId:null,
        feeID:null,
        subCountyName:null,
        wardName:null,
        subCountyID:null,
        wardID:null,
        brimsCode:null,
        plotNumber:null,
        fiscalYear:null,
        applicant:null,
        phoneNumber:null,
        businessSubCategory:null
      }],
      application:{
        id: null,
        billNo: null,
        businessID:null,
        businessName:null,
        billTotal:null,
        receiptAmount:null,
        billBalance:null,
        statusID:null,
        status:null,
        printable:null,
        issuingOfficer:null,
        designation:null,
        dateOfIssue:null,
        stageStatus:null,
        comments:null,
        incomeTypeId:null,
        feeID:null,
        subCountyName:null,
        wardName:null,
        subCountyID:null,
        wardID:null,
        brimsCode:null,
        plotNumber:null,
        fiscalYear:null,
        applicant:null,
        phoneNumber:null,
        businessSubCategory:null,
        businessCategory:null
      },
      tradeCategories: [{
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix: null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategoryID: null,
        zoneCategory: null
      }],
      tradeSubCategories: [
        {
          feeId: null,
          feeDescription: null,
          unitOfMeasure: null,
          unitFeeAmount: null,
          accountNo: null,
          incomeTypeId: null,
          feeType: null,
          accountDesc: null,
          costCenterNo: null,
          zone: null,
          typeDescription: null,
          prorated: null,
          duration: null
        }
      ],
      subCounties: [{
        subCountyID: "",
        subCountyName: ""
      }],
      wards: [{
        wardID: "",
        wardName: ""
      }],
    }
  },
  watch: {
    subCounty: function () {
      //Trade demographics
      this.businessDetails.subCountyName = this.subCounty.subCountyName
      this.businessDetails.subCountyID = this.subCounty.subCountyID

      this.getWards()
    },
    ward: function () {
      //Trade demographics
      this.businessDetails.wardName = this.ward.wardName
      this.businessDetails.wardID = this.ward.wardID

    },
    businessID(){
      const keyword = this.businessID;
      this.businessSearch = false
      this.getBusiness(keyword)
    }
    },

  mounted() {
    this.currentPage = sessionStorage.getItem("currentPage")
    this.getSubCounties()
  },

  computed: {
    activeClass(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-dashboard'
      }
    },
    activeClass2(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-ongoing'
      }
    },
    activeClass3(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-inspection'
      }
    },
    activeClass4(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-review'
      }
    },
    activeClass5(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-approved'
      }
    },
    activeClass6(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-ongoing'
      }
    },
    activeClass7(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-ongoing'
      }
    },
    activeClass8(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-active'
      }
    },
    activeClass9(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-ongoing'
      }
    },
    activeClass10(){
      return {
        'nav-item': true,
        'nav-link': true,
        'active': this.currentPage === 'cert-alcohol-ongoing'
      }
    }
  },
  methods:{
    printViewBill(item){
      this.application = item;
      this.billDetails.billNo = item.billNo
      this.printBill()
    },
    getBusiness(keyword){
      this.invoiceDetails = false
      this.businessSearch = true
      const data = new FormData();
      data.append("function", "getBusiness");
      data.append("businessID", keyword);
      execute(data,trade)
          .then((res) =>{
            if (res.data.success) {
              this.businessSearch = false
              this.businessDetailsResult = true
              this.businessDetails =  res.data.data.business

              this.subCounty.subCountyID = this.businessDetails.subCountyID
              this.subCounty.subCountyName =  this.businessDetails.subCountyName
              this.ward.wardID =  this.businessDetails.wardID
              this.ward.wardName = this.businessDetails.wardName
            }else{
              this.businessSearch = false
              this.businessDetailsResult = false
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.businessSearch = false
            //alert(e.message);
            this.message =e.message
          });
    },
    getFeeAndChargesByTypeDescription(){
      this.businessDetailsResult = false
      this.generatingInvoice = true
      const data = new FormData();
      data.append("function", "getFeeAndChargesByTypeDescription");
      data.append("typeDescription", "LIQUOR REGISTRATION");
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.generatingInvoice = false
              this.feesAndChargeItems.splice(0)
              //Set fee and charge
              this.feesAndChargeItems = res.data.data.feesAndCharges
              this.feesAndChargeItems = this.feesAndChargeItems.map(item => {
                item.customer = "Liquor ID: " + this.businessDetails.businessID;
                return item;
              });

              this.billItem.splice(0)
              this.addToBill(this.businessDetails.businessName);

            }else{
              this.businessDetailsResult = true
              this.generatingInvoice = false
              alert(res.data.message)
            }
          })
          .catch((e) => {
            this.businessSearch = false
            //alert(e.message);
            this.message =e.message
          });
    },

    addToBill(revenueStreamItem){

      for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer} of this.feesAndChargeItems) {
        this.totalAmount += parseFloat(unitFeeAmount * this.quantity);

        this.billItem.push({
          fiscalYear: this.fiscalYear,
          typeDescription,
          feeDescription,
          unitFeeAmount: unitFeeAmount * this.quantity,
          accountNo,
          costCenterNo,
          incomeTypeId,
          revenueStreamItem: revenueStreamItem,
          feeId,
          amount:  unitFeeAmount * this.quantity,
          customer: customer,
          zone: sessionStorage.getItem("sessionZone"),
          subCountyID:this.subCounty.subCountyID,
          subCountyName:this.subCounty.subCountyName,
          wardID: this.ward.wardID,
          wardName: this.ward.wardName,
          idNo: sessionStorage.getItem("sessionIdNo"),
          phoneNumber: sessionStorage.getItem("sessionPhoneNumber"),
          names: sessionStorage.getItem("sessionNames"),
          customerPhoneNumber: this.phoneNumber,
          description: this.description,
        });
      }

      this.generateBill()
    },
    generateBill(){
      this.generatingInvoice = true
      this.paymentMethod = false;
      this.paymentPanel = true;
      this.transactionSummary = false ;
      this.transactionCompleted = false
      this.message ="Generating Bill...please wait... "
      const data = new FormData();
      data.append("function", "generateBill3");
      data.append("billItem", JSON.stringify(this.billItem));
      execute(data,biller)
          .then((res) =>{
            this.generatingInvoice = false

            this.message =res.data.message
            if (res.data.success) {
              this.invoiceDetails  = true
              this.billDetails = res.data.data.bill.billDetails
              this.billInfo = res.data.data.bill.billInfo
              this.paymentData.amount = res.data.data.bill.billDetails.billBalance
              this.paymentData.accNo = res.data.data.bill.billDetails.billNo
              this.paymentData.payBillNo = res.data.data.billGenerated.payBillNo
              this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")
              this.paymentData.sessionNames = sessionStorage.getItem("sessionNames")
              this.paymentData.sessionIdNo = sessionStorage.getItem("sessionIdNo")

            }else{
              this.generatingInvoice = false
              this.invoiceDetails = false
              alert(res.data.message);
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },

    printBill() {
      localStorage['params'] = JSON.stringify({
        billNo: this.billDetails.billNo
      })
      const routeData = this.$router.resolve({name: 'bill'});
      window.open(routeData.href, '_blank');
    },
    printReceipt() {
      if (this.receiptDetails.receiptNo == null) {
        alert("Try again later");
      } else {
        //  this.$refs.closeReceived.click();
        localStorage['params'] = JSON.stringify({
          receiptNo: this.receiptDetails.receiptNo
        })
        const routeData = this.$router.resolve({name: 'receipt'});
        window.open(routeData.href, '_blank');
      }
    },
    showPaymentMethod(){
      this.paymentMethod = true;
      this.paymentPanel = false;
      this.transactionSummary = false;
      this.transactionCompleted = false
    },
    showTransactionSummary(paymentMode){

      this.paymentData.paymentMode = paymentMode

      if(paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }
      if(paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }

      }

      this.paymentMethod = false;
      this.paymentPanel = false;
      this.transactionSummary = true;
      this.transactionCompleted = false
    },
    makePayment() {
      if(this.paymentData.paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }
      if(this.paymentData.paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }
      this.message = "Payment Request sent";
      this.paymentData.phoneNumber = this.phoneNumber

      const data = new FormData();
      data.append("function", "makePayment");
      data.append("paymentData", JSON.stringify(this.paymentData));

      let stream = null;
      if (this.paymentData.accNo.startsWith("SBP")) {
        stream = trade;
      }
      if (this.paymentData.accNo.startsWith("PKN")) {
        stream = parking;
      }

      if (this.paymentData.accNo.startsWith("RNT")) {
        stream = rent;
      }

      if (this.paymentData.accNo.startsWith("BLL")) {
        stream = biller;
      }

      execute(data, stream)
          .then((res) => {
            if (res.data.success) {
              this.paymentMethod = false;
              this.paymentPanel = false;
              //start checking payment
              this.timer = setInterval(() => {

                this.checkPayment();
              }, 3000);
            } else {
              this.rate = null;
              alert(res.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    checkPayment() {
      this.randomNumber = Math.floor(Math.random() * 3) + 1
      if(this.randomNumber === 1){
        this.message = "Waiting for payment.";
      }
      if(this.randomNumber === 2){
        this.message = "Waiting for payment..";
      }
      if(this.randomNumber ===3){
        this.message = "Waiting for payment...";
      }
      const data = new FormData();
      data.append("function", "checkPayment");
      data.append("accountRef", this.paymentData.accNo);

      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.message = null;
              clearInterval(this.timer);
              this.receipt = true;
              this.paymentData.success = false;
              this.transaction = res.data.data.transaction;
              this.receiptDetails = res.data.data.receipt.receiptDetails
              //Close the modal
              this.transactionSummary = false
              this.transactionCompleted = true
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },

    gotTo(route){
      sessionStorage.setItem("currentPage",route)
      this.$router.push(route);
    },
    getTodayHighlights(){
      this.todayHighlights.splice(0)
      const data = new FormData();
      data.append("function", "todayHighlights");
      execute(data,parking)
          .then((res) =>{
            if (res.data.success) {
              this.todayHighlights = res.data.data.todayHighlights

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    registerBusiness(){
      this.invoiceMessage ="Please wait.."
      const data = new FormData();
      data.append("function", "registerBusiness");
      data.append("business", JSON.stringify(this.businessDetails));
      execute(data,liquor)
          .then((res) =>{
            this.invoiceMessage ="Generate Invoice"

            if (res.data.success) {
              this.selectedMethod = ""
              this.businessDetails = res.data.data.business
              this.getFeeAndChargesByTypeDescription()
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getSubCounties(){
      const data = new FormData();
      data.append("function", "getSubCounty");
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.subCounties =res.data.data.subCounties
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getWards(){
      this.wards =[]
      this.ward = ''
      const data = new FormData();
      data.append("function", "getWards");
      data.append("subCountyID", this.subCounty.subCountyID);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.wards =res.data.data.wards
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
  }
}

</script>

<style scoped>

</style>