<template>
    <div class="row">
        <div class="col-12 active">
            <div class="card active">
                <div class="card-body mini-nav bg-primary pt-2 pb-3 active ">
                    <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
                        <button
                                class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary"
                                type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span class="mdi mdi-menu"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between active"
                             id="navbarNavAltMarkup">
                            <div class="navbar-nav">
                                <router-link :to="{name: 'targets-SubCounty'}" active-class="mm-active" class="nav-item nav-link">Sub County Targets</router-link>
                                <router-link :to="{name: 'targets-collectors'}" active-class="mm-active" class="nav-item nav-link">Revenue Collector Targets</router-link>
                                <router-link :to="{name: 'targets-stream'}" active-class="mm-active" class="nav-item nav-link">Revenue Stream Targets</router-link>
                            </div>


                        </div>
                    </nav>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "ToolBarTargets",
        data() {
            return {
                selectedSub: null,
            }
        },
        methods:{
            mounted() {
                this.selectedSub = sessionStorage.getItem("selectedSub")
            },
            gotTo(route){
                this.$router.push(route);
                sessionStorage.setItem("selectedSub",route)
                this.selectedSub = route
            }
        }
    }
</script>

<style scoped>

</style>