<template>

  <head>

    <meta charset="utf-8" />
    <title>Hotline Transporters Sacco | All invoices</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body @contextmenu.prevent data-sidebar="dark">

  <!-- Begin page -->
  <div id="layout-wrapper">


    <NavigationBar/>

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Rentings for Modern Market</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="housing-quick-stat.html">Housing</a></li>
                    <li class="breadcrumb-item"><a href="housing-register.html">Rentals Register</a></li>
                    <li class="breadcrumb-item active"><a href="#">Modern Market</a></li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- tool bar -->
          <ToolBarHousing/>
          <!-- end page title -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header bg-white  w-100 border-bottom d-flex justify-content-between align-items-center">
                  <h4 class="card-title text-uppercase">
                    {{property.property}}
                  </h4>
                  <div class="d-flex">

                    <a @click="gotTo('housing-new-unit')" type="button" class="btn btn-success text-uppercase dropdown-toggle option-selector me-3">
                      <i class="bx bx-plus font-size-16"></i> <span class="pl-1 d-md-inline">Add Unit</span>
                    </a>



                  </div>
                </div>
                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">


                  <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                    <div class="flex-grow-1">
                      <div class="search-box mb-0 me-3">
                        <div class="position-relative">
                          <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l">
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="input-group  fw-semibold text-uppercase me-3">
                        <label class="input-group-text text-uppercase">Unit type {{category}}</label>


                        <select class="form-select"  v-model="category">
                          <option value="" selected="">All Unit Types (n)</option>
                          <option v-for="(item, index) in unitCategories" :key="`${index}`">{{item.category}} ({{numberFormat(item.count)}})</option>

                        </select>

                      </div>
                    </div>

                    <div>

                      <div class="input-group  fw-semibold text-uppercase me-3">
                        <label class="input-group-text text-uppercase">Unit Status</label>
                        <select class="form-select" value="unitStatus" v-model="unitStatus">
                          <option value="" selected="">All Units (n)</option>
                          <option v-for="(item, index) in unitStatuses" :key="`${index}`">{{item.status}} ({{numberFormat(item.count)}})</option>
                        </select>

                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="d-fle align-items-center me-3 pe-3 d-none">
                        <span class="pr-3 fw-semibold">From:</span>
                        <input class="form-control" placeholder="Start date" type="date">
                        <span class="px-3 fw-semibold">TO:</span>
                        <input class="form-control" placeholder="Start date" type="date">
                      </div>
                      <!--                      <button  data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                              <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                                            </button>
                                            -->
                      <button @click="downloadUnits()" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                      </button>

                    </div>


                  </div>


                  <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                    <button type="button"  data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive overflow-visible">

                    <table class="table align-middle table-hover  contacts-table table-striped " id="">
                      <thead class="table-light">
                      <tr class="table-dark">
                        <th>Stall No./Name</th>
                        <th>Stall Type</th>
                        <th scope="col">Tenant</th>
                        <th scope="col">Status</th>
                        <th>Last Payment Date</th>
                        <th>Next Date</th>
                        <th>Months in Arreas</th>
                        <th class="text-right">Monthly Rent</th>
                        <th class="text-right">Arrears</th>
                        <th class="text-right">Total</th>
                        <td></td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr :key="index" :value="item" v-for="(item, index) in units">
                        <th>{{item.unitNo}}</th>
                        <th>{{item.category}}</th>
                        <td><a href="javascript: void(0)">{{item.occupants}}</a></td>
                        <td>{{item.status}}</td>
                        <td>{{formatDate(item.lastPaymentDate)}}</td>
                        <td>{{formatDate(item.nextPaymentDate)}}</td>
                        <td>{{numberFormat(item.arrears)}}</td>
                        <th class="table-info text-right text-black">{{numberFormat(item.monthlyRent)}}</th>
                        <th class="table-danger text-right text-black">{{numberFormat(item.arrears)}}</th>
                        <th class="table-success text-right text-black">{{sumNumbers(item.monthlyRent,item.arrears)}}</th>
                        <td class="text-uppercase fw-bold text-right">

                          <div class="dropdown">
                            <button  role="button" data-bs-toggle="dropdown" aria-haspopup="true" class="btn btn-sm btn-warning text-black waves-effect btn-label waves-light text-uppercase"><i class="mdi mdi-arrow-down-drop-circle label-icon"></i> More Options</button>

                            <div class="dropdown-menu dropdown-menu-end">
                              <a @click="selectedUnit(item)" class="dropdown-item" href="javascript: void(0)" data-toggle="modal" data-target=".vaccate-modal"><i class="font-size-15 mdi mdi-eye-plus-outline me-3"></i>Vacate Unit</a>
                              <a @click="selectedUnit(item)" class="dropdown-item" href="javascript: void(0)" data-toggle="modal" data-target="#bill-client" ><i class="font-size-15 mdi mdi mdi-receipt me-3"></i>Bill Client</a>
                              <a @click="selectedUnit(item)" class="dropdown-item" href="javascript: void(0)"><i class="font-size-15 mdi mdi-microsoft-excel me-3"></i>Statements</a>
                              <a v-if="getRight('EDIT HOUSE RENT')" @click="selectedUnit(item)" class="dropdown-item" href="javascript: void(0)" data-toggle="modal" data-target=".edit-modal"><i class="font-size-15 mdi mdi-file-document-edit me-3"></i>Edit</a>
                              <a @click="selectedUnit(item)" class="dropdown-item" href="javascript: void(0)" data-toggle="modal" data-target=".send-sms-modal"><i class="font-size-15 mdi mdi-chat-processing me-3"></i>Tenancy History</a>

                            </div>
                          </div>


                          <!-- <button class="btn btn-danger btn-sm text-uppercase" data-toggle="modal" data-target=".vaccate-modal">Vacate Stall</button>
                          <button class="btn btn-info btn-sm text-uppercase" data-toggle="modal" data-target=".edit-modal">Edit</button>
                          <button class="btn btn-info btn-sm text-uppercase" data-toggle="modal" data-target=".edit-modal">Receive Payment</button>
                          <a href="housing-marlet-details-rental-statements.html" class="btn btn-primary btn-sm text-uppercase" >Statements</a>
                          <a href="housing-market-details-history.html" class="btn btn-dark btn-sm text-uppercase" >Tenancy History</a> -->
                        </td>
                      </tr>
                      </tbody>
                      <tfoot class="table-dark">
                      <tr>
                        <th colspan="1">
                          {{numberFormat(units.length)}} Units
                        </th>
                        <th colspan="4">

                        </th>

                        <th></th>
                        <th></th>
                        <th class="text-right text-info text-uppercase">{{numberFormat(totalMonthyly)}}</th>

                        <th class="text-right text-danger text-uppercase">{{numberFormat(totalArreas)}}</th>


                        <th class="text-right text-success text-uppercase">{{numberFormat(totalAmount)}}</th>
                        <th></th>


                      </tr>

                      </tfoot>
                    </table>

                    <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                      <div class="p-5 py-0 pt-3">
                        <div class="p-5">
                          <div class="spinner-border text-info m-1" role="status">
                            <span class="sr-only text-uppercase">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <h4 class="text-uppercase">Loading Data</h4>
                      <div class="col-sm-12 col-md-8 col-lg-5">
                        <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                      </div>
                    </div>
                    <div v-if="units.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                      <div class="p-5 py-0 pt-3">
                        <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                      </div>
                      <h4>No Results To Show</h4>
                      <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                    </div>

                  </div>

                </div>

                <div class="card-footer bg-white">
                  <nav aria-label="Page navigation example "
                       class="d-flex align-items-center justify-content-between">

                    <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                      <select class="form-select w-auto font-13px " v-model="pageSize">
                        <option value="10">10 Rows</option>
                        <option value="25">25 Rows</option>
                        <option value="50">50 Rows</option>
                        <option value="100">100 Rows</option>
                        <option value="150">150 Rows</option>
                        <option value="200">200 Rows</option>
                        <option value="5000">500 Rows</option>
                        <option value="10000">10000 Rows</option>
                        <option value="20000">20000 Rows</option>
                        <option value="10000000000">All Rows</option>
                      </select>
                    </div>

                    <ul class="pagination">
                      <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                      <li class="page-item" :class="{ disabled: currentPage === 1 }">
                        <a class="page-link" tabindex="-1" @click="prevPage" :disabled="currentPage === 1">Previous</a>
                      </li>
                      <!-- replace the v-for directive with the pagination function call -->
                      <li v-for="page in pagination(currentPage, totalPages, 3)" :key="page" class="page-item" :class="{ active: currentPage === page }">
                        <!-- check if the page is an ellipsis and display it as plain text -->
                        <span v-if="page === '...'" class="page-link">{{ page }}</span>
                        <!-- otherwise, display it as a clickable link -->
                        <a v-else class="page-link" @click="goToPage(page)">{{ page }}</a>
                      </li>
                      <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                        <a class="page-link"  @click="nextPage" :disabled="currentPage === totalPages">Next</a>
                      </li>
                    </ul>

                  </nav>
                </div>
              </div>
            </div>
            <!-- end col -->
          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <!-- receipting modal -->
      <div class="modal fade download-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Download Report</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <address>
                    <strong class="text-capitalize">Report Title</strong><br>
                    Revenue Collections By Subcounties in Homabay County
                  </address>
                </div>

                <div class="col-12">
                  <label><strong>Report Date Range</strong></label>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-xl-6 col-sm-6">
                      <div class="mb-3">
                        <label class="form-label">Date From</label>
                        <input class="form-control" placeholder="Start date" type="date">
                      </div>
                    </div>

                    <div class="col-xl-6 col-sm-6">
                      <div class="mb-3">
                        <label class="form-label">Date To</label>
                        <input class="form-control" placeholder="Start date" type="date">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <label><strong>Select File Type</strong></label>
                  <select class="form-select">
                    <option>Select Document Type</option>
                    <option>PDF file</option>
                    <option>Excel File</option>
                  </select>
                </div>

                <div class="col-12">
                  <button  type="button" class="btn btn-primary dropdown-toggle option-selector text-capitalize w-100 mt-5">
                    <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                  </button>
                </div>

              </div>

            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of receipting modal -->

      <!-- vaccating modal -->
      <div class="modal fade vaccate-modal" id="new-business" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">{{this.unit.unitNo +' '+ this.unit.category  +' '+ this.unit.occupants  +' '+ this.unit.phoneNumber  +' '+ this.unit.property}}</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body steps-container">
              <div class="row the-step biz-info active">
                <div class="col-12 mb-3">
                  <label for="">Reason for Vacating the stall</label>

                  <select class="form-select" v-model="reason" >

                    <option selected="" value="">Select Options</option>
                    <option v-for="(item, index) in vacateReasons" :key="`${index}`"><a class="dropdown-item" href="#">{{item.reason}}</a></option>

                  </select>

                </div>

                <div class="col-12 mb-3">
                  <label for="">Effective from</label>
                  <input v-model="effectiveFrom" class="form-control" type="date" placeholder="Select date" id="example-date-input">
                </div>

                <div class="col-12 mb-3">
                  <label for="">More Details</label>
                  <textarea v-model="description" rows="" cols="" class="form-control" placeholder="Start typing ..."></textarea>
                </div>
              </div>

              <div class="row the-step biz-additional-info d-none">
                <div class="col-12">
                  <div class="alert alert-warning" role="alert">
                    You are changing something that needs two OTPs, which I sent to the first and second approver. You need to get the OTPs from them and enter them in order, because if you enter them wrong, you can't continue. You also need to enter them exactly as they are, without any spaces or punctuation marks. Thank you for your cooperation.
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label for="">First Approver</label>
                  <input v-model="otpMessage1" class="form-control " placeholder="Enter OTP">
                  <p style="color: red">{{message1}}</p>
                </div>

                <div class="col-12 mb-3">
                  <label for="">Second Approver</label>
                  <input v-model="otpMessage2" class="form-control " placeholder="Enter OTP">
                  <p style="color: red">{{message2}}</p>
                </div>

              </div>


            </div>
            <div class="modal-footer">
              <div class="d-flex modal-next-prev-container">
                <button type="button" class="btn btn-outline-secondary btn-prev waves-effect waves-effect waves-light me-3">
                  <i class="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i> Previous
                </button>

                <button @click="sendVacateOTP()" type="button" class="btn btn-primary waves-effect waves-effect waves-light btn-next">
                  Next <i class="bx bx-right-arrow-alt font-size-16 align-middle me-2"></i>
                </button>

                <button data-dismiss="modal"  @click="vacateUnit()" type="button" class="btn btn-submit btn-success d-none ml-3 ms-3 waves-effect waves-effect waves-light btn-next">
                  <i class="bx bx-check-double font-size-16 align-middle me-2"></i> Finish
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of vacating modal -->


      <!-- billing on the spot -->
      <div class="modal fade" id="bill-client" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
                </button>
              </div>

              <div class="payment-panel-parent">

                <div class="payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">
                  <div class="success-image mb-4 pb-4 pt-4">
                    <img src="assets/images/bills.svg" height="100" alt="">
                  </div>


                  <h4 class="text-black fw-bold">Create Bill</h4>


                  <div class="border-1 invoice-form p-4">

                    <div class="form-group mb-4">

                      <div class="row">
                        <div class="col-6">
                          <label for="" class="text-uppercase">Unit Number:</label>
                        </div>
                        <div class="col-6">
                          <p class="text-black fw-bold">{{ unit.unitNo }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="" class="text-uppercase">Onwer:</label>
                        </div>
                        <div class="col-6">
                          <p class="text-black fw-bold">{{ unit.occupants }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="" class="text-uppercase">Monthly Rent:</label>
                        </div>
                        <div class="col-6">
                          <p class="text-black fw-bold">{{ unit.monthlyRent }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="" class="text-uppercase">Arreas:</label>
                        </div>
                        <div class="col-6">
                          <p class="text-black fw-bold">KES {{ unit.arrears }}</p>
                        </div>
                      </div>
                    </div>

                    <p class="text-muted mb-4">Please input the desired payment amount based on the outstanding arreas or what the client wishes to pay</p>

                    <div class="col-12">

                      <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Income Type</label>

                        <select @change="selectedIncomeType($event)"  class="form-control show-tick" data-live-search="true" >
                          <option v-for="(item, index) in billingModule.incomeTypes" :value="JSON.stringify(item)" :key="index">
                            {{ item.incomeTypeDescription }}
                          </option>
                        </select>

                      </div>

                    </div>
                    <div class="col-12">
                      <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Fee type</label>
                        <select @change="selectedFeeAndCharge($event)" class="form-control bg-white" title="Select Traffic offence" data-live-search="true" >
                          <option v-for="(item, index) in feesAndCharges" :value="JSON.stringify(item)" :key="index">
                            {{ item.feeDescription }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="row">
                        <div class="col-12">
                          <label for="formrow-quantity-input" class="form-label">Amount to Invoice</label>
                          <input v-model="amount" type="text" class="form-control" id="formrow-quantity-input" placeholder="Amount">
                        </div>
                        <!--                        <div class="col-6" d-done >
                                                  <label for="formrow-amount-input" class="form-label">Amount</label>
                                                  <input v-model="amount" type="text" class="form-control" id="formrow-amount-input" placeholder="Bill Item amount" readonly>
                                                </div>-->
                      </div>
                    </div>
                  </div>


                  <div class="d-flex w-100 pb-2" style="margin-top: 20px">

                    <button @click="generateBill()" data-toggle="modal" data-target="#payment-modal" data-dismiss="modal"  type="button" class="btn btn-primary btn-block  me-3 flex-grow-1 print-bill-btn">
                      <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                      Create Invoice
                    </button>


                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- billing on the spot -->

      <!-- payment modal -->
      <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                </button>
              </div>

              <div>

                <div v-if="paymentPanel" class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4 pb-4 pt-4">
                    <img src="assets/images/bills.svg" height="200" alt="">
                  </div>
                  <h4 class="text-black fw-bold">{{message}}</h4>
                  <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
                  <div class="d-flex w-100 pb-2">
                    <button @click="showPaymentMethod()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                      <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                      Receive Payment
                    </button>
                    <a @click="printBill()" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                  </div>
                </div>

                <div v-if="paymentMethod" class="send-method payment-panel ">
                  <label for="" class="mb-0 pb-0">Payment Method</label>
                  <p><small class="text-muted">How would you like to send this money?</small></p>

                  <div>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                              <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div>
                              <p>
                                A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to the MPESA number you enter below.
                              </p>
                            </div>
                            <div class="form-group">
                              <label for="">MPESA Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Phone No.</label>
                              </div>

                              <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                Send Payment Request
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                              <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>

                            </div>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div class="form-group">
                              <label for="">Enter The Bank's Receipt Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Bank Ref No.</label>
                              </div>

                              <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div v-if="transactionSummary" class="transaction-summary payment-panel">
                  <label for="">Transaction Breakdown</label>
                  <div class="border p-4 rounded ">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="text-muted ">
                          BillO NO.
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billDetails.billNo}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Bill For
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billDetails.incomeTypeDescription}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Payment Method
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{paymentData.paymentMode}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Transaction Amount
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          KES {{billDetails.billBalance}}
                        </div>
                      </div>


                      <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                      <div class="col-lg-6 text-uppercase">
                        <div class="text-muted mt-2 font-21">
                          Total
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end text-uppercase">
                        <div class=" text-right text-black font-21 fw-bold">
                          KES {{billDetails.billBalance}}
                        </div>
                      </div>
                    </div>

                    <div class="mpesa-confirmation">
                      <div v-if="paymentData.paymentMode === 'MPESA'">
                        <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ phoneNumber }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                          <br>
                          <br>
                          Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                      </div>

                      <div>{{message}}  </div>
                      <br>
                      <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="stk-timer-container d-none justify-content-center align-items-center">
                            <span class="mdi mdi-timer-outline font-16px"></span>
                            <span class="stk-timer px-2"></span>
                          </div>
                          <div class="justify-content-center align-items-center d-flex">
                            <span class="px-2">Send Request</span>
                            <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                          </div>
                        </div>
                      </button>
                    </div>


                  </div>
                </div>

                <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4">
                    <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                  </div>
                  <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                  <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
                  <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                    <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                    Print Receipt
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of payment modal -->



      <!-- add plot modal -->
      <div class="modal fade add-plot-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Add New Unit</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Plot Details:</p>
                </div>


                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Unit Number</label>
                    <input type="text" class="form-control" placeholder="Enter the Plot Number">
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Unit Type</label>
                    <select class="form-select  font-13px  form-control">

                      <option value="25">Resturant</option>
                      <option value="50">Single Stall</option>

                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Monthly Rent</label>
                    <input type="text" class="form-control" placeholder="Enter the Plot Number">
                  </div>
                </div>



              </div>

            </div>
            <div class="modal-footer">

              <a href="#" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light w-100">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of add plot modal -->

      <!-- adsigning new tenan modal -->
      <div class="modal fade new-tenant-modal" id="new-business" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">129/4775/009 New tenant</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body steps-container">
              <div class="row the-step biz-info active">

                <div class="col-12 mb-3">
                  <label for="">Tenat's Full Names</label>
                  <input type="text" class="form-control" placeholder="Enter Full Names">
                </div>

                <div class="col-12 mb-3">
                  <label for="">ID/PPT Number</label>
                  <input type="text" class="form-control" placeholder="Enter ID/PPT Number">
                </div>

                <div class="col-12 mb-3">
                  <label for="">Phone Number</label>
                  <input type="text" class="form-control" placeholder="Enter Phone">
                </div>

                <div class="col-12 mb-3">
                  <label for="">Email Address</label>
                  <input type="text" class="form-control" placeholder="Enter Phone">
                </div>


                <div class="col-12 mb-3">
                  <label for="">Effective from</label>
                  <input class="form-control" type="date" placeholder="Select date" id="example-date-input">
                </div>


              </div>

              <div class="row the-step biz-additional-info d-none">
                <div class="col-12">
                  <div class="alert alert-warning" role="alert">
                    You are changing something that needs two OTPs, which I sent to the first and second approver. You need to get the OTPs from them and enter them in order, because if you enter them wrong, you can't continue. You also need to enter them exactly as they are, without any spaces or punctuation marks. Thank you for your cooperation.
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label for="">First Approver</label>
                  <input class="form-control " placeholder="Enter OTP">
                </div>

                <div class="col-12 mb-3">
                  <label for="">Second Approver</label>
                  <input class="form-control " placeholder="Enter OTP">
                </div>

              </div>


            </div>
            <div class="modal-footer">
              <div class="d-flex modal-next-prev-container">
                <button type="button" class="btn btn-outline-secondary btn-prev waves-effect waves-effect waves-light me-3">
                  <i class="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i> Previouse
                </button>

                <button type="button" class="btn btn-primary waves-effect waves-effect waves-light btn-next">
                  Next <i class="bx bx-right-arrow-alt font-size-16 align-middle me-2"></i>
                </button>

                <button type="button" class="btn btn-submit btn-success d-none ml-3 ms-3 waves-effect waves-effect waves-light btn-next">
                  <i class="bx bx-check-double font-size-16 align-middle me-2"></i> Finish
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- assigning new tenant modal -->

      <!-- editing modal -->
      <div class="modal fade edit-modal" id="new-business" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">Edit stall detais for Stall {{unit.unitNo}}</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body steps-container">
              <div class="row the-step biz-info active">
                <div class="col-12 mb-3">
                  <label for="">Stall Number</label>
                  <input readonly v-model="unit.unitNo" type="text" class="form-control" placeholder="Stall Name">
                </div>

                <div class="col-12 mb-3">
                  <label for="">Stall Type</label>
                  <select class="form-select"  v-model="unitCategory">
                    <option selected="" value="">Select Category</option>
                    <option v-for="(item, index) in unitCategories" :key="`${index}`" :value="item" ><a class="dropdown-item" href="#">{{item.category}}</a></option>
                  </select>

                </div>

<!--                <div class="col-12 mb-3">
                  <label for="">Chargable per</label>
                  <select class="form-select">
                    <option>Daily</option>
                    <option>Weekly</option>
                    <option value="">Monthly</option>
                  </select>
                </div>-->

                <div class="col-12 mb-3">
                  <label for="">Amount Chargable</label>
                  <input v-model="unit.monthlyRent" type="text" class="form-control" placeholder="Enter amount">
                </div>



                <div class="col-12 mb-3">
                  <label for="">Occupants</label>
                  <input v-model="unit.occupants" type="text" class="form-control" placeholder="Occupants">
                </div>



                <div class="col-12 mb-3">
                  <label for="">Occupants</label>
                  <input v-model="unit.phoneNumber" type="text" class="form-control" placeholder="phoneNumber">
                </div>


                <div class="col-12 mb-3">
                  <label for="">idNo</label>
                  <input v-model="unit.idNo" type="text" class="form-control" placeholder="idNo">
                </div>

                <a @click="updateUnits()" type="button" class="btn btn-success text-uppercase dropdown-toggle option-selector me-3">
                  <i class="bx bx-plus font-size-16"></i> <span class="pl-1 d-md-inline">Save</span>
                </a>


              </div>

<!--              <div class="row the-step biz-additional-info d-none">
                <div class="col-12">
                  <div class="alert alert-warning" role="alert">
                    You are changing something that needs two OTPs, which I sent to the first and second approver. You need to get the OTPs from them and enter them in order, because if you enter them wrong, you can't continue. You also need to enter them exactly as they are, without any spaces or punctuation marks. Thank you for your cooperation.
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label for="">First Approver</label>
                  <input class="form-control " placeholder="Enter OTP">
                </div>

                <div class="col-12 mb-3">
                  <label for="">Second Approver</label>
                  <input class="form-control " placeholder="Enter OTP">
                </div>

              </div>-->


            </div>
<!--

            <div class="modal-footer">
              <div class="d-flex modal-next-prev-container">
                <button type="button" class="btn btn-outline-secondary btn-prev waves-effect waves-effect waves-light me-3">
                  <i class="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i> Previouse
                </button>

                <button type="button" class="btn btn-primary waves-effect waves-effect waves-light btn-next">
                  Next <i class="bx bx-right-arrow-alt font-size-16 align-middle me-2"></i>
                </button>

                <button type="button" class="btn btn-submit btn-success d-none ml-3 ms-3 waves-effect waves-effect waves-light btn-next">
                  <i class="bx bx-check-double font-size-16 align-middle me-2"></i> Finish
                </button>

              </div>
            </div>
-->

          </div>
        </div>
      </div>
      <!-- editing modal -->

      <!-- assigning tenant modal -->
      <div class="modal fade edit-modal" id="tenant-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">Assign tenant for Unit Number {{unit.unitNo}}</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body steps-container">
              <div class="row the-step biz-info active">
                <div class="col-12 mb-3">
                  <label for="">Tenant's Full Names</label>
                  <input type="text" class="form-control" placeholder="Enter Tenant's Full Names">
                </div>

                <div class="col-12 mb-3">
                  <label for="">Tenant's ID Number</label>
                  <input type="text" class="form-control" placeholder="Enter ID Number">
                </div>

                <div class="col-12 mb-3">
                  <label for="">Phone Number</label>
                  <input type="text" class="form-control" placeholder="Enter Phone Number">
                </div>

              </div>

              <div class="row the-step biz-additional-info d-none">
                <div class="col-12">
                  <div class="alert alert-warning" role="alert">
                    You are changing something that needs two OTPs, which I sent to the first and second approver. You need to get the OTPs from them and enter them in order, because if you enter them wrong, you can't continue. You also need to enter them exactly as they are, without any spaces or punctuation marks. Thank you for your cooperation.
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label for="">First Approver</label>
                  <input class="form-control " placeholder="Enter OTP">

                </div>

                <div class="col-12 mb-3">
                  <label for="">Second Approver</label>
                  <input class="form-control " placeholder="Enter OTP">
                </div>

              </div>


            </div>
            <div class="modal-footer">
              <div class="d-flex modal-next-prev-container">
                <button type="button" class="btn btn-outline-secondary btn-prev waves-effect waves-effect waves-light me-3">
                  <i class="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i> Previouse
                </button>

                <button type="button" class="btn btn-primary waves-effect waves-effect waves-light btn-next">
                  Next <i class="bx bx-right-arrow-alt font-size-16 align-middle me-2"></i>
                </button>

                <button type="button" class="btn btn-submit btn-success d-none ml-3 ms-3 waves-effect waves-effect waves-light btn-next">
                  <i class="bx bx-check-double font-size-16 align-middle me-2"></i> Finish
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of assigning tenant modal -->

      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">
            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">
                Homa Bay County Government
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay "></div>

  </body>

</template>

<script>
/* eslint-disable */
import {biller, execute, executeDownload, getRight, rent} from "@/api";
import NavigationBar from "@/components/Navigation.vue";
import router from "@/router";
import ToolBarHousing from "@/components/housing/ToolBarHousing.vue";

export default {
  name: "HousingDetails",
  components: {ToolBarHousing, NavigationBar},
  data() {
    return{
      amount:0,
      search:'',
      download:'Download Report',
      loading: true,
      // set the initial current page as 1
      currentPage: 1,
      // set the total number of pages as 10 (you can change this according to your data source)
      totalPages: 0,
      // set the number of items per page as 5 (you can change this according to your preference)
      pageSize: 10,
      summary: {
        count: 0,
        total: 0
      },

      category:'',
      unitStatus:'',
      unitCategories:[{
        id: null,
        category: null,
        count:null
      }],
      unitCategory: {
        id: null,
        category: null,
        count: null,
      },
      totalMonthyly:0,
      totalArreas:0,
      totalAmount:0,
      message:null,
      message1:null,
      message2:null,
      otpMessage1: null,
      otpMessage2: null,

      otp1:0,
      otp2:0,

      description:'',
      effectiveFrom:'',
      reason:'',
      units: [
        {
          id: null,
          propertyID:  null,
          property: null,
          unitNo: null,
          category:  null,
          occupants: null,
          monthlyRent:  null,
          arrears:  null,
          phoneNumber:  null,
          idNo: null,
          updated: null,
          lastPaymentDate: null,
          nextPaymentDate: null
        }
      ],
      unit:{
        id: null,
        propertyID:  null,
        property: null,
        unitNo: null,
        category:  null,
        occupants: null,
        monthlyRent:  null,
        arrears:  null,
        phoneNumber:  null,
        idNo: null,
        updated: null,
        lastPaymentDate: null,
        nextPaymentDate: null
      },
      vacateReasons: [
        {
          id: null,
          reason:  null,
        }],
      selectedUnits:[
        {
          id: null,
          propertyID:  null,
          property: null,
          unitNo: null,
          category:  null,
          occupants: null,
          monthlyRent:  null,
          arrears:  null,
          phoneNumber:  null,
          idNo: null,
          updated: null,
          lastPaymentDate: null,
          nextPaymentDate: null
        }

      ],

      billingModule:{
        incomeType:{
          incomeTypeId: null,
          incomeTypeDescription:null,
          incomeTypePrefix: null,
          fundDescription: null,
          status: null
        },
        incomeTypes:[{
          incomeTypeId: null,
          incomeTypeDescription:null,
          incomeTypePrefix: null,
          fundDescription: null,
          status: null}]
      },
      feesAndCharges:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null
      }],
      feesAndChargeItems:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        customer: null
      }],
      billItem:[],
      billDetails:{
        id:'',
        billNo:'',
        incomeTypeID:'',
        incomeTypeDescription:'',
        costCenterNo:'',
        accountNo:'',
        feeID:'',
        detailAmount:'',
        receiptAmount:'',
        billBalance:'',
        wardID:'',
        subCountyID:'',
        status:'',
        dateCreated:''
      },
      billInfo:[],
      paymentData: {
        bankReceiptNo: null,
        accNo: null,
        payBillNo: null,
        amount: null,
        phoneNumber: null,
        success: false,
        paymentMode:null,
        sessionNames:null,
        sessionIdNo:null,
        sessionPhoneNumber:null
      },
      alert:{
        success: false,
        info: false,
        failed:false
      },

      transactionCompleted: false,
      transactionSummary: false,
      paymentPanel: true,
      paymentMethod: false,

      unitStatuses: [{
        status:null,
        count: null
      }],
      property: {
        id:null,
        propertyID:null,
        property: null,
        subCountyName: null,
        subCountyID: null,
        wardName: null,
        wardID: null,
        zone: null
      }
    }
  },
  watch: {

    pageSize(newPageSize) {
      this.getUnits()
      // get the total number of items from your data source (you can change this according to your logic)
      const totalItems = this.summary.count;
      // calculate the new total pages by dividing the total items by the new page size and rounding up
      const newTotalPages = Math.ceil(totalItems / newPageSize);
      // update the totalPages data property with the new value
      this.totalPages = newTotalPages;
      // reset the current page to 1
      this.currentPage = 1;

    },

    otpMessage1: function () {
      if(this.otpMessage1.length>3){
        if(this.otpMessage1 == this.otp1){
          this.message1 =null
        }else {
          this.message1 ="Invalid OTP"
        }
      }else {
        this.message1 =null
      }

    } ,
    otpMessage2: function () {
      if(this.otpMessage2.length>3){
        if(this.otpMessage2 == this.otp2){
          this.message2 =null
        }else {
          this.message2 ="Invalid OTP"
        }
      }else {
        this.message2 =null
      }
    },

    category: function () {
      this.getUnits()
    },
    unitStatus: function () {
      this.getUnits()
    },
    search: function () {
      this.getUnits()
    },
  },
  mounted() {
    this.getProperty()
    this.getUnits()
    this.getVacateReasons()
    this.getIncomeTypes()
    this.getUnitStatus()
    this.menuAccess();


  },
  methods: {
    getRight,

    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Housing Details");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then((res) => {
            // alert(res.data.message);
          })
          .catch((e) => {
            // alert(e.message);
          });

    },
    //pagination code
    pagination(currentPage, totalPages, maxVisibleButtons) {
      //initialize an empty array for the pages
      let pages = [];
      //calculate the start and end indices of the visible buttons
      let start = currentPage - Math.floor(maxVisibleButtons / 2);
      let end = currentPage + Math.floor(maxVisibleButtons / 2);
      //adjust the start and end indices if they are out of bounds
      if (start < 1) {
        start = 1;
        end = start + maxVisibleButtons - 1;
        if (end > totalPages) {
          end = totalPages;
        }
      }
      if (end > totalPages) {
        end = totalPages;
        start = end - maxVisibleButtons + 1;
        if (start < 1) {
          start = 1;
        }
      }
      //loop through the start and end indices and push the pages to the array
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      //add an ellipsis at the beginning if there are more pages before the start index
      if (start > 1) {
        pages.unshift("...");
      }
      //add an ellipsis at the end if there are more pages after the end index
      if (end < totalPages) {
        pages.push("...");
      }
// return the array of pages
      return pages;
    },
    // define a method to go to the previous page
    prevPage() {
      // check if the current page is not the first page
      if (this.currentPage > 1) {
        // decrement the current page by 1
        this.currentPage--;
        this.getUnits()
      }
    },
    // define a method to go to the next page
    nextPage() {
      // check if the current page is not the last page
      if (this.currentPage < this.totalPages) {
        // increment the current page by 1
        this.currentPage++;
        this.getUnits()
      }
    },
    // define a method to go to a specific page
    goToPage(page) {
      // check if the page is valid and not equal to the current page
      if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
        // set the current page to the given page
        this.currentPage = page;
        this.getUnits()
      }
    },

    downloadUnits(){
      this.download ="Please wait.."

      let str = this.category
      let cleanedStr = str.replace(/\(\d+\)/, '').trim();

      let str2 = this.unitStatus
      let cleanedStr2 = str2.replace(/\(\d+\)/, '').trim();

      const data = new FormData();
      data.append("function", "downloadUnits");
      data.append("propertyID", sessionStorage.getItem("propertyID"));
      data.append("category", cleanedStr);
      data.append("unitStatus", cleanedStr2);

      executeDownload(data,rent).then(response => {
        this.download ="Download Report"
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = "Report.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getProperty(){
      const data = new FormData();
      data.append("function", "getProperty");
      data.append("propertyID", sessionStorage.getItem("propertyID"));
      execute(data,rent)
          .then((res) =>{
            if (res.data.success) {
              this.property = res.data.data.property;

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getUnitCategory(){
      this.unitCategories.splice(0)
      const data = new FormData();
      data.append("function", "getUnitCategory");
      execute(data,rent)
          .then((res) =>{
            if (res.data.success) {
              this.unitCategories =res.data.data.unitCategories

              //preselections
              this.unitCategories.forEach((value, index) => {
                if (this.unit.category === value.category) {
                   this.unitCategory = this.unitCategories[index];
                }
              });

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getUnitStatus(){
      this.unitStatuses.splice(0)
      const data = new FormData();
      data.append("function", "getUnitStatus");
      execute(data,rent)
          .then((res) =>{
            if (res.data.success) {
              this.unitStatuses =res.data.data.unitStatuses
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    sumNumbers(a, b) {
      // Convert strings to numbers if they are strings
      if (typeof a === 'string') {
        a = parseFloat(a);
      }
      if (typeof b === 'string') {
        b = parseFloat(b);
      }

      // Check if both parameters are valid numbers
      if (isNaN(a) || isNaN(b)) {
        return "Both parameters must be valid numbers";
      }

      return a + b;
    },
    numberFormat(num) {
      const britishNumberFormatter = new Intl.NumberFormat("en-GB");
      return britishNumberFormatter.format(num)
    },
    selectedUnit(item){
      this.unit = item

      this.selectedUnits.splice(0)
      this.selectedUnits.push(item)
      this.amount = this.unit.monthlyRent

      this.getUnitCategory()

    },
    formatDate(dateStr) {
      if (!dateStr) return "Invalid Date";

      const date = new Date(dateStr);

      if (isNaN(date.getTime())) return "Invalid Date";

      const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      };

      const formattedDate = date.toLocaleDateString('en-US', options);
      const formattedTime = date.toLocaleTimeString('en-US', options);

      return formattedDate.replace(',', '') + ' ' + formattedTime;
    },
    getUnits(){
      this.loading = true
      this.units.splice(0)

      let str = this.category
      let cleanedStr = str.replace(/\(\d+\)/, '').trim();

      let str2 = this.unitStatus
      let cleanedStr2 = str2.replace(/\(\d+\)/, '').trim();

      const data = new FormData();
      data.append("function", "getUnits");
      data.append("propertyID", sessionStorage.getItem("propertyID"));
      data.append("category", cleanedStr);
      data.append("unitStatus", cleanedStr2);
      data.append("keyword", this.search);
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);

      execute(data,rent)
          .then((res) =>{
            this.loading = false
            if (res.data.success) {
              this.units = res.data.data.units
              this.totalMonthyly = this.units.reduce((acc, cur) => {
                let receiptAmount = Number(cur.monthlyRent);
                return acc + receiptAmount;
              }, 0);

              this.totalArreas = this.units.reduce((acc, cur) => {
                let receiptAmount = Number(cur.arrears);
                return acc + receiptAmount;
              }, 0);

              this.totalAmount = this.totalMonthyly + this.totalArreas

              this.summary.count = res.data.data.summary.count
            //  this.summary.total = res.data.data.summary.totalReceiptAmount
              this.totalPages =  Math.ceil(this.summary.count / this.pageSize);

            }else{

            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    getVacateReasons(){
      const data = new FormData();
      data.append("function", "getVacateReasons");
      execute(data,rent)
          .then((res) =>{
            if (res.data.success) {
              this.vacateReasons = res.data.data.vacateReasons
            }else{

            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    updateUnits(){
      const data = new FormData();
      data.append("function", "updateUnits");
      data.append("unit", JSON.stringify(this.unit));
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo", sessionStorage.getItem("sessionIdNo"));

      execute(data,rent)
          .then((res) =>{
            if (res.data.success) {
             this.getProperty()
            }else{

            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    vacateUnit(){
      //check if OTP Matches
      if(this.otpMessage1 == this.otp1 && this.otpMessage2 == this.otp2){

        const data = new FormData();
        data.append("function", "vacateUnit");
        data.append("effectiveFrom", this.effectiveFrom);
        data.append("id", this.unit.id);
        execute(data,rent)
            .then((res) =>{
              if (res.data.success) {
                alert(res.data.message)
                this.getUnits();
              }else{
                alert(res.data.message)
              }
            })
            .catch((e) => {
              //alert(e.message);
              this.message =e.message
            });

      }else {

        alert("One or more invalid OTP")
      }

    },
    sendVacateOTP(){

      this.otp1 =  Math.floor(1000 + Math.random() * 9000);
      this.otp2 =  Math.floor(1000 + Math.random() * 9000);

      const data = new FormData();
      data.append("function", "sendVacateOTP");
      data.append("description", this.unit.unitNo +' '+ this.unit.category  +' '+ this.unit.occupants  +' '+ this.unit.phoneNumber  +' '+ this.unit.property);
      data.append("otp1", this.otp1);
      data.append("otp2", this.otp2);
      execute(data,rent)
          .then((res) =>{
            if (res.data.success) {

            }else{

            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },

    selectedIncomeType(event){
      const value = event.target.value
      const result = JSON.parse(value);
      this.getFeesAndChargesBilling(result.incomeTypeId)
    },
    getIncomeTypes(){
      this.message =null
      this.billingModule.incomeTypes.splice(0)
      this.feesAndCharges.splice(0)
      const data = new FormData();
      data.append("function", "getIncomeTypes");
      data.append("incomeTypePrefix", "HSRENT");
      data.append("keyword", "Housing");
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.billingModule.incomeTypes =res.data.data.incomeTypes
              this.getFeesAndChargesBilling(this.billingModule.incomeTypes[0].incomeTypeId)
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getFeesAndChargesBilling(incomeTypeId){
      this.message =null
      this.feesAndCharges.splice(0)
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId",incomeTypeId);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.feesAndCharges = res.data.data.feesAndCharges
              this.selectedFeeAndChargeFirstOne()
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    selectedFeeAndCharge(event){
      const data = JSON.parse(event.target.value)
      // this.amount = data.unitFeeAmount
      this.feeID = data.feeId
      this.unitFeeAmount = data.unitFeeAmount
      this.feesAndChargeItems = []
      this.feesAndChargeItems.splice(0)
      this.feesAndChargeItems.push(data)

    },
    selectedFeeAndChargeFirstOne(){
      const data = this.feesAndCharges[0]
      // this.amount = data.unitFeeAmount
      this.feeID = data.feeId
      this.unitFeeAmount = data.unitFeeAmount
      this.feesAndChargeItems = []
      this.feesAndChargeItems.splice(0)
      this.feesAndChargeItems.push(data)

    },
    generateFeeAndChargesItem() {
      // Loop through the selectedVehicles array
      for (let item of this.selectedUnits) {
        // Create a new object with the mapped values
        let newItem = {
          feeId: this.feeID, // Map feeID to feeId
          unitFeeAmount: this.amount, // Map amount to unitFeeAmount
          customer: item.unitNo+"|"+item.idNo+"|"+item.id, //unitNo | idNo | id
        };
        // Push the new object to the feesAndChargeItems array
        this.feesAndChargeItems.splice(0)
        this.feesAndChargeItems.push(newItem);
      }
      this.addToBill()
    },
    addToBill(){
      for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer} of this.feesAndChargeItems) {
        this.totalAmount += parseFloat(unitFeeAmount);
        this.billItem.push({
          typeDescription,
          feeDescription,
          unitFeeAmount: unitFeeAmount,
          accountNo,
          costCenterNo,
          incomeTypeId,
          revenueStreamItem: this.unit.unitNo,
          feeId,
          amount:  unitFeeAmount,
          customer: customer,
          zone: this.unit.zone,
          subCountyID: this.unit.subCountyID,
          subCountyName:this.unit.subCountyName,
          wardID: this.unit.wardID,
          wardName: this.unit.wardName,
          idNo: sessionStorage.getItem("sessionIdNo"),
          phoneNumber: sessionStorage.getItem("sessionPhoneNumber"),
          names: sessionStorage.getItem("sessionNames"),
          customerPhoneNumber: this.unit.phoneNumber,
          description:  this.unit.unitNo,
        });
      }
    },
    generateBill(){
      this.generateFeeAndChargesItem()

      this.paymentMethod = false;
      this.paymentPanel = true;
      this.transactionSummary = false ;
      this.transactionCompleted = false
      this.message ="Generating Bill...please wait... "
      const data = new FormData();
      data.append("function", "generateBill3");
      data.append("billItem", JSON.stringify(this.billItem));
      execute(data,biller)
          .then((res) =>{
            this.message =res.data.message
            if (res.data.success) {
              this.billDetails = res.data.data.bill.billDetails
              this.billInfo = res.data.data.bill.billInfo
              this.paymentData.amount = res.data.data.bill.billDetails.billBalance
              this.paymentData.accNo = res.data.data.bill.billDetails.billNo
              this.paymentData.payBillNo = res.data.data.billGenerated.payBillNo

              this.paymentData.sessionNames =sessionStorage.getItem("sessionNames")
              this.paymentData.sessionIdNo =sessionStorage.getItem("sessionIdNo")
              this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")



            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    printBill() {
      localStorage['params'] = JSON.stringify({
        billNo: this.billDetails.billNo
      })
      const routeData = this.$router.resolve({name: 'bill'});
      window.open(routeData.href, '_blank');
    },
    printReceipt() {
      if (this.receiptDetails.receiptNo == null) {
        alert("Try again later");
      } else {
        //  this.$refs.closeReceived.click();
        localStorage['params'] = JSON.stringify({
          receiptNo: this.receiptDetails.receiptNo
        })
        const routeData = this.$router.resolve({name: 'receipt'});
        window.open(routeData.href, '_blank');
      }
    },
    showPaymentMethod(){
      this.paymentMethod = true;
      this.paymentPanel = false;
      this.transactionSummary = false;
      this.transactionCompleted = false
    },
    showTransactionSummary(paymentMode){

      this.paymentData.paymentMode = paymentMode

      if(paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }
      if(paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }

      this.paymentMethod = false;
      this.paymentPanel = false;
      this.transactionSummary = true;
      this.transactionCompleted = false
    },
    makePayment() {
      if(this.paymentData.paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }
      if(this.paymentData.paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }
      this.message = "Payment Request sent";
      this.paymentData.phoneNumber = this.phoneNumber

      const data = new FormData();
      data.append("function", "makePayment");
      data.append("paymentData", JSON.stringify(this.paymentData));


      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.paymentMethod = false;
              this.paymentPanel = false;
              //start checking payment
              this.timer = setInterval(() => {

                this.checkPayment();
              }, 3000);
            } else {
              this.rate = null;
              alert(res.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    checkPayment() {
      this.randomNumber = Math.floor(Math.random() * 3) + 1
      if(this.randomNumber === 1){
        this.message = "Waiting for payment.";
      }
      if(this.randomNumber === 2){
        this.message = "Waiting for payment..";
      }
      if(this.randomNumber ===3){
        this.message = "Waiting for payment...";
      }
      const data = new FormData();
      data.append("function", "checkPayment");
      data.append("accountRef", this.paymentData.accNo);

      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.message = null;
              clearInterval(this.timer);
              this.receipt = true;
              this.paymentData.success = false;
              this.transaction = res.data.data.transaction;
              this.receiptDetails = res.data.data.receipt.receiptDetails
              //Close the modal
              this.transactionSummary = false
              this.transactionCompleted = true
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'KES',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },
    gotTo(route){
      router.push(route)
    },

  }
}

</script>

