<template>

  <header id="page-topbar">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a class="logo logo-dark" >
                            <span class="logo-sm">
                                    <img alt="" height="22" :src="'./assets/images/logo.svg'">
                                </span>

            <span class="logo-lg">
<!--                                    <img alt="" height="17" src="/assets/images/logo-dark.png">-->
                                </span>
          </a>

          <a class="logo logo-light d-flex">
            <span class="logo-sm">
                <img alt="" height="50" :src="county.logo">
            </span>
            <span class="logo-lg">
                <img alt="" height="45" :src="county.logo">
            </span>

            <div class="portal-title">
              <h4 class="text-white">County Invoice</h4>
              <h6 class="text-muted mb-0 text-white opacity-75"> County</h6>
            </div>


          </a>
        </div>

        <button onclick="toggleVerticalMenu()" id="vertical-menu-btn" class="btn btn-sm px-3 font-size-16 header-item waves-effect" type="button">
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <!-- logo small -->
        <div class="phone-logo d-md-none d-sm-flex"><img alt="" class="img" :src="county.logo"></div>


        <!-- App Search-->
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input class="form-control" placeholder="Search..." type="text">
            <span class="bx bx-search-alt"></span>
          </div>
        </form>

      </div>

      <div class="d-flex">
        <!-- small search -->
        <div class="dropdown d-inline-block d-lg-none ms-2">
          <button id="page-header-search-dropdown" aria-expanded="false" aria-haspopup="true"
                  class="btn header-item noti-icon waves-effect" data-bs-toggle="dropdown" type="button">
            <i class="mdi mdi-magnify"></i>
          </button>
          <div aria-labelledby="page-header-search-dropdown"
               class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">

            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input aria-label="Recipient's username" class="form-control" placeholder="Search ..." type="text">
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button class="btn header-item noti-icon waves-effect" data-bs-toggle="fullscreen" type="button">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <div class="dropdown d-inline-block d-none">

          <!-- notifications, i will come to this later -->
          <button id="page-header-notifications-dropdown" aria-expanded="false" aria-haspopup="true"
                  class="btn header-item noti-icon waves-effect" data-bs-toggle="dropdown" type="button">
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge bg-danger rounded-pill">3</span>
          </button>
          <div aria-labelledby="page-header-notifications-dropdown"
               class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 key="t-notifications" class="m-0"> Notifications </h6>
                </div>
                <div class="col-auto">
                  <a key="t-view-all" class="small" href="#!"> View All</a>
                </div>
              </div>
            </div>
            <div data-simplebar style="max-height: 230px;">
              <a class="text-reset notification-item" href="javascript: void(0);">
                <div class="d-flex">
                  <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-primary rounded-circle font-size-16">
                                                    <i class="bx bx-cart"></i>
                                                </span>
                  </div>
                  <div class="flex-grow-1">
                    <h6 key="t-your-order" class="mb-1">Your order is placed</h6>
                    <div class="font-size-12 text-muted">
                      <p key="t-grammer" class="mb-1">If several languages coalesce the grammar</p>
                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                    </div>
                  </div>
                </div>
              </a>
              <a class="text-reset notification-item" href="javascript: void(0);">
                <div class="d-flex">
                  <img alt="user-pic" class="me-3 rounded-circle avatar-xs" :src="'./assets/images/users/avatar-3.jpg'">
                  <div class="flex-grow-1">
                    <h6 class="mb-1">James Lemire</h6>
                    <div class="font-size-12 text-muted">
                      <p key="t-simplified" class="mb-1">It will seem like simplified English.</p>
                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>

              <a class="text-reset notification-item" href="javascript: void(0);">
                <div class="d-flex">
                  <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-success rounded-circle font-size-16">
                                                    <i class="bx bx-badge-check"></i>
                                                </span>
                  </div>
                  <div class="flex-grow-1">
                    <h6 key="t-shipped" class="mb-1">Your item is shipped</h6>
                    <div class="font-size-12 text-muted">
                      <p key="t-grammer" class="mb-1">If several languages coalesce the grammar</p>
                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                    </div>
                  </div>
                </div>
              </a>

              <a class="text-reset notification-item" href="javascript: void(0);">
                <div class="d-flex">
                  <img alt="user-pic" class="me-3 rounded-circle avatar-xs" :src="'./assets/images/users/avatar-4.jpg'">
                  <div class="flex-grow-1">
                    <h6 class="mb-1">Salena Layfield</h6>
                    <div class="font-size-12 text-muted">
                      <p key="t-occidental" class="mb-1">As a skeptical Cambridge friend of mine occidental.</p>
                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="p-2 border-top d-grid">
              <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View More..</span>
              </a>
            </div>
          </div>
        </div>


        <div class="dropdown d-inline-block">

          <div class="dropdown d-inline-block">
            <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="rounded-circle header-profile-user" :src="county.logo"
                   alt="Header Avatar">
              <span class="d-none d-xl-inline-block ms-1" key="t-henry">{{sessionNames}}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a @click="gotTo('change-password')" class="dropdown-item" href="javascript: void(0);"><i
                  class="bx bx-lock-open font-size-16 align-middle me-1"></i> <span
                  key="t-lock-screen">Change Password</span></a>
              <div class="dropdown-divider"></div>

              <a @click="logout('/')" class="dropdown-item text-danger" href="javascript: void(0);"><i
                  class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></a>
            </div>


          </div>

<!--
          <button id="page-header-user-dropdown" aria-expanded="false" aria-haspopup="true" class="btn header-item waves-effect" data-bs-toggle="dropdown" type="button">
            <img alt="Header Avatar" class="rounded-circle header-profile-user"
                 :src="'./logos/county_logo.png'"><span @click="logout('/')" key="t-henry" class="d-none d-xl-inline-block ms-1">Logout</span><i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
-->


        </div>
      </div>
    </div>
  </header>
  <div id="vertical-menu" class="vertical-menu" >

    <div class="h-100" data-simplebar>

      <!--- Sidemenu -->
      <div id="sidebar-menu" >
        <!-- Left Menu Start -->
        <ul id="side-menu" class="metismenu list-unstyled text-capitalize">
          <li key="t-pages" class="menu-title d-none">Best Birdy Billing system.</li>

          <li v-if="getRight('BILLS')" class="side-bar-button">
            <a @click="gotToSelected('new-bill')" class="waves-effect btn btn-light btn-rounded text-left write-msg-btn">
              <i class=""><img alt="" src="assets/images/plus-icon.svg"></i>
              <span class="text-capitalize">Create Invoice</span>
            </a>
          </li>

          <li  v-if="getRight('DASHBOARD')" :class="{ 'mm-active': selected === 'dashboard' }">
            <a @click="gotToSelected('dashboard')" class="waves-effect">
              <i class="bx bx-home-circle"></i><span>Home</span>
            </a>

<!--
            <a @click="gotToSelected('dashboard2')" class="waves-effect">
              <i class="bx bx-home-circle"></i><span>Home2</span>
            </a>
-->

          </li>

           <li  v-if="getRight('LIVE MONITORING')">
             <a href="javascript: void(0);" @click="gotToSelected('live_monitoring')" class="waves-effect">
               <i class="bx bx-user-voice"></i><span>Performance</span>
             </a>
           </li>

          <li v-if="getRight('PARKING')" :class="{ 'mm-active': selected === 'parking' }">

            <a @click="selectedMenu('parking')" class="waves-effect has-arrow">
              <i class="mdi mdi-car-back"></i>
              <span>Parking</span>
            </a>

            <ul :class="{ 'mm-active': selected === 'parking' }" class="sub-menu" aria-expanded="false">

              <li v-if="getRight('PARKING')" :class="{ 'mm-active': selectedSub === 'parking_dashboard' }" ><a href="javascript: void(0);" @click="gotTo('parking_dashboard')">Overview</a></li>
              <li v-if="getRight('SEASONAL PARKING')" :class="{ 'mm-active': selectedSub === 'parking-fleet' }" ><a href="javascript: void(0);" @click="gotTo('parking-fleet')">Seasonal</a></li>
              <li v-if="getRight('PARKING')" :class="{ 'mm-active': selectedSub === 'daily_parking' }" ><a href="javascript: void(0);" @click="gotTo('daily_parking')">Daily</a></li>
              <li v-if="getRight('PARKING')" :class="{ 'mm-active': selectedSub === 'monthly_parking' }" ><a href="javascript: void(0);" @click="gotTo('monthly_parking')">Monthly</a></li>
              <li v-if="getRight('PARKING')" :class="{ 'mm-active': selectedSub === 'weekly_parking' }" ><a href="javascript: void(0);" @click="gotTo('weekly_parking')">Weekly</a></li>
              <li v-if="getRight('PARKING')" :class="{ 'mm-active': selectedSub === 'vehicles_register' }" ><a href="javascript: void(0);" @click="gotTo('vehicles_register')">Vehicles Register</a></li>
              <li v-if="getRight('PARKING')" :class="{ 'mm-active': selectedSub === 'clamped_vehicles' }" ><a href="javascript: void(0);" @click="gotTo('clamped_vehicles')">Vehicles Clamped</a></li>

            </ul>
          </li>

          <!-- <li>
          <a class="waves-effect" href="flagged-payments.html">
          <i class="dripicons-flag"></i><span>Flagged Payments</span>
          </a>
          </li>-->
          <li  v-if="getRight('FINANCE ACT')" :class="{ 'mm-active': selected === 'finance-act' }">
            <a class="waves-effect" @click="gotToSelected('finance-act')">
              <i class="mdi mdi-currency-usd"></i><span>Finance Bill</span>
            </a>
          </li>

        <!--  <li v-if="tenantAreas" :class="{ 'mm-active': selected === 'Tenant Areas' }">
            <a @click="selectedMenu('Tenant Areas')" href="javascript: void(0);" class="waves-effect has-arrow">
              <i class="mdi mdi-home-alert"></i>
              <span>Tenant Areas</span>
            </a>
            <ul :class="{ 'mm-active': selected === 'Tenant Areas' }" class="sub-menu" aria-expanded="false">
              <li :class="{ 'mm-active': selectedSub === 'rentals' }"><a href="javascript: void(0);" @click="gotTo('rentals')">Rentals</a></li>
              <li :class="{ 'mm-active': selectedSub === 'Market Stalls' }"><a href="javascript: void(0);" @click="gotTo('stalls')">Market Stalls</a></li>
            </ul>
          </li>
-->
          <li v-if="getRight('BILLS')" :class="{ 'mm-active': selected === 'bills' }">
            <a @click="selectedMenu('bills')" class="waves-effect has-arrow" href="javascript: void(0);">
              <i class="bx bx-receipt"></i>
              <span>Invoices</span>
            </a>
              <ul v-if="getRight('BILLS')" :class="{ 'mm-active': selected === 'bills' }" class="sub-menu">
              <li v-if="getRight('BILLS RAISED')" :class="{ 'mm-active': selectedSub === 'bills-raised' }"><a href="javascript: void(0);" @click="gotTo('bills-raised')">Invoices raised</a></li>
              <li v-if="getRight('RECEIPTED BILLS')" :class="{ 'mm-active': selectedSub === 'bills-receipted' }"><a href="javascript: void(0);" @click="gotTo('bills-receipted')">Paid Invoices</a></li>
              <li v-if="getRight('UNPAID BILLS')" :class="{ 'mm-active': selectedSub === 'un-paid-bills' }"><a href="javascript: void(0);" @click="gotTo('un-paid-bills')">Unpaid Invoices</a></li>
              <li  :class="{ 'mm-active': selectedSub === 'waived-bills' }"><a href="javascript: void(0);" @click="gotTo('waived-bills')">Waived Bills</a></li>

            </ul>
          </li>

          <li  v-if="getRight('PAYMENT AND RECEIPTING')" :class="{ 'mm-active': selected === 'Payments & Receipting' }">
            <a @click="selectedMenu('Payments & Receipting')" class="waves-effect has-arrow" href="javascript: void(0);">
              <i class="mdi mdi-receipt"></i>
              <span>Payments</span>
            </a>
            <ul aria-expanded="false" class="sub-menu" :class="{ 'mm-active': selected === 'Payments & Receipting' }">
              <li :class="{ 'mm-active': selectedSub === 'receipts-new' }"><a style="cursor: pointer;" @click="gotTo('receipts-new')">Receive Payment</a></li>
              <li :class="{ 'mm-active': selectedSub === 'receipts' }"><a style="cursor: pointer;" @click="gotTo('receipts')">View Receipts</a></li>
            </ul>
          </li>


          <li  v-if="getRight('RECON')" :class="{ 'mm-active': selected === 'recons' }">
            <a @click="selectedMenu('recons')" class="waves-effect has-arrow" href="javascript: void(0);">
              <i class="bx bx-calendar-check"></i>
              <span>Recons</span>
            </a>
            <ul aria-expanded="false" class="sub-menu" :class="{ 'mm-active': selected === 'recons' }">
              <li :class="{ 'mm-active': selectedSub === 'recon' }"><a style="cursor: pointer;" @click="gotTo('recon')">Auto Reconciliation</a></li>
              <li :class="{ 'mm-active': selectedSub === 'recon-all' }"><a style="cursor: pointer;" @click="gotTo('recon-all')">All Uploaded Transactions</a></li>
            </ul>
          </li>

<!--          <li v-if="getRight('RECON')" :class="{ 'mm-active': selected === 'recon' }">
            <a @click="gotTo('recon')" class="waves-effect">
              <i class="bx bx-calendar-check"></i><span>Auto Reconciliation</span>
            </a>
          </li>-->

          <li v-if="getRight('PAYMENT CORRECTION')" :class="{ 'mm-active': selected === 'action-center' }">
            <a @click="selectedMenu('action-center')" class="waves-effect has-arrow">
              <i class="mdi mdi-flag"></i>
              <span>Action Center</span>
            </a>

            <ul  :class="{ 'mm-active': selected === 'action-center' }" class="sub-menu" aria-expanded="false">
              <li v-if="getRight('PAYMENT CORRECTION')" :class="{ 'mm-active': selectedSub === 'flagged-transaction-correction' }" ><a href="javascript: void(0);" @click="gotTo('flagged-transaction-correction')">Request Correction</a></li>
              <li v-if="getRight('PAYMENT CORRECTION')" :class="{ 'mm-active': selectedSub === 'transaction-correction-request' }" ><a href="javascript: void(0);" @click="gotTo('transaction-correction-request')">Approve Correction</a></li>
            </ul>

          </li>

          <li :class="{ 'mm-active': selected === 'landPlot' }">

            <a @click="selectedMenu('landPlot')" class="waves-effect has-arrow">
              <i class="mdi mdi-map-clock-outline"></i>
              <span>Rates(Land & Plot)</span>
            </a>

            <ul :class="{ 'mm-active': selected === 'landPlot' }" class="sub-menu" aria-expanded="false">

              <li :class="{ 'mm-active': selectedSub === 'plot_rent' }" ><a href="javascript: void(0);" @click="gotTo('plot_rent')">Plot Rent</a></li>
              <li :class="{ 'mm-active': selectedSub === 'land-rate' }" ><a href="javascript: void(0);" @click="gotTo('land-rate')">Land Rates</a></li>
              <li :class="{ 'mm-active': selectedSub === 'pending-transfer' }" ><a href="javascript: void(0);" @click="gotTo('pending-transfer')">Pending Transfer</a></li>
              <li :class="{ 'mm-active': selectedSub === 'approved-transfer' }" ><a href="javascript: void(0);" @click="gotTo('approved-transfer')">Approved Transfer</a></li>
              <li :class="{ 'mm-active': selectedSub === 'decline-transfer' }" ><a href="javascript: void(0);" @click="gotTo('decline-transfer')">Declined Transfer</a></li>
              <li :class="{ 'mm-active': selectedSub === 're-approve-transfer' }" ><a href="javascript: void(0);" @click="gotTo('re-approve-transfer')">Re-Submitted Transfers</a></li>

            </ul>
          </li>

          <li v-if="getRight('DASHBOARD')" :class="{ 'mm-active': selected === 'housing' }">
            <a @click="selectedMenu('housing')" class="waves-effect has-arrow">
              <i class="mdi mdi-map-marker-radius"></i>
              <span>Housing</span>
            </a>

            <ul  :class="{ 'mm-active': selected === 'housing' }" class="sub-menu" aria-expanded="false">
              <li :class="{ 'mm-active': selectedSub === 'housing-register' }"><a href="javascript: void(0);" @click="gotTo('housing-register')">Rentals Register</a></li>
            </ul>
          </li>

          <li v-if="getRight('CERTIFICATE AND LICENCING')" :class="{ 'mm-active': selected === 'trade' }">

            <a @click="selectedMenu('trade')" style="cursor: pointer;" class="waves-effect has-arrow" href="javascript: void(0);">
              <i class="mdi-certificate mdi"></i>
              <span>Licencing</span>
            </a>
            <ul :class="{ 'mm-active': selected === 'trade' }" aria-expanded="false" class="sub-menu">
              <li v-if="getRight('BUSINESS PERMITS')" :class="{ 'mm-active': selectedSub === 'cert-biz-stats' }"><a href="javascript: void(0);" @click="gotTo('cert-biz-stats')">SBP Licencing</a></li>
              <li v-if="getRight('BUSINESS PERMITS')" :class="{ 'mm-active': selectedSub === 'fire-dashboard' }"><a href="javascript: void(0);" @click="gotTo('fire-dashboard')">Fire Licencing</a></li>
              <li v-if="getRight('LIQUOR LICENCE')" :class="{ 'mm-active': selectedSub === 'cert-alcohol-dashboard' }"><a href="javascript: void(0);" @click="gotTo('cert-alcohol-dashboard')">Liquor Licencing</a></li>
              <!--              <li :class="{ 'mm-active': selectedSub === 'customers' }"><a href="javascript: void(0);">Food handlers certificate</a></li>-->
              <li v-if="getRight('FOOD HYGIENE')" :class="{ 'mm-active': selectedSub === 'hygiene-dashboard' }"><a href="javascript: void(0);" @click="gotTo('hygiene-dashboard')">Food Hygiene Certificate</a></li>
              <li v-if="getRight('MEDICAL CERTIFICATE')" :class="{ 'mm-active': selectedSub === 'medical-dashboard' }"><a href="javascript: void(0);" @click="gotTo('medical-dashboard')">Medical Certificate</a></li>

            </ul>
          </li>

          <li v-if="getRight('BUSINESSES MAPPING')" :class="{ 'mm-active': selected === 'mapping' }">
            <a @click="selectedMenu('mapping')" class="waves-effect has-arrow">
              <i class="mdi mdi-map-marker-radius"></i>
              <span>Trade mapping</span>
            </a>

            <ul  :class="{ 'mm-active': selected === 'mapping' }" class="sub-menu" aria-expanded="false">
              <li v-if="getRight('LIVE MAP')" :class="{ 'mm-active': selectedSub === 'business-mapping' }" ><a href="javascript: void(0);" @click="gotTo('business-mapping')">Geo location</a></li>
              <li v-if="getRight('BUSINESS REGISTER')" :class="{ 'mm-active': selectedSub === 'business-data' }"><a href="javascript: void(0);" @click="gotTo('business-data')">SBP list</a></li>
            </ul>
          </li>

          <li v-if="getRight('REPORTS')" :class="{ 'mm-active': selected === 'reports' }">
            <a @click="selectedMenu('reports')" class="waves-effect has-arrow" href="javascript: void(0);">
              <i class="bx bx-file"></i>
              <span>Reports</span>
            </a>
            <ul :class="{ 'mm-active': selected === 'reports' }" style="cursor: pointer;" aria-expanded="false" class="sub-menu text-capitalize">
              <!-- <li><a href="reports-conty.html">Revenus By County</a></li>
              <li class=""><a href="reports-debtors.html">Debtors</a></li>
              <li class=""><a href="reports-sources.html">Revenue sources</a></li>-->
              <li v-if="getRight('REVENUE STREAMS COLLECTIONS')"  :class="{ 'mm-active': selectedSub === 'reports-streams' }" style="cursor: pointer;" class="text-capitalize"><a @click="gotTo('reports-streams')">By Streams</a></li>
              <li v-if="getRight('COLLECTIONS BY SUB COUNTIES')"   :class="{ 'mm-active': selectedSub === 'reports-sub-county' }" style="cursor: pointer;" class="text-capitalize"><a @click="gotTo('reports-sub-county')">By Sub Counties</a></li>
              <li v-if="getRight('TRANSACTIONS')"  :class="{ 'mm-active': selectedSub === 'customers' }" style="cursor: pointer;" class="text-capitalize"><a @click="gotTo('customers')">Payments</a></li>
              <li   :class="{ 'mm-active': selectedSub === 'reports-sources' }" style="cursor: pointer;" class="text-capitalize"><a @click="gotTo('reports-sources')">Collection Sources</a></li>
              <li v-if="getRight('ALL TRANSACTIONS')"  :class="{ 'mm-active': selectedSub === 'transactions' }" style="cursor: pointer;" class="text-capitalize"><a @click="gotTo('transactions')">All Payments</a></li>
              <li v-if="getRight('REPORTS AGENTS')"  :class="{ 'mm-active': selectedSub === 'live_monitoring' }" style="cursor: pointer;" class="text-capitalize"><a @click="gotTo('live_monitoring')">Attendants</a></li>
              <li  :class="{ 'mm-active': selectedSub === 'reports-streams-unpaid' }" style="cursor: pointer;" class="text-capitalize"><a @click="gotTo('reports-streams-unpaid')">Unpaid Invoices</a></li>
              <li  :class="{ 'mm-active': selectedSub === 'reports-streams-paid' }" style="cursor: pointer;" class="text-capitalize"><a @click="gotTo('reports-streams-paid')">Paid Invoices</a></li>

            </ul>
          </li>

          <li v-if="getRight('COLLECTION ZONES')"  :class="{ 'mm-active': selected === 'zones' }" style="cursor: pointer;">
            <a @click="gotToSelected('zones')" class="waves-effect">
              <i class="mdi mdi-map"></i><span>Revenue Zones</span>
            </a>
          </li>

          <li v-if="getRight('SYSTEM USERS')" :class="{ 'mm-active': selected === 'users' }">
            <a @click="selectedMenu('users')" class="waves-effect has-arrow" href="javascript: void(0);">
              <i class="mdi mdi-monitor-multiple"></i>
              <span>User Management</span>
            </a>
            <ul v-if="getRight('SYSTEM USERS')"  :class="{ 'mm-active': selected === 'users' }"  aria-expanded="false" class="sub-menu">
              <li v-if="getRight('USER ACCOUNT')"  :class="{ 'mm-active': selectedSub === 'set-accounts' }"><a style="cursor: pointer;" @click="gotTo('set-accounts')">Roles</a></li>
              <li v-if="getRight('SYSTEM USERS')" :class="{ 'mm-active': selectedSub === 'users' }" ><a style="cursor: pointer;" @click="gotTo('users')">Users</a></li>
              <li v-if="getRight('AUDIT LOGS')" :class="{ 'mm-active': selectedSub === 'all-user-logs' }" ><a style="cursor: pointer;" @click="gotTo('all-user-logs')">Audit Logs</a></li>
              <li v-if="getRight('ADD SYSTEM USER')"  :class="{ 'mm-active': selectedSub === 'add-users' }"><a style="cursor: pointer;" @click="gotTo('add-users')">Register user</a></li>
            </ul>
          </li>

          <li v-if="getRight('SETTINGS AND SETUPS')"  :class="{ 'mm-active': selected === 'settings' }">
            <a @click="selectedMenu('settings')" class="waves-effect has-arrow" href="javascript: void(0);">
              <i class="bx bx-slider"></i>
              <span>Configurations</span>
            </a>
            <ul :class="{ 'mm-active': selected === 'settings' }" aria-expanded="false" class="sub-menu">
              <li v-if="getRight('DEPARTMENT')"  :class="{ 'mm-active': selectedSub === 'department' }"><a style="cursor: pointer;"  @click="gotTo('department')">For Department</a></li>
              <li v-if="getRight('REVENUE STREAM')"  :class="{ 'mm-active': selectedSub === 'revenue-stream' }"><a style="cursor: pointer;" @click="gotTo('revenue-stream')"> For Revenue Stream</a></li>
              <li v-if="getRight('REVENUE TARGET')"  :class="{ 'mm-active': selectedSub === 'targets-SubCounty' }"><a style="cursor: pointer;" @click="gotTo('targets-SubCounty')">For Revenue Targets</a></li>
            </ul>
          </li>

        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import { MetisMenu } from 'metismenujs';
  import {authUrl, biller, execute, getRight} from "@/api";
  export default {
    name: 'NavigationBar',

    data() {
      return {
        county: {
          id: null,
          countyID: null,
          countyName: null,
          tagline: null,
          smsusername: null,
          smspassword:null,
          contact: null,
          center: null,
          address: null,
          email: null,
          headline: null,
          tagline2: null,
          ussd: null,
          logo: null,
          bank: null,
          powered: null,
          stateLogo: null,
          QRLink:null
        },
        sessionNames:'',
        isVerticalMenuHidden: false,
        permission:"",
        category:"",
        newBill: true,
        dashboard: true,
        paymentsReceipting:true,
        financeAct:true,
        tenantAreas: true,
        bills:true,
        trade: true,
        parking: true,
        reports: true,
        settings: true,
        zones: true,
        users: true,
        liveMonitoring:true,
        transactions: true,
        businessMapping: true,

        selectedSub: null,
        selected: null,
        helmet: {
          names:''
        },
      };
    },
    components: {

    },

    methods:{

      getRight(type){
       return getRight(type)
      },
      selectedMenu(selected){
        sessionStorage.setItem("selected",selected)
      },
      gotToSelected(selected){
        sessionStorage.setItem("selected",selected)
        this.$router.push(selected);
      },
      gotTo(route){
        sessionStorage.setItem("selectedSub",route)
        sessionStorage.setItem("currentPage",route)
        this.$router.push(route);

      },

      getCounty(){
        const data = new FormData();
        data.append("function", "getCounty");
        execute(data,authUrl)
            .then((res) =>{
              if (res.data.success) {
                this.county = res.data.data.county
              }else{
                // alert(res.data.message)
              }
            })
            .catch((e) => {
              //alert(e.message);
              this.message =e.message
            });
      },

      logout(route){

        const data = new FormData();
        data.append("function", "logout");
        data.append("sessionNames",  sessionStorage.getItem("sessionNames"));
        data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
        execute(data,authUrl)
            .then((res) =>{

              sessionStorage.clear()
              this.$router.push(route);

              if (res.data.success) {

              }else{
                this.message =res.data.message
              }
            })
            .catch((e) => {
              //alert(e.message);
              this.message =e.message
            });
      }
    },
    mounted() {

      this.getCounty()


      this.sessionNames = sessionStorage.getItem("sessionNames")

      this.names = sessionStorage.getItem("names")
      new MetisMenu('#side-menu');
      this.selected = sessionStorage.getItem("selected")
      this.selectedSub = sessionStorage.getItem("selectedSub")
      this.category = sessionStorage.getItem("category")
      this.permission = sessionStorage.getItem("permission")



      if(this.category === "EXECUTIVE"){
        this.newBill= false
        this.dashboard = true
        this.paymentsReceipting= false
        this.financeAct=false
        this.tenantAreas= false
        this.bills=false
        this.trade=false
        this.parking=false
        this.reports= true
        this.settings=false
        this.zones= false
        this.users= false
        this.liveMonitoring=false
        this.transactions = false
        this.businessMapping = false

      }
      else if(this.category ==="CASHIER"){

        this.newBill= true
        this.dashboard = false
        this.paymentsReceipting= true
        this.financeAct=false
        this.tenantAreas= true
        this.bills=true
        this.trade=true
        this.parking=true
        this.reports= false
        this.settings=false
        this.zones= false
        this.users= false
        this.liveMonitoring=false
        this.transactions = true
        this.businessMapping = false
      }
      else {
        this.newBill= true
        this.dashboard = true
        this.paymentsReceipting= true
        this.financeAct=true
        this.tenantAreas= true
        this.bills=true
        this.trade=true
        this.parking=true
        this.reports= true
        this.settings=true
        this.zones= true
        this.users= true
        this.liveMonitoring=true
        this.transactions = true
        this.businessMapping = true
      }

    }
  };

</script>

<style>


  .page-topbar {
    transition: margin-left 0.3s ease; /* Add a smooth transition for the top bar */
  }

  .page-topbar-minimize {
    margin-left: 80px; /* Adjust the width as needed for the minimized top bar */
  }

  .hide-vertical-menu {
    display: none; /* Hide the vertical menu when the class is present */
  }

  .content-minimize {
    transition: margin-left 0.3s ease; /* Add a smooth transition for the content */
    margin-left: 80px; /* Adjust the width as needed for the minimized content */
  }

  @media (max-width: 768px) {
    #vertical-menu {
      display: none; /* hide by default on mobile view */
    }

    .hide-vertical-menu #vertical-menu {
      display: block; /* show when the hide-vertical-menu class is present */
    }
    #vertical-menu.show-mobile-verticle{
      display: block !important;
    }
  }


  @media print {
    .footer,
    .navbar-header,
    .page-title-box,
    .right-bar,
    .vertical-menu {
      display: none!important
    }
    .card-body,
    .content-minimize,
    .page-content,
    .right-bar,
    body {
      padding: 0;
      margin: 0
    }
    .card {
      border: 0
    }
  }

  .content-minimize {
    margin-left: 250px;
    overflow: hidden
  }

  .content-minimize .content {
    padding: 0 15px 10px 15px;
    margin-top: 70px
  }

  @media (max-width:992px) {
    .vertical-menu {
      display: none
    }
    .content-minimize {
      margin-left: 0!important
    }
    body.sidebar-enable .vertical-menu {
      display: block
    }

    #vertical-menu.show-mobile-verticle{
      display: block !important;
    }
  }

  .vertical-collpsed .content-minimize {
    margin-left: 70px
  }

  body[data-layout=horizontal] .content-minimize {
    margin-left: 0!important
  }


  body[data-sidebar-size=small] .content-minimize {
    margin-left: 160px
  }

  body[data-sidebar-size=small].vertical-collpsed .content-minimize {
    margin-left: 70px
  }



</style>


