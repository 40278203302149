<template>

<head>

    <meta charset="utf-8" />
    <title>Hotline Transporters Sacco | All invoices</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Juma Otineo" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body @contextmenu.prevent data-sidebar="dark">

    <!-- <body data-layout="horizontal" data-topbar="dark"> -->

    <!-- Begin page -->
    <div id="layout-wrapper">



        <NavigationBar/>





        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Revenue Collection By agents for 7th Sep 2023</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Reports</a></li>
                                        <li class="breadcrumb-item active">Revenue Collections By Agents</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                      <div class="flex-grow-1">
                                        <div class="search-box mb-0 me-3">
                                            <div class="position-relative">
                                                <input type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l">
                                                <i class="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                      </div>

                                        <div class="d-flex">
                                            <div class="d-flex align-items-center me-3 pe-3">
                                                <span class="pr-3 fw-semibold">Date </span>
                                                <input class="form-control" placeholder="Start date" type="date">

                                            </div>

                                            <div class="d-flex align-items-center me-3 pe-3">
                                                <span class="pr-3 fw-semibold">Idle Time Filter</span>
                                                <div class="d-flex">
                                                    <input class="form-control" placeholder="Idle time" type="text">

                                                    <select class="form-select">
                                                        <option>Min</option>
                                                        <option>Hrs</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div class="d-flex align-items-center me-3 pe-3">
                                                <span class="pr-3 fw-semibold">Filter By Target</span>
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <select class="form-select me-2">
                                                        <option>All Renvenue Agents</option>
                                                        <option>Above Target</option>
                                                        <option>Below Target</option>
                                                        <option>Poor Perfomance</option>
                                                        <option>Zero Collections</option>
                                                    </select>

                                                    <button title="what does this filter do?" data-bs-toggle="modal" data-bs-target=".target-modal" type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle">
                                                        <span class="avatar-title bg-transparent text-reset">
                                                            <strong>?</strong>
                                                        </span>
                                                    </button>
                                                </div>

                                            </div>


                                            <button v-if="getRight('DOWNLOAD REPORTS')" data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                                            </button>

                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button v-if="getRight('DOWNLOAD REPORTS')" type="button"  data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive overflow-visible">

                                        <table class="table align-middle table-hover  contacts-table table-striped " id="">
                                            <thead class="table-light">
                                                <tr class="table-dark">
                                                    <th>#</th>
                                                    <th scope="col">Agent</th>
                                                    <th>Inspections</th>
                                                    <th scope="col">Transactions</th>
                                                    <th>Idle Time</th>
                                                    <th class="text-right">Highest Achievement</th>
                                                    <th class="text-right">Inspected Revenue</th>
                                                    <th class="text-right">Target</th>
                                                    <th scope="col" class="text-right">Amount collected</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in users" :value="item" :key="index">
                                                    <td class="text-capitalize text-nowrap"><strong>{{index+1}}.</strong></td>
                                                    <td>
                                                        <a href="use-logs.html" title="click to vie user logs and activities">
                                                            </a><div class=" d-flex align-items-center "><a href="use-logs.html" title="click to vie user logs and activities">
                                                                <div class="avatar-xs mr-15px ">
                                                                    <span class="avatar-title rounded-circle ">
                                                                            {{item.names[0]}}  {{item.names[1]}}
                                                                        </span>
                                                                </div>
                                                                </a><div><a href="use-logs.html" title="click to vie user logs and activities">
                                                                    </a><h5 class="font-size-14 mb-1 "><a href="use-logs.html" title="click to vie user logs and activities"></a><a href="javascript: void(0); " class="text-dark text-capitalize">{{item.names}} </a></h5>
                                                                    <p class="text-muted mb-0 ">{{item.category}}</p>
                                                                </div>
                                                            </div>

                                                    </td>
                                                    <td class="text-capitalize text-nowrap">22</td>
                                                    <td class="text-capitalize text-nowrap">18</td>
                                                    <td>22 Min</td>
                                                    <td class="text-capitalize text-nowrap text-right">KES 220,000</td>
                                                    <td class="text-capitalize text-nowrap text-right">KES 220,000</td>
                                                    <td class="text-capitalize text-nowrap text-right">KES {{item.target}}</td>
                                                    <th class="text-capitalize text-nowrap text-right">KES {{item.amount}}</th>

                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <a type="button" @click="gotTo('reports-agents-details')" href="javascript: void(0);"  class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3">View Details</a>
                                                            <div class="dropdown">
                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="#"><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="#"><i class="font-size-15 mdi mdi-email me-3 "></i>Send Email Reminder</a>
                                                                    <a class="dropdown-item " href="#"><i class="font-size-15 mdi mdi-chat me-3 "></i>Send SMS Reminder</a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                            <tfoot class="table-dark">
                                                <tr>

                                                    <th colspan="">
                                                        23 Agents
                                                    </th>
                                                   <th>

                                                   </th>
                                                   <th>53</th>
                                                   <th>53</th>
                                                   <th>5 Hrs</th>
                                                   <td class="text-nowrap text-right " colspan="2">
                                                    <span class="fw-semibold ">KES 2,000,000</span>
                                                </td>

                                                   <td class="text-nowrap text-right " colspan="2">
                                                    <span class="fw-semibold ">KES 2,000,000</span>
                                                </td>


                                                    <td class="text-nowrap text-right " colspan="">
                                                        <span class="fw-semibold ">KES 2,000,000</span>
                                                    </td>
                                                    <TD></TD>


                                                </tr>

                                            </tfoot>
                                        </table>
                                    </div>

                                </div>

                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example " class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px ">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <li class="page-item disabled">
                                                <a class="page-link" href="#" tabindex="-1">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active">
                                                <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade download-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Download Report</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <address>
                                        <strong class="text-capitalize">Report Title</strong><br>
                                        Revenue Collections By Sub-Counties
                                    </address>
                                </div>

                                <div class="col-12">
                                    <label><strong>Report Date Range</strong></label>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-xl-6 col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Date From</label>
                                                <input class="form-control" placeholder="Start date" type="date">
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Date To</label>
                                                <input class="form-control" placeholder="Start date" type="date">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <label><strong>Select File Type</strong></label>
                                    <select class="form-select">
                                        <option>Select Document Type</option>
                                        <option>PDF file</option>
                                        <option>Excel File</option>
                                    </select>
                                </div>

                                <div class="col-12">
                                    <button v-if="getRight('DOWNLOAD REPORTS')"  type="button" class="btn btn-primary dropdown-toggle option-selector text-capitalize w-100 mt-5">
                                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade target-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Target Categorization</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <ol>
                                        <li class="mb-2">  <strong>Above Target:</strong> Collectors who collect more than their target, i.e., Ratio > 100.</li>
                                        <li class="mb-2"> <strong>On Target:</strong> Collectors who collect exactly their target, i.e., Ratio = 100.</li>

                                        <li class="mb-2"><strong>Below Target:</strong> Collectors who collect less than their target, i.e., Ratio 100.</li>
                                        <li class="mb-2"><strong>Poor Perfomance:</strong> Collectors who achieved less than 80% of the target.</li>

                                        <li class="mb-2"><strong>Zero Collections:</strong> Collectors who did not collect anything</li>

                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                        <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method" data-style="btn-secondary w-100" data-live-search="true" title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                     </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex " placeholder="Phone No." spellcheck="false" data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex" placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#" class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            County Government
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>

    getDashboardUsers
</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import router from "@/router";
    import {authUrl, biller, execute, getRight} from "@/api";
    export default {
        name: "AddUser",
        components: {NavigationBar},
        data() {
            return{
                permission:'',
                loading: false,
                // set the initial current page as 1
                currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
                totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 10,
                summary: {
                    count: 0
                },
                selectedUser:{
                    id:null,
                    names:null,
                    idNo:null,
                    category:null,
                    phoneNumber:null,
                    email:null,
                    zone:null,
                    subCountyID:null,
                    subCountyName:null,
                    wardID:null,
                    wardName:null,
                    status:null,
                },
                search:"",
                selectedItem: "All users (100)",// The default value
                idNo:"",
                message:null,
                ward:{
                    id:"",
                    countyID:"",
                    countyName:"",
                    subCountyID:"",
                    subCountyName:"",
                    wardID:"",
                    wardName:"",
                    status:""
                },
                wards:[],
                users:[
                    {
                        id: "",
                        names: "",
                        gender: "",
                        email: "",
                        phoneNumber: "",
                        idNo: "",
                        password: "",
                        roles: "",
                        dateCreated: "",
                        zone: "",
                        subCountyID: "",
                        subCountyName: "",
                        lat: "",
                        lng: "",
                        lastSeen:"",
                        address:"",
                        status:""
                    }
                ],
                //

                subCounty: {
                    id:"",
                    countyID:"",
                    countyName:"",
                    subCountyID:"",
                    subCountyName:"",
                    wardID:"",
                    wardName:"",
                    status:""
                },
                wardName:'',
                subCountyName:'',
                gender:'Female',
                names:'',
                phoneNumber:'',
                email:'',
                categories:[{
                    id: "",
                    category: ""
                }],
                selectedCategory:'',
                selectedRoles:[],
                zones: [],
                roles: [],
                zone:[],
                subCounties:[],

            }
        },
        computed: {
            //pagination code
            // calculate the start index of the items for the current page
            startIndex() {
                return (this.currentPage - 1) * this.pageSize;
            },
            // calculate the end index of the items for the current page
            endIndex() {
                return this.currentPage * this.pageSize;
            },

        },
        watch: {
            //pagination code
            // define a watcher for the pageSize property
            pageSize(newPageSize) {
                this.getUsers("")//todo change
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            },
            selectedItem: {
                handler() {
                    this.getUsers(this.selectedItem);
                }
            },
            search: {
                handler() {

                    this.getUsers("");
                }
            }
        }
        ,
        mounted() {
            this.permission = sessionStorage.getItem("permission")
            this.getUsers("")
             this.menuAccess()

        },
        methods:{

          menuAccess(){

            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "Reports Agents");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then(() => {
                  // alert(res.data.message);
                })
                .catch(() => {
                  // alert(e.message);
                });

          },
            getRight(type){
                return getRight(type)
            },
            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getUsers("")
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getUsers("")
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getUsers("")
                }
            },
            selectUser(item){
                this.selectedUser = item
                this.names = this.selectedUser.names
                this.idNo = this.selectedUser.idNo
                this.phoneNumber = this.selectedUser.phoneNumber
                this.email = this.selectedUser.email
                this.getSubCounties()
                this.getWards(this.selectedUser.subCountyID)
                this.getCategories()
            },
            getHumanDate(dateData){
                //  const res = dateData.split(" ");
                const dateObject = new Date(Date.parse(dateData));
                return dateObject.toDateString();

            },
            logs(idNoUser,logsName){
                sessionStorage.setItem("idNoUser",idNoUser)
                sessionStorage.setItem("logsName",logsName)
                this.gotTo('user-logs')
            },
            gotTo(route){

                router.push(route)
            },


            getUsers(category){
                this.loading = true
                this.users.splice(0)
                const data = new FormData();
                data.append("function", "getDashboardUsers");
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("category", category);
                data.append("search", this.search);
                execute(data,authUrl)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                            this.users = res.data.data.users

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },

            getWards(subCountyID){
                const data = new FormData();
                data.append("function", "getWards");
                data.append("subCountyID",subCountyID);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.wards = res.data.data.wards

                            this.wards.forEach((value, index) => {
                                if (this.selectedUser.wardID === value.wardID) {
                                    this.ward = this.wards[index];
                                }
                            });

                            this.getZones(subCountyID)
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounties");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties = res.data.data.subCounties

                            //preselection
                            this.subCounties.forEach((value, index) => {
                                if (this.selectedUser.subCountyID === value.subCountyID) {
                                    this.subCounty = this.subCounties[index];
                                }
                            });

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getCategories(){
                const data = new FormData();
                data.append("function", "getCategories");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.categories = res.data.data.categories

                            //preselection
                            this.categories.forEach((value, index) => {
                                if (this.selectedUser.category === value.category) {
                                    this.selectedCategory = this.categories[index].category;
                                }
                            });
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getRoles(){
                const data = new FormData();
                data.append("function", "getRoles");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.roles = res.data.data.roles
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getZones(subCountyID){
                this.zone.splice(0)
                const data = new FormData();
                data.append("function", "getZones");
                data.append("subCountyID", subCountyID);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.zones = res.data.data.zones


                            this.zones.forEach((value, index) => {
                                if (this.selectedUser.zone.includes(value.zone)) {
                                    this.zone.push(this.zones[index].zone)

                                }
                            });

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            createUser(){
                this.message = null
                if(this.gender === '' ||
                    this.names === '' ||
                    this.phoneNumber === '' ||
                    this.idNo === '' ||
                    this.selectedCategory === '' ||
                    this.zone === ''){
                    this.message ="Fill all the places"
                    return
                }
                const data = new FormData();
                data.append("function", "createUser");
                data.append("names", this.names);
                data.append("email", this.email);
                data.append("phoneNumber", this.phoneNumber);
                data.append("category", this.selectedCategory)    ;
                data.append("idNo", this.idNo);
                data.append("zone", this.zone);
                data.append("subCountyID", this.subCounty.subCountyID);
                data.append("subCountyName", this.subCounty.subCountyName);
                data.append("wardID", this.ward.wardID);
                data.append("wardName", this.ward.wardName);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            // this.message =res.data.message
                            this.gotTo('users')
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            updateUser(){
                this.message = null
                if(
                    this.names === '' ||
                    this.phoneNumber === '' ||
                    this.idNo === '' ||
                    this.selectedCategory === '' ||
                    this.zone.length<0){
                    this.message ="Fill all the places"
                    return
                }
                const data = new FormData();
                data.append("function", "updateUser");
                data.append("names", this.names);
                data.append("email", this.email);
                data.append("phoneNumber", this.phoneNumber);
                data.append("category", this.selectedCategory)    ;
                data.append("idNo", this.idNo);
                data.append("zone", this.zone);
                data.append("subCountyID", this.subCounty.subCountyID);
                data.append("subCountyName", this.subCounty.subCountyName);
                data.append("wardID", this.ward.wardID);
                data.append("wardName", this.ward.wardName);
                data.append("id", this.selectedUser.id);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.search = this.names
                            this.getUsers("")
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            resetPassword(){
                this.message = null
                if(
                    this.names === '' ||
                    this.phoneNumber === '' ||
                    this.idNo === '' ||
                    this.selectedCategory === '' ||
                    this.zone.length<0){
                    this.message ="Fill all the places"
                    return
                }
                const data = new FormData();
                data.append("function", "resetPassword");
                data.append("names", this.names);
                data.append("email", this.email);
                data.append("phoneNumber", this.phoneNumber);
                data.append("category", this.selectedCategory)    ;
                data.append("idNo", this.idNo);
                data.append("zone", this.zone);
                data.append("subCountyID", this.subCounty.subCountyID);
                data.append("subCountyName", this.subCounty.subCountyName);
                data.append("wardID", this.ward.wardID);
                data.append("wardName", this.ward.wardName);
                data.append("id", this.selectedUser.id);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.search = this.names
                            this.getUsers("")
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            disable(status){
                this.message = null
                if(
                    this.names === '' ||
                    this.phoneNumber === '' ||
                    this.idNo === '' ||
                    this.selectedCategory === '' ||
                    this.zone.length<0){
                    this.message ="Fill all the places"
                    return
                }
                const data = new FormData();
                data.append("function", "disable");
                data.append("status", status);
                data.append("names", this.names);
                data.append("email", this.email);
                data.append("phoneNumber", this.phoneNumber);
                data.append("category", this.selectedCategory)    ;
                data.append("idNo", this.idNo);
                data.append("zone", this.zone);
                data.append("subCountyID", this.subCounty.subCountyID);
                data.append("subCountyName", this.subCounty.subCountyName);
                data.append("wardID", this.ward.wardID);
                data.append("wardName", this.ward.wardName);
                data.append("id", this.selectedUser.id);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.search = this.names
                            this.getUsers("")
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },




        },
    }
</script>

<style scoped>

</style>