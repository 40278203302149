<template>

    <div class="row">
        <div class="col-12 active">
            <div class="card active">
                <div class="card-body mini-nav bg-primary pt-2 pb-3 active ">
                    <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
                        <button
                                class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary"
                                type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span class="mdi mdi-menu"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between active"
                             id="navbarNavAltMarkup">
                            <div class="navbar-nav">
                                <a class="nav-item nav-link" href="live-monitoring.html">All Users (100)</a>
                                <a class="nav-item nav-link" href="live-monitoring-active.html">Active Users (78)</a>

                                <a class="nav-item nav-link" href="live-monitoring-dormant.html">Dormant Users (90)</a>
                                <a class="nav-item nav-link" href="live-monitoring-out.html">Offline (900)</a>
                                <a class="nav-item nav-link" href="live-monitoring-never.html">Never Logged In (987)</a>

                            </div>

                            <form class="form-inline mx-3 my-lg-0 d-flex text-capitalize">
                                <select class="form-select">
                                    <option>All target Classifications</option>
                                    <option>Underperforming (Below 80%)</option>
                                    <option>Below Target (80%-90%)</option>
                                    <option>Performing (91%-100%)</option>
                                    <option>Above Target (Above 100%)</option>
                                    <option>Zero Collectors</option>
                                </select>

                                <select class="form-select mx-2" v-model="selectedCategory">
                                    <option selected>All User Categories</option> <!-- Move this option to the top -->
                                    <option v-for="(item, index) in categories" :key="`${index}`">
                                        <a class="dropdown-item" href="#">{{ item.category }}</a>
                                    </option>
                                </select>

                                <select class="form-select" id="country"  v-model="subCounty">
                                    <option v-for="(item, index) in subCounties" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.subCountyName}}</a></option>
                                </select>


                            </form>


                        </div>
                    </nav>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {authUrl, execute} from "@/api";

    export default {
        name: "ToolBarLiveMonitoring",
        data(){
            return{
                subCounties:[],
                selectedCategory:'',
                categories:[{
                    id: "",
                    category: ""
                }],
            }
        },

        mounted() {
            this.getCategories()
            this.getSubCounties()
        },
        methods:{
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounties");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties = res.data.data.subCounties
                        }else{
                            this.message =res.data.message
                            this.alert.success = false
                            this.alert.failed = true
                            this.alert.info = false
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                        this.alert.success = false
                        this.alert.failed = true
                        this.alert.info = false
                    });
            },
            getCategories(){
                const data = new FormData();
                data.append("function", "getCategories");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.categories = res.data.data.categories
                        }else{
                            this.message =res.data.message
                            this.alert.success = false
                            this.alert.failed = true
                            this.alert.info = false
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.alert.success = false
                        this.alert.failed = true
                        this.alert.info = false
                        this.message =e.message
                    });
            },
        }
    }
</script>

<style scoped>

</style>